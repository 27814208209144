import { Box, Heading, Image, Stack } from "@chakra-ui/core";
import React from "react";
import LoginForm from "../authentication/LoginForm";
import { pengPink } from "./NavigationBar";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "540px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const Login = props => {
  let history = useHistory();
  const { t } = useTranslation();
  
  return (
    <Box>
      <Helmet>
        <title>{"Login"}</title>
        <meta name="description" content={t("Login")} />
        <meta name="keywords" content="celebrity,gifts,peng" />
      </Helmet>
      <Box {...wrapperProps}>
        <Stack align="center" spacing={5} mb={8}>
          <Box maxW="168px">
            <Image   onClick={() => {history.push('/')}} src={pengPink} />
          </Box>
          <Heading>Log in to your account</Heading>
        </Stack>
        <LoginForm onReset={() => {history.push('/')}} />
      </Box>
    </Box>
  );
};

export default Login;
