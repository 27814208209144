import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { object, string } from "yup";
import PasswordInput from "../form-elements/PasswordInput";
import { AppContext, createUrl } from "./AppProvider";
import * as Yup from 'yup';


let initialValue = {
  email: "",
};
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
let validationSchema = object().shape({
  email: string().max(250, "Email should be less than 250 characters")
    .email("Enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Enter a valid email"),
});

let placeholders = {
  email: {
    label: "Email address",
    placeholder: "Your email"
  },
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: touched[name] && errors[name],
  errorMessage: errors[name]
});

const ResetPasswordForm = ({ onSubmit = () => { }, spacing = "24px" }) => {
  const { setAuthToken, isLoggedIn, authService } = useContext(AppContext);
  let [loading, setLoading] = React.useState(false);
  let [message, setMessage] = React.useState('');
  let [status, setStatus] = React.useState('success');
  const toast = useToast();

  function onFormSubmit(data) {
    setLoading(true);
    authService.resetPassword(data).then((res) => {
      let body = res.body;
      if (res.status == 200) {
        setStatus('success')
        setMessage(body.detail)
        toast({
          title: "Reset email link has been sent.",
          description: body.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onSubmit(body.detail)
      } else {
        setStatus('error')
        // if (body.new_password2) {
        //   setMessage('Erroneous input: ' + body.new_password2.join(' '))
        // } else {
        //   setMessage('Erroneous input')
        // }
      }
      setLoading(false);
    }).catch((error) => {
      setStatus('error')
      setMessage('Unknown error')
      console.log(error)
    })
  }



  // .then((response) => {
  //   setErrorMessage(response)
  //   setLoading(false)
  // })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValue}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
              {message !== '' && (<Alert status={status}>
                <AlertIcon />
                {message}
              </Alert>)
              }
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      type="email"
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Button
              mt={spacing}
              type="submit"
              isFullWidth
              size="lg"
              variantColor="pink"
              isLoading={loading}
            // isDisabled={!isComplete}
            >
              Reset Password
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;

ResetPasswordForm.defaultProps = {
  onSubmit: data => {
    // alert(JSON.stringify(data))
  }
};
