import { Heading, Box, Text } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const CelebrityTerms = props => {
  return (
    
      <Box bg="gray.50" minH="640px">
        <Box {...wrapperProps}>
          <Heading>Talent Terms of Use</Heading>
          <br />
          <Text fontSize="16px" color="#black">
            <strong>Talent Terms of Use </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            This Talent Terms of Service Agreement (“Terms”) governs your use as
            a talent user of the PENG™ marketplace platform for personalized
            video shout-outs and other products and services offered by PENG
            Corporate Resource Limited (“we,” “us,” or “PENG”) through our
            website (Sendpeng.com), mobile application (“App”), and through the
            services we provide (collectively, the website, App, and services
            referred to as our “Site”). “You” and “Talent User” refer to you as
            a talent user of the Site.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            These Terms apply solely to Talent Users of our Site. Any use by you
            of our Site other than as a Talent User will be governed by the Site
            Terms of Service.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>PLEASE READ THESE TERMS CAREFULLY.</strong> By using our
            Site as a Talent User or otherwise indicating your acceptance (for
            example, agreeing by creating or when logging into your account,
            clicking “I Agree,” etc.), you represent and warrant that you have
            read, understand, and agree to be bound by these Terms. If you do
            not agree to be bound by these Terms, you may not access or use our
            Site as a Talent User.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>ARBITRATION NOTICE:</strong> This Terms contains provisions
            governing how claims that you and PENG have against each other are
            resolved, including any claims that arose or were asserted prior to
            the “Last Updated” date at the top of these Terms. In particular, it
            contains an arbitration agreement that will, with limited
            exceptions, require disputes between you and PENG to be submitted to
            binding and final arbitration. Unless you opt out of the arbitration
            agreement, you will be permitted to pursue claims and seek relief
            against us only on an individual basis, not as a plaintiff or class
            member in any class or representative action or proceeding; and (2)
            you waive your right to seek relief in a court of law and to have a
            jury trial on your claims.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>1. Your Obligations</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a. PENG Videos: </strong> From time to time, a user of our
            Site (“User”) may request one or more video recordings (each, a
            “PENG Video”) from you through our Site. While we hope you will
            fulfill a request within 48 hours of receiving it, you may have up
            to 7 days (at PENG’s sole discretion) to complete and upload the
            PENG Video. If you do not either: (i) accept the request and upload
            the PENG Video; or (ii) decline the request within that time; the
            request will expire and can no longer be fulfilled (except that you
            may fulfill the expired request at no cost to the User and with no
            payment due from us). You may decline a request or otherwise refuse,
            in your discretion, to create or upload a PENG Video if a User’s
            request is objectionable or otherwise offensive to you. If you
            accept a request, you agree to record and upload to our Site one
            PENG Video. PENG retains the right, in its sole discretion, to
            cancel any request from a User. We reserve the right to remove your
            PENG Video at any time for any reason. No payment will be made to
            you for any declined, cancelled, or unfulfilled PENG Video request.
            Each PENG Video will be approximately 30 seconds in duration and
            will follow the general directions and requests of the User (for
            example, birthday message, congratulatory message, or “Good luck!”
            message). You will have sole discretion over the script and content
            of any PENG Video, except that you agree: (x) you will use your name
            (please introduce yourself), the User’s name, and, the name of any
            third party that the User identifies as a recipient (“Recipient”) in
            each PENG Video, unless otherwise requested by the User; and (y) you
            will not state in the PENG Video that the PENG Video is incomplete
            or cannot be completed. Your response to the User’s request is your
            responsibility and at your discretion, subject to these Terms;
            however, Users are usually happiest when at least the majority of
            their request is followed by a Talent User. If you complete a PENG
            Video but do not follow the general directions and requests of the
            User, we will discuss with you an appropriate adjustment of the
            payment made to you. Your interactions with third parties, including
            Users, on or through our Site, are solely between you and the third
            party; however, PENG may, in its sole discretion, intercede in any
            dispute and you will reasonably cooperate with PENG if it does so.
            You acknowledge and agree that PENG will not be responsible for any
            damages, losses, costs, expenses, or liabilities incurred as the
            result of such interactions. You agree to contact Users about PENG
            Videos only through our Site.
            <br />
            <br />
            <strong>b. Registration: </strong>In order to accept requests on our
            Site, you will need to register. In registering, you agree to
            provide true, accurate, current, and complete information about
            yourself as prompted by our registration form, as well as any other
            information reasonably requested by us (collectively, “Registration
            Data”), and maintain and promptly update the Registration Data to
            keep it true, accurate, current, and complete. If you do not do so
            or we reasonably believe that you have not done so, we have the
            right to suspend or terminate your Site account and your use of any
            part of our Site. You agree not to create a Site account using a
            false identity or providing false information, on behalf of another
            person (except as outlined below for a parent or legal guardian and
            Organizations), or if you have previously been removed or banned
            from our Site. You are responsible for maintaining the
            confidentiality of your Site account information, including your
            username and password. You are responsible for all activities that
            occur on or in connection with your Site account and you agree to
            notify us immediately of any unauthorized access or use of your Site
            account. You acknowledge and agree that we are not responsible or
            liable for any damages, losses, costs, expenses, or liabilities
            related to any unauthorized access to or use of your Site account.
            <br />
            <br />
            i. Talent User, age 16 or older: Talent Users who are at least 16 years old may register directly. By registering, you represent and warrant that: (A) you are at least 16 years old (and, if between 16 and 18 years old, are registering with the supervision and with the consent of your parent or legal guardian, who also agrees to these Terms); (B) you are of legal age to form a binding contract; (C) you (and if you are between 13 and 18 years old, your parent or legal guardian) are not barred from using our Site under the laws of Nigeria, your place of residence (and if you are between 16 and 18 years old, the place of residence of your parent or legal guardian), or any other applicable jurisdiction; and (D) you (and if you are between 16 and 18 years old, your parent or legal guardian) are responsible for complying with all applicable laws and regulations relating to Talent User’s participation on our Site and will fully indemnify the PENG Parties (defined below) for any failure to do so.
            <br />
            <br />
            ii. Parent or Legal Guardian of Talent User, under age 13: Talent Users who are under 16 years old, may register only through a parent or legal guardian who, by registering, represents and warrants that: (A) he or she is the parent or legal guardian of the Talent User and agrees to these Terms; (B) neither the parent or legal guardian nor the Talent User is barred from using our Site under the laws of Nigeria, the place of residence of the parent, legal guardian, or Talent User, or any other applicable jurisdiction; and (C) he or she is responsible for complying with all applicable laws and regulations relating to Talent User’s participation on our Site and will fully indemnify the PENG Parties for any failure to do so.
            <br />
            <br />
            iii. Organizations: A management company, manager, agency, agent, publicist, or other individual or organization (each, an “Organization”) may register a Talent User (“Affiliated Talent”). By registering, the Organization represents and warrants for itself and each Affiliated Talent that: (A) Organization is the authorized representative of the Affiliated Talent and (if any Affiliated Talent is below the age of 18, registering with any required consent of the Affiliated Talent’s parent or legal guardian) and agrees to these Terms; (B) neither the Organization nor the Affiliated Talent is barred from using our Site under the laws of Nigeria, the place of residence of the Organization or any Affiliated Talent, or any other applicable jurisdiction; and (C) Organization is responsible for complying with all applicable laws and regulations relating to Affiliated Talent’s participation on our Site under these Terms and will fully indemnify the PENG Parties for any failure to do so. In addition to these Terms, Additional Terms (defined below) may apply. To register Affiliated Talent as an Organization, please contact contact[at]sendpeng.com.
            <br />
            <br />
            <strong>c. Promotional Materials: </strong> At no cost to PENG, you
            will provide to us the following promotional materials (“Promotional
            Materials”) within 72 hours of beginning the talent on-boarding
            process on our Site: (i) if you would like us to promote your
            participation on our Site, three high resolution images of yourself;
            (ii) your Site profile bio; and (iii) a promotional video of
            approximately :15 in length to let your fans know that they can book
            you on our Site. Please note that you will not be able to receive
            requests from Users until we receive your promotional video. From
            time to time we may request additional Promotional Materials from
            you for PENG’s use to promote you on or in connection with our Site
            or on any social media platform or third party website. Any other
            materials or photos of or concerning you that you approve for PENG’s
            use will also be Promotional Materials under these Terms.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>2. Fees and Payment</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="#black">
            <strong>a. Fees: </strong>Other than with respect to an expired
            request that you choose to fulfill (as set forth in Section 1(a))
            and subject to these Terms, we will pay to you 70% of the revenue
            received by PENG for each PENG Video you created and delivered to
            fulfill to a User’s request that you accepted through our Site. You
            will have the right to set your own price for each PENG Video,
            provided, however, that your price must be either N0 or at least
            N5,000 per PENG Video (unless otherwise agreed by PENG).
            Additionally, we will pay to you 70% of any amount designated as a
            “tip” that you receive from a User via our Site (unless a different
            percentage is agreed by PENG). You represent and warrant that: (i)
            PENG Videos are not, and are not intended to be, covered by any
            guild, union, collective bargaining, or similar agreement and there
            will be no residual or any other type of payment due from PENG to
            you, to any third party, guild, or union, or pursuant to any
            collective bargaining agreement, in connection with any PENG Video,
            payment from us to you, or our Site; (ii) PENG is not responsible
            for, and will not make any deduction to payments made to you for any
            fees, commissions, costs, expenses or payments of any kind to or
            with respect to any third party, including any manager, agent,
            attorney, representative, or service provider, in connection with
            any revenue earned by or payments made to you in connection with our
            Site; and (iii) PENG is not responsible for any contributions,
            payments, taxes, or deductions for Social Security, retirement
            benefits, unemployment insurance, annuities, or pension or welfare
            fund payments required by law or any labor union, or any withholding
            or income taxes.
            <br />
            <br />
            <strong>b. Payment: </strong>You agree to register with the third
            party payment provider selected by PENG, which is currently
            Stripe.com or, if you live in a country in which Stripe.com does not
            provide services or are an Organization, PayPal, Paystack; any
            payment provider is subject to change in PENG’s sole discretion. You
            do not have the option to select a payment provider other than the
            one selected by PENG. You agree to provide to the payment provider
            all information required by such payment provider in order to
            receive payments due via the payment provider. All payments will be
            in the currency you select with the payment provider. If you do not
            provide to the payment provider all required information, then you
            may not be able to receive the payments due to you. PENG will not be
            responsible for any damages, losses, costs, expenses, or liabilities
            arising out of or in connection with your inability to receive
            payments as a result of your failure to provide such information.
            Subject to the applicable payment provider’s terms, payment will be
            made to you by the payment provider within approximately two weeks
            of receipt by PENG of payment from the User for each request for a
            PENG Video that you have fulfilled (as set forth in Section 1(a)).
            You acknowledge and agree that no payment provider used by PENG is
            operated, owned or controlled by PENG, and your use of any such
            payment provider is subject to the terms and conditions and privacy
            policies of that payment provider. We are not responsible for any
            delay, failure, damage, or liability caused by a payment provider,
            any other third party, a force majeure, or your failure to timely or
            properly set up an account with the payment provider or otherwise
            provide requested information for payment. Currently, PENG will be
            responsible for fees, costs, and expenses incurred in connection
            with the use of Stripe.com and with certain uses of PayPal. Unless
            otherwise agreed by PENG, you acknowledge and agree that you are
            solely responsible for any other fees, costs and expenses incurred
            in connection with the use of a payment provider. Notwithstanding
            anything to the contrary contained in these Terms, if PENG, in its
            sole discretion, believes that any fraud, money laundering, or other
            violation of law or regulation is taking place on or in connection
            with our Site, you acknowledge and agree that we may withhold,
            delay, or seek repayment of any payments we believe, in our sole
            discretion, may have arisen out of or in connection with any such
            violation of law or regulation.
            <br />
            <br />
            <strong>c. Charities: </strong>If you identify any charitable entity
            on your booking page or identify your relationship with the
            charitable entity elsewhere on our Site (each, a “Charity”), you:
            (i) represent and warrant that you will comply with all applicable
            laws and regulations that arise as a result of that identification,
            including making any required disclosure, registering, or entering
            into any required agreement, such as co-venturer agreements; (ii)
            acknowledge and agree that we have the right in our sole discretion
            to reject your identification; (iii) represent and warrant that the
            Charity is in good standing at all times your identification is
            used, that within 48 hours of your receipt of our request, you will
            provide us with written evidence of the Charity’s good standing and
            charitable status in all applicable jurisdictions, and that you will
            promptly remove the identification if the Charity ceases to be in
            good standing; (iv) represent and warrant that you will be
            responsible for making any payment to the Charity (unless we
            expressly agree in writing in advance to do so on your behalf and in
            satisfaction of our payment obligations to you under these Terms);
            and (v) represent and warrant that you have all rights necessary to
            grant to us a license to use the name and logo of the Charity in
            connection with our Site, including on our Site, in your
            identification, and in any social and other media. You further
            acknowledge and agree that we may add, in our sole discretion, to
            your booking page language similar to the following (subject to
            change in our sole discretion): “PENG has no affiliation with, and
            does not endorse, [Charity]. Any payment or donation to [Charity] is
            the sole responsibility of [Talent’s name], and PENG is not
            responsible or liable for same. Only [Talent’s name]’s portion of
            the fee paid to PENG for a PENG Video will be donated to [Charity].”
            If you choose to participate in a promotion conducted by PENG that
            involves a charitable entity, Additional Terms apply.
            <br />
            <br />
            d. Talent Referral Program: Under PENG’s referral program, we offer
            Talent Users and others who register on our Site as a talent
            referral source (“PENG Partner”) the opportunity to provide their
            friends, families, or other personal contacts with a unique referral
            code we provide to you (“Code”) that a prospective talent user can
            use to register as a talent user (“Referral Program”). To
            participate in the Referral Program, you must agree to the following
            Referral Program Terms and Conditions (“Referral Terms”), as well as
            the remainder of these Terms:
            <br />
            <br />
            i. PENG reserves the right to suspend or terminate the Referral
            Program or your ability to participate in the Referral Program at
            any time for any reason. Without limiting the foregoing rights, we
            reserve the right to disqualify you from participating in the
            Referral Program at any time at our sole discretion if you fail to
            comply with these Referral Terms. Violations of these Referral Terms
            may also result in the forfeiture of all Referral Fees (defined
            below), you previously earned through the Referral Program. We
            reserve the right to review and investigate activities undertaken in
            connection with the Referral Program and to suspend your Site
            account or modify the consideration (i.e., the Referral Fee), in our
            sole discretion, as we deem fair and appropriate.
            <br />
            <br />
            ii. Eligibility to participate in this Referral Program is limited
            to individuals who have registered an account on our Site as a
            Talent User or PENG Partner and agree to these Terms. This Referral
            Program cannot be used by businesses for affiliate lead generation
            or other commercial purposes. Employees, officers, directors,
            contractors, agents, and representatives of PENG are not eligible to
            participate in the Referral Program.
            <br />
            <br />
            iii. For every new user who registers as a Talent User on our Site
            using your Code (“Referred Talent”), for one year, beginning on the
            date that the Referred Talent first begins on-boarding as a Talent
            User on our Site using your Code, we will pay to you 5% of the
            revenue received by PENG for each PENG Video the Referred Talent
            created and delivered to fulfill a User’s request accepted through
            our Site (excluding tips, PENG Videos for which no revenue was
            earned and, at PENG’s discretion, PENG Videos to which a promotion
            applies or for which a promo code was used) (“Referral Fee”). The
            Referral Fee is based on total revenue but is paid only from PENG’s
            share of the revenue (as described in Section 2(a) above).
            Notwithstanding anything to the contrary contained in these Terms,
            you acknowledge and agree that if the new user you referred fails to
            use your Code when registering, has previously registered on our
            Site (with another code, under another name, or otherwise), or has
            previously begun the on-boarding process for our Site, or if anyone
            else has received or is receiving a Referral Fee from us in
            connection with that new user, we will not pay, nor do we owe, any
            Referral Fee, payment, or credit to you or your Site account. You
            further acknowledge and agree that each Referred Talent may have
            only one referring person or entity, and that we are not obligated
            to pay a Referral Fee to more than one referring person or entity
            with respect to any Referred Talent.
            <br />
            <br />
            iv. This Referral Program may be used only for personal purposes and
            you may share your Code only with your personal connections. You may
            not advertise your Code or share it with anyone other than your
            friends, family, or other personal contacts (for example, you may
            not share it on a social media channel). We reserve the right to
            revoke or refuse to issue any Referral Fees for Referred Talent that
            we suspect were generated through improper channels or otherwise in
            violation of these Referral Terms.
            <br />
            <br />
            v. You represent and warrant that any messages you use to share your
            Code with your personal connections will: be created and distributed
            in a personal manner; not be distributed in a bulk manner; and not
            be an unsolicited commercial email or a “spam” message under any
            applicable law or regulation. Any messages that do not meet these
            requirements are expressly prohibited and constitute grounds for
            immediate termination of your Site account and participation in this
            Referral Program. Registrations on our Site by a Talent User using a
            Code that was distributed through an unauthorized channel will not
            be valid and any Referral Fees issued in connection with such
            transactions may be revoked.
            <br />
            <br />
            vi. We reserve the right to deactivate your Site account and any
            Referred Talent’s Site account, cancel all related Referral Fees,
            and seek repayment if we determine, in our sole discretion, that
            either you or the Referred Talent: (A) has tampered with this
            Referral Program; (B) has used (or attempted to use) this Referral
            Program in a fraudulent, abusive, unethical, unsportsmanlike or
            otherwise questionable or suspicious manner; (C) has breached any of
            these Referral Terms; or (D) has violated any law or regulation or
            infringed or violated the rights of any third party. We also reserve
            the right to cancel this Referral Program or change these Referral
            Terms at any time and for any reason in our sole discretion.
            <br />
            <br />
            vii. By participating in the Referral Program, you agree to release
            and hold harmless PENG Parties from any and all economic
            liabilities, claims, damages, loss, harm, costs or expenses,
            including property damage, that arise from or relate in any way to
            the Referral Program. THIS SECTION 2(b)(vii) APPLIES ONLY TO
            ECONOMIC DAMAGES AND DOES NOT APPLY TO CLAIMS OF FRAUD OR PERSONAL
            INJURY.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>3. Talent Content</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a. License Grant to Talent Content: </strong> Our Site
            allows you to upload, submit, store, send, transmit, approve, and
            receive content and data, including your PENG Videos and Promotional
            Materials (collectively, “Talent Content”). When you upload, submit,
            store, send, transmit approve, or receive Talent Content to or
            through our Site, you grant to us a non-exclusive, royalty-free,
            fully paid, unlimited, worldwide, sublicensable (through multiple
            tiers of sublicenses), perpetual, and irrevocable license in any and
            all manner and media, whether now known or hereinafter invented
            (including social media channels and third party websites and
            platforms), to reproduce, license, distribute, modify, adapt,
            publicly perform, publicly display, create derivative works of (for
            example, translations, adaptations, or other changes we make so that
            Talent Content works better with our Site or otherwise), and to use
            your Talent Content for the purposes of operating and providing our
            Site, to develop and improve our products and services, and to
            advertise, market, and promote our Site, products, and services, and
            you agree that such Talent Content may, in PENG’s sole discretion,
            be used, including performed or displayed, in connection with any
            other elements, materials, copyrights, rights of publicity or
            copyrighted materials. Please remember that third parties (including
            Users) may search for and see any Talent Content you submit to
            public areas of our Site. You agree that we may display advertising
            with or in connection with your Talent Content. You further
            acknowledge and agree that PENG has no obligation to you in
            connection with any advertising displayed on or in connection with
            our Site (including no obligation to share any revenue received by
            PENG as a result of any such advertising).
            <br />
            <br />
            <strong>b. License Grant to Users: </strong>You hereby grant to User
            and the Recipient of the PENG Video a non-exclusive, royalty-free,
            fully paid, worldwide, sublicensable, and perpetual license to use,
            reproduce, distribute, and publicly display the PENG Video, in each
            case, solely in accordance with the Site Terms of Service, in any
            and all media, whether now known or hereafter invented (including
            social media channels and third party websites and platforms).
            <br />
            <br />
            <strong>c. Right to Remove PENG Videos: </strong>In some
            circumstances, we may need your authorization to remove a PENG Video
            from social media channels or third party websites or platforms and
            we will notify you of our intent to remove it. You hereby grant to
            us the right to act as your authorized agent in order to submit any
            DMCA notice or other demand with respect to your PENG Videos.
            <br />
            <br />
            <strong>d. Licenses: </strong>Please note that the licenses granted
            in this Section 3 are fully-paid and royalty free, meaning we do not
            owe you anything in connection with the use of your Talent Content,
            by us, Users, or third parties (including Recipients), other than
            the payment set forth in Section 2(a). We may exercise our rights
            under this license anywhere in the world. We may sublicense our
            rights as needed to provide and promote our Site or otherwise in
            accordance with these Terms, and Users may sublicense their rights
            subject to the Site Terms of Service. Finally, the licenses granted
            in this Section 3 are perpetual, meaning that the rights granted
            under these licenses continue even after you stop using our Site.
            <br />
            <br />
            <strong>e. Talent Content Representations and Warranties: </strong>
            You represent and warrant that:
            <br />
            1. you own all rights in and to your Talent Content and Feedback and
            that you have the right to grant the rights described in these
            Terms;
            <br />
            2. you have paid and will pay in full any fees, royalties, or other
            payments that are due or may become due in connection with any use
            of your Talent Content and Feedback by us, Users, or third parties
            (including Recipients) as described in these Terms;
            <br />
            3. your agreement to these Terms and the provision of any services
            by you does not violate any agreement that you may have with any
            third party;
            <br />
            4. your Talent Content and Feedback does not infringe,
            misappropriate, or otherwise use without necessary authorization,
            any intellectual property rights, privacy rights, publicity rights,
            moral rights, or other legal rights of any third party, or violate
            any law, regulation, or court order;
            <br />
            5. you will not post or make publicly available any PENG Video that
            the User has requested remain private and you will not ask us for
            permission to create or post same;
            <br />
            6. you will not contact, respond to or communicate with any User
            that you meet on or through our Site, other than to deliver the PENG
            Video through our Site;
            <br />
            7. you will not provide your contact information to any User or send
            merchandise or anything else to a User other than as permitted by
            these Terms;
            <br />
            8. you agree not to edit, change, modify, or remove the watermark
            from any PENG Video or assist or encourage any third party to do so;
            <br />
            9. you will not ask us to assist you with the actions in Subsections
            3(e)(v) through 3(e)(viii); and
            <br />
            10. you will not remake any PENG Video unless asked by us to do so.
            <br />
            <br />
            <strong>f. Treatment of Talent Content: </strong>Any Talent Content
            is non-confidential, non-proprietary, and must not contain or
            include any information which you do not have the right to disclose
            or that you do not wish to be disclosed. We will not be responsible
            or liable for any use or disclosure of Talent Content, including any
            personal information included in that Talent Content. You
            acknowledge and agree that your relationship with us is not a
            confidential, fiduciary, joint-venture, employer/employee, agency,
            or other type of special relationship, and that your decision to
            participate in our Site or submit any Talent Content does not place
            us in a position that is any different from the position held by
            members of the general public, including with regard to your Talent
            Content. None of your Talent Content will be subject to any
            obligation of confidence by us, Users, or third parties (including
            Recipients), and we will not be liable or responsible for any use or
            disclosure of any Talent Content.
            <br />
            <br />
            g. Refusal and Removal of Talent Content: We may refuse to accept or
            transmit Talent Content for any reason without notice to you. We may
            remove Talent Content from our Site for any reason without notice to
            you.
            <br />
            <br />
            h. Cancellation of Site Account: If you cancel your Site account,
            you may, on at least three business days’ advance written notice to
            us, request that we no longer include your PENG Videos on our Site
            and that we not make any new public use of them. You acknowledge and
            agree that we cannot restrict the use of your existing PENG Videos
            by the Users for whom you created them or by any third party with
            whom they have already been shared (including Recipients) and we
            have no obligation to remove any other uses of your PENG Videos
            (including from social media channels or third party websites or
            platforms).
            <br />
            <br />
            i. Moral Rights: You hereby waive any and all moral rights or “droit
            moral” that you may have in Talent Content, and you represent and
            warrant that no third party has any moral, “droit moral” or other
            rights in the Talent Content.
            <br />
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>4. Ownership</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. Other than Talent Content, we or our licensors own all right,
            title, and interest in and to: (i) our Site and the “look and feel”
            of our Site, including all software, ideas, processes, data, text,
            media, and other content available on our Site (individually, and
            collectively, “PENG Content”); and (ii) our trademarks, logos, and
            brand elements (“Marks”). Our Site, PENG Content, and Marks, are
            each protected under Nigerian and international laws. You may not
            duplicate, copy, or reuse any portion of the HTML/CSS, JavaScript,
            visual design elements, or concepts without our prior express
            written consent.
            <br />
            <br />
            b. PENG desires to avoid the possibility of future misunderstandings
            when projects developed by us, our employees, or our contractors
            might seem to be similar to material submitted to us by you or a
            third party. To the extent you submit any ideas, suggestions,
            proposals, plans, or other materials related to our business
            (individually, and collectively, “Feedback”), you acknowledge and
            agree that you are submitting that Feedback at your own risk and
            that PENG has no obligation (including no obligation of
            confidentiality or privacy) with respect to your Feedback, and you
            grant to PENG a non-exclusive, royalty-free, fully paid, unlimited,
            worldwide, sublicensable (through multiple tiers of sublicenses),
            perpetual, and irrevocable license, in any and all manner and media,
            whether now known or hereinafter invented, to reproduce, license,
            distribute, modify, adapt, publicly perform, publicly display,
            create derivative works of (for example, translations, adaptations,
            or other changes), and otherwise use and exploit in any manner
            (including commercially), any and all Feedback.
            <br />
            <br />
            c. You hereby waive any moral rights you may have in any Feedback.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>5. Additional Terms</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Some products or services offered through the Site may have
            additional terms and conditions (“Additional Terms”). If Additional
            Terms apply, we will make them available for you to read in
            connection with that product or service. By using that product or
            service, you agree to the Additional Terms. To the extent that the
            Additional Terms conflict with any of these Terms, these Terms will
            govern unless otherwise specified in such Additional Terms.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>6. Eligibility</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. Age: You must be at least 13 years old to use our Site. If you
            are a minor or under the age of majority in your state of residence,
            your parent or legal guardian must agree to these Terms on your
            behalf and you may access and use our Site only with permission from
            your parent or legal guardian.
            <br />
            <br />
            b. Representations and Warranties: You represent and warrant that:
            <br />
            i. you have not been prohibited from using or accessing any aspect
            of our Site by us or under any applicable law or regulation;
            <br />
            ii. you will comply with all of the terms and conditions, as revised
            from time to time, of any third party payment provider selected by
            us, and you are not on a prohibited list of that third party payment
            provider;
            <br />
            iii. if you include an animal in any PENG Video, you will cause no
            harm to the animal and will comply with all applicable laws and
            regulations;
            <br />
            iv. we have not previously disabled any Site account you created or
            controlled for violation of any law or regulation, the infringement
            of any third party right, or the violation of any of these Terms;
            and
            <br />
            v. you are not a convicted sex offender.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>7. Acceptable Use of our Site</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. Your Responsibilities: You are responsible for your use of any
            PENG Video, your Site account, our Site, and Talent Content. Our
            goal is to create a positive, useful, and safe user experience. To
            promote this goal, we prohibit certain kinds of conduct that may be
            harmful. You represent and warrant that in creating and using a PENG
            Video, your Site account, our Site, and Talent Content, you will
            not:
            <br />
            i. violate any law, regulation, or court order;
            <br />
            ii. violate, infringe, or misappropriate the intellectual property,
            privacy, publicity, moral, or other legal rights of any third party;
            <br />
            iii. even if requested by a User: take any action that is or submit,
            post, share, or communicate anything that is or that incites or
            encourages action that is, explicitly or implicitly: illegal,
            abusive, harassing, threatening, hateful, racist, derogatory,
            harmful to any reputation, pornographic, indecent, profane, obscene,
            or otherwise objectionable (including nudity);
            <br />
            iv. send advertising or commercial communications, including spam,
            or any other unsolicited or unauthorized communications;
            <br />
            v.engage in spidering or harvesting, or participate in the use of
            software, including spyware, designed to collect data from our Site,
            including from any User;
            <br />
            vi. transmit any virus, other computer instruction, or technological
            means intended to, or that may, disrupt, damage, or interfere with
            the use of computers or related systems;
            <br />
            vii. stalk, harass, threaten, or harm any third party;
            <br />
            viii. impersonate any third party;
            <br />
            ix. participate in any fraudulent or illegal activity, including
            phishing, money laundering, or fraud;
            <br />
            x. use any means to scrape or crawl any part of our Site;
            <br />
            xi. attempt to circumvent any technological measure implemented by
            us, any of our providers, or any other third party (including
            another User) to protect us, our Site, Users, Recipients, or any
            other third party;
            <br />
            xii. access our Site to obtain information to build a similar or
            competitive website, application, or service;
            <br />
            xiii. attempt to decipher, decompile, disassemble, or reverse
            engineer any of the software or other underlying code used to
            provide our Site; or
            <br />
            xiv. advocate, encourage, or assist any third party in doing any of
            the foregoing.
            <br />
            <br />
            For clarity, your use of any PENG Video includes use anywhere (on
            our Site or otherwise).
            <br />
            <br />
            b. Investigations: You acknowledge and agree that we are not
            obligated to monitor access to or use of our Site by you or third
            parties (including monitoring Talent Content), but we have the right
            to do so in order to operate our Site, to ensure compliance with
            these Terms, to comply with applicable law, regulation, court order,
            or other legal, administrative, or regulatory request or process, or
            otherwise.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>8. Independent Contractor</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. You and PENG agree and declare that these Terms create an
            independent contractor relationship and it is the parties’ express
            intent that their relationship be interpreted and held to be that of
            independent contractor for all purposes. You acknowledge and agree
            that you are not a joint venturer, franchisee, partner, agent, or
            employee of PENG, and will not represent yourself as such. You are
            solely and exclusively responsible for determining the manner,
            method, details, and means of your performance under these Terms. We
            have no right to, and will not, control the manner or determine the
            method of accomplishing your performance.
            <br />
            <br />
            b. You represent and warrant that as between PENG and you (whether a
            Talent User, parent or legal guardian of a Talent User, an
            Organization, Affiliated Talent, PENG Partner, or otherwise), you
            assume sole liability for and will pay or cause to be paid all
            applicable contributions, payments, taxes, and deductions for Social
            Security, retirement benefits, unemployment insurance, and
            annuities, pension or welfare fund payments required by law or any
            labor union, and all withholding taxes, or income taxes, and make
            any reports required as a result of participation on our Site under
            these Terms.
            <br />
            <br />
            c. You will use your own equipment to perform your obligations under
            these Terms. You are solely responsible for making any disclosure
            required by any applicable law, regulation, court order or any
            agreement you may have with any third parties to any person or
            entity regarding your performance under these Terms.
            <br />
            <br />
            d. Your relationship with PENG is non-exclusive, meaning that you
            can provide similar services to third parties and that we can and do
            engage third parties to provide similar services to those
            contemplated by these Terms.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>9. Links</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Our Site may contain links to social media platforms or third party
            websites. You acknowledge and agree that: (a) the link does not mean
            that we endorse or are affiliated with the platform or website; and
            (b) we are not responsible or liable for any damages, losses, costs,
            expenses, or liabilities related to your use of the platform or
            website. You should always read the terms and conditions and privacy
            policy of the platform or website before using it.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>10. Changes to our Site</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            We enhance and update our Site often. You acknowledge and agree we
            may change or discontinue any aspect of our Site at any time,
            without notice to you.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>11. Termination and Reservation of Rights</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            You may stop using our Site at any time. You may cancel your Site
            account at any time by contacting a member of the PENG team at
            support[at]sendpeng.com. We reserve the right to terminate access to
            our Site to any person, including you, at any time, for any reason,
            in our sole discretion. If you violate any of these Terms, your
            permission to use our Site automatically terminates.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>12. Disclaimers and Limitations on our Liability</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. You acknowledge and agree that your use of our Site is at your
            own risk and that our Site is provided on an “as is” and “as
            available” basis. To the extent permitted by applicable law, the
            PENG Parties disclaim all warranties, conditions, and
            representations of any kind, whether express, implied, statutory, or
            otherwise, including those related to merchantability, fitness for a
            particular purpose, and non-infringement and those arising out of
            course of dealing or usage of trade.
            <br />
            <br />
            b. In particular, the PENG Parties make no representations or
            warranties about the accuracy or completeness of content available
            on or through our Site or the content of any social media platform
            or third party website linked to or integrated with our Site. You
            acknowledge and agree that the PENG Parties will have no liability
            for any: (i) errors, mistakes, or inaccuracies of content; (ii)
            personal injury, property damage, or other harm resulting from your
            access to or use of our Site; (iii) any unauthorized access to or
            use of our servers, any personal information, or user data; (iv) any
            interruption of transmission to or from our Site; (v) any bugs,
            viruses, trojan horses, or the like that may be transmitted on or
            through our Site; or (vi) any damages, losses, costs, expenses, or
            liabilities of any kind incurred as a result of the use of any
            content posted or shared through our Site.
            <br />
            <br />
            c. You acknowledge and agree that any material or information
            downloaded or otherwise obtained through our Site, including a User
            request, is done at your own risk and that you will be solely
            responsible for any damages, losses, costs, expenses, or liabilities
            arising from or in connection with doing so. No advice or
            information, whether oral or written, obtained by you from us or
            through our Site, including through a PENG Video, will create any
            warranty not expressly made by us.
            <br />
            <br />
            d. You acknowledge and agree that when using our Site, you will be
            exposed to content from a variety of sources, and that PENG is not
            responsible for the accuracy, usefulness, safety, legality, or
            intellectual property rights of or relating to any such content. You
            further understand and acknowledge that you may be exposed to
            content that is inaccurate, offensive, indecent, objectionable, or
            harassing, and you agree to waive, and hereby do waive, any legal or
            equitable rights or remedies you have or may have against any PENG
            Party with respect thereto.
            <br />
            <br />
            e. To the fullest extent permitted by applicable law, you
            acknowledge and agree that in no event will any PENG Party be liable
            to you or to any third party for any indirect, special, incidental,
            punitive, or consequential damages (including for loss of profits,
            revenue, or data) or for the cost of obtaining substitute products,
            arising out of or in connection with these Terms, however caused,
            whether such liability arises from any claim based upon contract,
            warranty, tort (including negligence), strict liability or
            otherwise, and whether or not PENG has been advised of the
            possibility of such damages.
            <br />
            <br />
            f. To the maximum extent permitted by applicable law, our total
            cumulative liability to you or any third party under these Terms,
            from all causes of action and all theories of liability, will be
            limited to and will not exceed the fees actually received by PENG
            from you during the 12 months preceding the claim giving rise to
            such liability.
            <br />
            <br />
            g. Certain jurisdiction does not allow the exclusion or limitation
            of certain damages. If those laws apply to you, some or all of the
            above exclusions or limitations may not apply to you, and you might
            have additional rights.
            <br />
            <br />
            h. You agree that the limitations of damages set forth above are
            fundamental elements of the basis of the bargain between PENG and
            you.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>13. Indemnification</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            You agree to indemnify, defend, and hold harmless PENG and its parents, subsidiaries, affiliates, officers, employees, directors, shareholders, agents, partners and licensors (each, a “PENG Party, “ and collectively, “PENG Parties”) from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including reasonable attorneys’ fees and costs) of any kind or nature, arising from, out of, in connection with, or relating to: (a) any actual or alleged breach of these Terms by you or anyone using your Site account; (b) any actual or alleged violation of any laws or regulations or infringement of any rights of any third party by you or anyone using your Site account; (c) your negligence, misconduct, or fraud; (d) any action or inaction by you or anyone acting on your behalf; (e) any Organization or Affiliated Talent; (f) any Charity; (g) your status as a parent or legal guardian of a Talent User; (h) Talent Content;(i) Feedback; (j) your participation in the Referral Program. PENG will have the right to select counsel to be paid for by you in connection with any indemnification owed by you. You will reasonably cooperate with us in connection with this indemnification and any defense.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              14. Arbitration Agreement and Waiver of Certain Rights
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. Arbitration: You and PENG agree to resolve any disputes between you and PENG through binding and final arbitration instead of through court proceedings. You and PENG each hereby waive any right to a jury trial of any controversy, claim, counterclaim, or other dispute arising between you and PENG relating to these Terms or our Site (each a “Claim,” and collectively, “Claims”).
            <br />
            <br />
            b. Costs and Fees: If you demonstrate that the costs of arbitration
            will be prohibitive as compared to the costs of litigation, PENG
            will pay as much of the administrative costs and arbitrator’s fees
            required for the arbitration as the arbitrator deems necessary to
            prevent the cost of the arbitration from being prohibitive. In the
            final award, the arbitrator may apportion the costs of arbitration
            and the compensation of the arbitrator among the parties in such
            amounts as the arbitrator deems appropriate.
            <br />
            <br />
            c. No Preclusions: This arbitration agreement does not preclude you
            or PENG from seeking action by federal, state, or local government
            agencies. You and PENG each also have the right to bring any
            qualifying Claim in small claims court. In addition, you and PENG
            each retain the right to apply to any court of competent
            jurisdiction for provisional relief, including pre-arbitral
            attachments or preliminary injunctions, and any such request will
            not be deemed to be either incompatible with these Terms or a waiver
            of the right to have disputes submitted to arbitration as provided
            in these Terms.
            <br />
            <br />
            d. No Class Representative or Private Attorney General: You and PENG
            each agree that neither may act as a class representative or private
            attorney general or participate as a member of a class of claimants,
            with respect to any Claim. You agree that Claims may not be
            arbitrated on a class or representative basis. The arbitrator can
            decide only individual Claims (whether brought by you or PENG). The
            arbitrator may not consolidate or join the claims of other persons
            or parties who may be similarly situated.
            <br />
            <br />
            e. Severability/No Waiver/Survival: If any provision of this Section
            17 is found to be invalid or unenforceable, that provision will be
            deemed appropriately modified to give effect to the intent of the
            provision or, if modification is not possible, will be severed and
            the remainder of this Section 17 will continue in full force and
            effect. No waiver of any provision of this Section 17 will be
            effective or enforceable unless recorded in a writing signed by the
            party waiving such a right or requirement. Such a waiver will not
            waive or affect any other provision of these Terms. This Section 17
            of these Terms will survive the termination of your relationship
            with PENG.
            <br />
            <br />
            f. 30-Day Opt-Out Right. You have the right to opt out of the
            provisions of this Arbitration Agreement by sending, within 30 days
            after first becoming subject to this Arbitration Agreement, written
            notice of your decision to opt out. Your notice must include your
            name and address, any usernames, each email address you have used to
            set up an account on our Site, and an unequivocal statement that you
            want to opt out of this Arbitration Agreement. You agree that if you
            opt out of this Arbitration Agreement, all other parts of these
            Terms will continue to apply to you. Opting out of this Arbitration
            Agreement has no effect on any other arbitration agreements that you
            may currently have, or may enter in the future, with us.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>15. Other Provisions</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            a. Force Majeure: Under no circumstances will any PENG Party be
            liable for any delay or failure in performance due in whole or in
            part to any acts of God, earthquakes, unavoidable accidents, laws,
            rules, regulations or orders of government authorities, acts of war
            (declared or not), terrorism, hostilities, blockades, civil
            disturbances, embargoes, strikes, or any other event or cause beyond
            the reasonable control of any PENG Party.
            <br />
            <br />
            b. Choice of Law and Jurisdiction: These Terms will be governed by
            and construed in accordance with the laws of the State of Illinois,
            without giving effect to any conflict of laws rules or provisions.
            You agree that any action of whatever nature arising from or
            relating to these Terms or our Site. You consent and submit to the
            personal jurisdiction of such courts for the purposes of any such
            action.
            <br />
            <br />
            c. Severability: If any provision of these Terms is found to be
            invalid or unenforceable, that provision will be deemed
            appropriately modified to give effect to the intent of the provision
            or, if modification is not possible, will be severed from these
            Terms and will not affect the enforceability of any other provision.
            <br />
            <br />
            d. No Waiver or Amendment: The failure by PENG to enforce any right
            or provision of these Terms will not prevent PENG from enforcing
            such right or provision in the future and will not be deemed to
            modify these Terms.
            <br />
            <br />
            e. Assignment: PENG may, at any time, assign its rights and
            obligations under these Terms, including in connection with a sale
            of assets, merger, acquisition, reorganization, bankruptcy, other
            transaction, or by operation of law.
            <br />
            <br />
            f. Miscellaneous: The term “including” in these Terms will be
            interpreted broadly and will mean “including, without limitation.”
            Titles are for convenience only and will not be considered when
            interpreting these Terms.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Text fontSize="12px" color="black">
            Last Updated: 4th November, 2019
          </Text>
        </Box>
      </Box>
    
  );
};

export default CelebrityTerms;
