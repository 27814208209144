import { Heading, Box, Text } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";
import CelebrityTerms from "./CelebrityTerms";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const TalentTerms = props => {
  return (
    <PageWrapper>
        <CelebrityTerms/>
    </PageWrapper>
  );
};

export default TalentTerms;
