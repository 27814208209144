import React from "react";
import { Box, Flex, Stack, Text, Link } from "@chakra-ui/core";
import VideoPlayer from "../data-display/VideoPlayer";
import Rating from "../data-display/Rating";

const mediaProps = {
  height: "auto%",
  weight: "auto",
  maxHeight: "100%",
  minHeight: "420px",
  borderRadius: "16px 16px 0px 0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const videoWrapper = {
  height: "420px",
  direction: "column",
  borderWidth: "2xs",
  borderColor: "gray.100",
  boxShadow: "md",
  borderRadius: "16px",
  overflow: "hidden",
  bg: "gray.50"
};

const CelebrityVideo = ({ personal_booking, order_from, order_to, order_rating, video, uuid }) => {
  return (
    <Flex {...videoWrapper}>
      <Box flex={1} overflow="hidden" borderRadius="16px 16px 0px 0px">
      <VideoPlayer
            fluid={false}
            width={"100%"}
            objectFit="cover"
            height="100%"
            src={video.recording_url}
            poster={video.snapshot_url}
          />
        {/* <VideoPlayer {...mediaProps} src={video.recording_url}  poster={video.snapshot_url} /> */}
      </Box>

      <Stack
        py={4}
        spacing={3}
        px={6}
        isInline
        align="center"
        justify="space-between"
        borderTopWidth="2xs"
      >
        <Link href={`/peng/${uuid}`} color="pink.500">
          <Text fontSize="md" isTruncated lineHeight="none">
            By {personal_booking ? order_to : order_from}
          </Text>
        </Link>
          {order_rating && <Rating size="sm" value={order_rating} />}
      </Stack>
    </Flex>
  );
};

export default CelebrityVideo;
