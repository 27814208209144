import { Heading, Box, List, ListItem, Text } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const Privacy = props => {
  return (
    <PageWrapper>
      <Box bg="gray.50" minH="640px">
        <Box {...wrapperProps} fontSize="sm">
          <Heading>Privacy policy</Heading>
          <br />
          <Box as="section" id="introduction">
            <Heading as="h2" size="md" color="#F411A8">
              Introduction
            </Heading>
            <Text fontSize="sm" color="black">
              PENG Corporate Resource Limited and its subsidiaries and affiliates (“we,” “us,” and “our”) value our users and their privacy. This Privacy Notice applies to all websites (any, a “Site”), mobile applications (any, an “App”), social features, online services, widgets, downloads, or other outlets, that are owned or controlled by PENG and that post or include a link to this Privacy Notice (individually, a “Platform,” and collectively, the “Platforms”), regardless of whether accessed by computer, mobile device, or otherwise. By using our services, you accept the practices described in this Privacy Notice. The Platforms include, but are not limited to:
              <br />
              PENG mobile app and www.sendpeng.com
              <br />
              This Privacy Notice applies only to information collected on or through the Platforms. It does not apply to any information collected offline by PENG, unless specifically stated. This Privacy Notice explains PENG’s information practices in connection with the Platforms, such as the types of information we collect and how we may use that information. Please review this Privacy Notice carefully. In addition, please review the Terms of Service (“Terms of Service”) http://www.sendpeng.com/terms/, which governs your use of the Platforms. Please note that certain features or services referenced in this Privacy Notice may not be offered on all of the Platforms, and certain features or services may not be offered on the Platforms at all times.
            </Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              General Information
          </Heading>
            <Text fontSize="sm" color="black">
              WE FULLY DESCRIBE OUR PRIVACY PRACTICES WITH RESPECT TO THE PLATFORMS BELOW IN THIS PRIVACY NOTICE. THIS SUMMARY HIGHLIGHTS IMPORTANT INFORMATION REGARDING OUR USE AND SHARING OF YOUR INFORMATION, AND REGARDING THIRD PARTIES WHO MAY COLLECT CERTAIN INFORMATION WHEN YOU USE THE PLATFORMS. PLEASE REVIEW OUR FULL PRIVACY NOTICE BEFORE USING OUR PLATFORMS.
            </Text>
            <List styleType="disc" fontSize="sm">
              <ListItem>We primarily use the information we collect when you use the Platforms to provide the Platforms and related customer service. Some of our Sites and Apps may request location information, and we typically use that information to provide you more relevant suggestions and offerings.
  </ListItem>
              <ListItem>The Platforms collect certain information regarding how you access and use the Platforms, and we may utilize third parties to collect and analyze this information on our behalf.
  </ListItem>
              <ListItem>We do not share your Personal Information with third parties for their marketing purposes without your opt-in consent. However, we may share non-Personal Information with third parties, in our discretion and without restriction. In addition, we may share your information with other companies in the PENG family — the subsidiaries and affiliates of PENG Corporate Resource Limited.
  </ListItem>
              <ListItem>The Platforms may contain content served by third parties, and we work with third party service providers to conduct analytics and serve PENG advertisements elsewhere online. These third parties may collect certain information about your use of the Platforms or other websites or apps. These third parties may set and access their own tracking devices (including cookies, web beacons, and embedded scripts) and may otherwise collect or have access to information about you and your online activities. We may offer certain Social Features on or through the Platforms, which may allow you to share information or make certain information public.
  </ListItem>
            </List>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">Information Collection</Heading>
            <Heading as="h3" fontSize="sm">Information You Provide</Heading>
            <Text fontSize="sm">
              The Platforms may request that you provide certain personally identifiable information, which is information that may reasonably be used to identify you as a specific individual (“Personal Information”). Examples of Personal Information include your name, e-mail address, username, address, and phone number. The Platforms may also request additional information about you, such as payment information, demographic information (e.g., your gender or marital status), or information about your preferences and interests. The Platforms may require that you provide certain information to use the Platforms or certain features, and the required information may change from time to time depending on, among other things, the nature of your relationship with us. If we combine demographic or similar information with the Personal Information we collect, we will treat the combined information as Personal Information under this Privacy Notice.
</Text>
            <br />
            <Text fontSize="sm">
              The Platforms may also enable you to transmit files or otherwise provide information to PENG. Please note that files you transmit may contain metadata, which may contain Personal Information about you. For example, photos & videos you transmit could include metadata indicating where (e.g., GPS coordinates) and when (date and time) the photo or video was taken.
</Text>
            <br />
            <Heading as="h3" fontSize="sm">
              Information the Platforms Automatically Collect
</Heading>
            <Text fontSize="sm">
              We and our third-party service providers collect certain information about your use of our Platforms (“Usage Information”). Usage Information may include the browser and operating system you are using, the URL, advertisements, or search terms that referred or otherwise led you to the Platforms (if applicable), aspects of the Platforms that you use or visit, the products or services you access or view, the types of videos that you send, and the time of day you accessed the Platforms, among other information.
              Usage Information also includes the IP address or other unique identifier (“Device Identifier”) for any computer, mobile phone or other device (any, a “Device”) used to access the Platforms. A Device Identifier is automatically assigned to your Device, and our servers identify your Device by its Device Identifier. Please note that your IP address may be included in the header information of every video notification email that we send for your videos. Some mobile service providers may also provide us or our third party service providers with information regarding the physical location of a Device used to access the Platforms. Usage Information is generally non-identifying, but if we associate it with you as a specific and identifiable person, we treat it as Personal Information.
</Text>
            <br />
            <Text fontSize="sm">The methods used to collect Usage Information may include:</Text>
            <List styleType="disc" fontSize="sm">
              <ListItem>
                <Text as="b">Cookies</Text>
                <Text fontSize="sm">
                  A cookie is a data file placed on a Device when it is used to visit a Platform or otherwise access our online content. Cookies may be used for many purposes, such as remembering you and your preferences and tracking your visits to the Platforms.
                    Immediately below is information about one such vendor we use across one or more Platforms.
    </Text>
              </ListItem>
              <ListItem>
                <Text as="b">Google Analytics</Text>
                <Text >PENG uses a specific cookie in order to facilitate the use of Google Universal Analytics for users logged-in to the Platforms (“Logged-In User”). If you are a Logged-In User, PENG may use your PENG user ID in combination with Google Universal Analytics and Google Analytics to track and analyze the pages of the services you visit. We do this only to better understand how you use the Platform, with a view to offering improvements for all PENG users; and to tailor our business and marketing activities accordingly, both generally and specifically to you. Google Analytics cookies do not provide PENG with any Personal Information and you may opt-out of this feature by installing the Google Analytics Opt-out Browser Add-on. Note: This opt-out feature only works on your desktop browser and not on a mobile device.</Text>
                <Text fontSize="sm">PENG uses other third-party performance and remarketing cookies, and further information on those third-party cookies can be obtained by contacting us at support[at]sendpeng.com.</Text>
              </ListItem>
              <ListItem>
                <Text as="b">Web Beacons and Pixels</Text>
                <Text fontSize="sm">Small graphic images or other web programming code called “web beacons” “clear GIFs” or “1×1 pixels” may be used on the Platforms or in our emails and may be invisible to you. Web beacons may be connected to your Personal Information, if you have provided it. Web beacons may be used for various purposes, such as counting visitors to the Platforms, monitoring how users navigate the Platforms, counting how many emails that were sent were actually opened, or counting how many times a link was accessed. We do not sell, rent, trade, or otherwise distribute information about you gathered through the web beacons or 1×1 pixels with any third parties, except for third parties who provide us with advertising, marketing, research or analysis services.</Text>
              </ListItem>
              <ListItem>
                <Text as="b">Embedded Scripts</Text>
                <Text fontSize="sm">An embedded script is a programming code that is designed to collect information about your interactions with the Platforms, such as the links you click on. The code is temporarily downloaded onto your Device from our web server or a third party service provider, is active only while you are connected to the Platforms, and is deactivated or deleted thereafter.</Text>
              </ListItem>
            </List>

            <br />
            <Heading as="h3" fontSize="sm">Do Not Track</Heading>
            <Text fontSize="sm">The platform does not currently support recognition of Do Not Track signals sent by a browser.</Text>
            <br />
            <Heading as="h3" fontSize="sm">
              Information You Provide About Third Parties
</Heading>
            <Text fontSize="sm">
              The Platforms may permit you to provide Personal Information about third parties, such as mailing address, email addresses, or significant dates (such as birthdays or anniversaries), and may permit you to do so through the use of our platform. If you use the Platforms to send a video or any other content, we collect certain Personal Information about the recipient (such as name, mailing address, and email address, as applicable). We may share the information regarding your transaction with third parties as necessary to process your transaction (i.e., to create, send or deliver the card). If you choose to provide Personal Information about third parties, we will store that information only in connection with your relationship with us (e.g., to send videos).
</Text>
            <br />
            <Heading as="h3" fontSize="sm">
              Information Third Parties Provide About You
</Heading>
            <Text fontSize="sm">
              We may receive information about you from third parties. For example, if you are on another website and you opt-in to receive information from PENG, the other website will forward your contact and other information to us so that we may contact you as requested. Further, we may receive certain information about you from third parties if you engage the third parties in connection with our Platforms – for example, if you download one of our Apps from an app store, make an in-app purchase, connect via Social Features, or make a payment using a third-party payment provider like PayPal, consistent with the privacy policies of the applicable third parties.
</Text>
            <Text fontSize="sm">
              We also may supplement the information we collect with outside records from third parties in order to provide you with information, services or goods you have requested, to enhance our ability to serve you, and to tailor our content to you. We may combine the information we receive from those other sources with information we collect through the Platforms. In those cases, we will apply this Privacy Notice to the combined information.
</Text>
            <br />
            <Heading as="h3" fontSize="sm">
              How We Use the Information We Collect
</Heading>
            <Text fontSize="sm">
              We may use information about you, including Personal Information and Usage Information, to:
</Text>
            <List as="ol" styleType="decimal">
              <ListItem> allow you to use the Platforms or to provide related customer service; </ListItem>
              <ListItem> provide or offer you information, products, or services, any of which may be tailored to you; </ListItem>
              <ListItem> send or display special offers or advertisements from PENG Companies (as hereinafter defined) and our business partners; </ListItem>
              <ListItem> process your registration, including verifying that information you provide is active and valid; </ListItem>
              <ListItem> process a transaction you initiate; </ListItem>
              <ListItem> improve the Platforms and our products, and for internal business purposes, including the measurement of the Platforms’ effectiveness; </ListItem>
              <ListItem> contact you with regard to your use of the Platforms and, in our discretion, changes to our policies; </ListItem>
              <ListItem> identify and protect against fraudulent activities, transactions, and abuse of the Platforms, and to protect the rights and property of PENG, its partners, and customers; and </ListItem>
              <ListItem> as otherwise described in this Privacy Notice, for purposes disclosed at the time you provide your information, or with your consent.</ListItem>
            </List>
            <br />
            <Text fontSize="sm">
              Some of our Apps may request your location information (whether GPS or network-based). We may use the location information for a variety of purposes. Unless otherwise stated, this location information is not tied to your Personal Information; and you may be able to prevent the collection of your location through your device settings.
</Text>
            <Text fontSize="sm">
              If you contact us for customer support regarding the Platforms, we will not use the information you provide to contact you for marketing purposes unrelated to your request unless you agree otherwise.
</Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              How and When We Disclose Information to Third Parties
            </Heading>
            <Heading as="h3" size="sm">
              General Statement Regarding Information Sharing
            </Heading>
            <Text fontSize="sm">
              We do not share your Personal Information with third parties for their marketing purposes without your opt-in consent. However, we may share non-Personal Information, such as aggregate user statistics, demographic information and Usage Information with third parties, in our discretion and without restriction.
            </Text>
            <Text fontSize="sm">Third parties may also collect non-Personal Information when you download or use the Platforms. We may share your Personal Information with third parties with your consent, as disclosed at the time you provide us with information, or as described below in this Section III or elsewhere in this Privacy Notice.</Text>
            <br />
            <Heading as="h3" size="sm">
              Third Party Service Providers
            </Heading>
            <Text fontSize="sm">We may share Personal Information with our service providers who perform services to us or on our behalf; and in some instances, our service providers may collect information from you directly. Such services may include, for example, hosting or maintaining the Platforms, providing customer support, monitoring the Platform, sending email or other electronic communications, sending videos, providing marketing assistance, processing payments, sharing information about you with third-parties to assist us in delivering content or advertising to you on the Platforms or elsewhere online, and providing analytics services. We do not authorize these service providers to use or disclose the information they obtain from us for any purpose other than the purposes for which they obtained the information or to comply with legal requirements.</Text>
            <br />
            <Heading as="h3" size="sm">
              When You Agree to Receive Information from Third Parties or Request that We Share your Information
            </Heading>
            <Text>

              We may offer the opportunity to receive information or marketing offers from one or more third parties, or to otherwise have your information shared with a third party. We may also offer you the opportunity to order products and services from a third party directly through the Platforms or in communications you may receive from PENG; and in this case, we will disclose that a third party will be fulfilling your order. If you agree to have us share your Personal Information and we do so, your Personal Information will be subject to the privacy practices of that third party. Please note that we are not responsible for the privacy policies and practices of third parties. If you later decide that you no longer wish to receive communications from a third party, you must contact that third party directly.
</Text>
            <br />
            <Heading as="h3" size="sm">Contests, Sweepstakes and Promotions</Heading>

            <Text>We may sponsor contests, sweepstakes or other promotions (any, a “Promotion, and collectively, “Promotions”). If you choose to participate in a Promotion, you may be required to provide certain Personal Information. By participating in a Promotion, you agree to the official rules that govern that Promotion. The official rules may contain specific requirements of you, including, except where prohibited by law, allowing the sponsor(s) of the Promotion to use your name, voice, likeness or other indicia of persona in advertising or marketing associated with the Promotion. If you choose to enter a Promotion, your Personal Information may be disclosed to third parties or the public in connection with the administration of such Promotion, including, without limitation, in connection with winner selection, prize fulfillment, and as required by law or permitted by the Promotion’s official rules, such as on a winners list.</Text>

            <br />
            <Heading as="h3" size="sm">Sharing Between PENG Companies; Business Transitions</Heading>

            <Text>PENG Companies are the subsidiaries and affiliates of PENG, Inc. We may share your information, including Personal Information, with PENG Companies for a variety of purposes, such as to conduct Promotions, offer special premiums, and for business and operational purposes (such as sharing databases and equipment). We may, and reserve the right to, share your information with any other company that is not presently, but becomes one of the PENG Companies. PENG will facilitate payments through third party partners, but we will not share your credit card information or other payment information with anyone, including PENG Companies, without your express permission.</Text>

            <Text>We may also disclose and transfer your Personal Information: (i) to a subsequent owner, co-owner or operator of a Platform or applicable database; (ii) if PENG (or any of its affiliated, parent, or subsidiary companies) assigns its rights regarding any of your information to a third party; or (iii) in connection with a corporate merger, consolidation, liquidation, restructuring, the sale of certain of PENG’s ownership interests, assets, or both, or other company change, including during the course of any due diligence process (collectively, a “Business Transition”). If we disclose and transfer your Personal Information in connection with a Business Transition, we will place a prominent notice of the Business Transition on the homepages of the Site and within the App if you have activated this feature on your Device, with links to information about how you can change your privacy preferences.
Administrative and Legal Reasons</Text>
            <Text>
              We may disclose your information, including Personal Information and Usage Information, to third parties (i) if we are required to respond to subpoenas or other legal process or if in our opinion such disclosure is required by law; (ii) at the request of governmental authorities conducting an investigation; (iii) to protect and/or defend the Platforms or any of the PENG Companies’ rights and property, or to enforce the Terms of Service or other policies applicable to the Platforms; or (iv) to respond to an emergency or otherwise protect the personal safety, rights, property or security of any of the PENG Companies or any third party. We may also use Device Identifiers, including IP addresses, to identify users, and may do so in cooperation with copyright owners, Internet service providers, wireless service providers, or law enforcement agencies in our discretion. These transfers and disclosures may be carried out without notice to you.
            </Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              Third Party Analytics and Advertising Service Providers; Online Tracking
            </Heading>
            <Text>
              We work with certain third parties (including network advertisers, ad agencies, payment processors, and analytics companies) including Google, PayPal, Stripe, Paystack for a variety of purposes. These purposes include providing us with information regarding traffic and use of the Platforms and our advertisements on the Platforms and across the Internet, and to engage in multi-site advertising. For example, if you click on an advertisement that led you to one of our Platforms, our service provider(s) may be able to tell us which advertisement you clicked on and where you were viewing the advertisement; or, you may be served PENG advertisements elsewhere online based on your visit to or use of the Platforms. Some of these third parties may collect Personal Information when you visit the Platforms or other websites or online services. Additionally, we may share certain information derived from your Personal Information with third parties to assist us in delivering content or advertising to you, both on the Platforms and elsewhere online.
</Text><Text>
              These third parties may set and access their own tracking technologies (including cookies, web beacons, and embedded scripts) and may otherwise collect or have access to your Device Identifier, Usage Information and related information about you. These service providers may use that information for their own purposes, including to target advertisements to you, prevent you from seeing the same advertisements too many times and to conduct research regarding the usefulness of certain advertisements to you. We may share the Device Identifier and Usage Information about visitors with third party advertising companies, analytics providers and other vendors for similar purposes.
</Text><Text>
              PENG does not control the information collection, use, or sharing practices of third party analytics providers or advertisers. Some of these parties may collect your Personal Information when you access the Platforms or other online websites and services.

            </Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              Third Party Content and Links to Third Parties
            </Heading>
            <Text>

              Certain content found on the Platforms may be hosted and served by third parties. The Platforms may also contain links to third party websites or apps that we do not control. This includes links from advertisers, reward programs, sponsors and partners associated with the Platforms. These links may use PENG’s logo or the logo of one of the PENG Companies as part of a co-branding agreement (e.g., a third party may be selling or providing PENG products or services). In addition, PENG content may be included on web pages and websites that are not associated with us and over which we have no control.
</Text><Text>
              These third parties may collect Usage Information through the use of their own cookies, web beacons, or embedded scripts, and they may also independently collect data or solicit Personal Information. PENG is not responsible for the privacy practices employed by any third party.

            </Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              Social & Interactive Features; Public Information
            </Heading>
            <Heading as="h3" size="sm">
              Community Forums
            </Heading>
            <Text>
              The Platforms may provide you the opportunity to participate and post content publicly through interactive features and through other communication functionality (“Community Forums”). You may choose, through such features or otherwise, to submit or post photographs (which may include metadata), videos, questions, comments, suggestions or other content (collectively, “User Content”). Please note that certain information, such as your name and other Personal Information, may be publicly displayed on the Platforms along with your User Content.
            </Text>
            <Text>Anything you post to a Community Forum is public — others will have access to your User Content and may use it or share it with third parties. Information or other content you choose to post or make available in the Community Forums may be viewed, collected and used by others. If you choose to voluntarily disclose Personal Information, that information will be considered public information and this Privacy Notice will not apply to such information. If you submit User Content, it may be published online or offline at any time. In addition, we may use your User Content and any excerpt from your User Content in connection with our advertising, marketing, publicity and promotional activities.
</Text>
            <br />
            <Heading as="h3" size="sm">
              Social Features
            </Heading>
            <Text>

              Certain functionalities on the Platforms may permit interactions between the Platforms and a third party website or interactive service (“Social Features”). For example, Social Features may enable you to indicate through a third party website or interactive service that you “like” a Platform or content available on a Platform, or to “share” content from the Platforms. Social Features may also allow you to upload or download photos or videos to or from your account on a third party website or interactive service, or to login to one or more of the Platforms using your access credentials for a third party website or interactive service.
</Text><Text>
              If you choose to use Social Features, certain information, which may include your Personal Information, may be publicly displayed, and the third party website or interactive service used in connection with the Social Features (and its users) may have access to information about you and your use of our Platforms. Similarly, if you post information on a third party website or interactive service that references the Platforms (e.g., by using a hashtag associated with the Platforms in a tweet or status update), your post may be published on the Platforms in accordance with the terms of the third party website or interactive service, and we may collect the information in your post.
</Text><Text>
              We may receive information about you from third party providers of Social Features that interact with our Platforms. For example, if you choose to use Social Features, you allow us to collect (or the third party to share) information about you, including Personal Information, related to your use of the third party website or interactive service. The third party website or interactive service may allow you to remove the application or feature, in which case we will no longer collect information about you through the application or service, but we may retain the information previously collected in accordance with respective third-party privacy policies. In addition, we may receive information about you if other users of a third party website, feature or application give us access to their profiles and you are one of their “connections” or information about you is otherwise accessible through your posts, your “connections” web page, profile page, or similar page on a third party social media website, feature or application. The information we collect in connection with Social Features is subject to this Privacy Notice. The information collected and stored by the third party remains subject to the third party’s privacy practices, including whether the third party continues to share information with us, the types of information shared, and your choices with regard to what is visible to others on that third party website or service.
            </Text>
            <br />
            <Heading as="h3" size="sm">
              Contact Importers
            </Heading>
            <Text>
              The Platforms may also allow you to import information about yourself or third parties from a third-party website or interactive service. For example, the Platforms may allow you to provide your login information to your calendar account so that the Platforms may import your calendar items (such as birthdays). As another example, the Platforms may allow you to provide your email account so that the Platforms may import your contacts or list of persons with whom you have communicated. By providing the login information in your account in any such situation, you are consenting to PENG, or a party on behalf of PENG, importing your information from the Platforms. Unless you consent, we will not store the password you provide if you import information from a third party source.
</Text>
            <br />
            <Heading as="h3" size="sm">
              Testimonials</Heading>
            <Text>We may post customer testimonials on our platforms, which may contain User Content or Personal Information. We do obtain your consent to post your name along with your testimonial (usually via email or instant message) prior to posting the testimonial on our Platforms. However, if you wish to request the removal of your testimonial, please contact us at support[at]sendpeng.com.</Text>
          </Box>
          <br />
          <Box as="section">
            <Heading as="h2" size="md" color="#F411A8">
              Our Control Over Your Information
            </Heading>
            <Text>
              PENG may provide functionality on some Platforms to update your contact information and/or preferences through a “My Account” (or similar) section. If you submit a request to update your contact information, PENG will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. Note, however, that information may persist internally for PENG’s administrative purposes and that residual data may remain on backup media or for other reasons. Please also note that if you are the user of one of our Apps, you may be able to review and update your Personal Information or preferences only through the website associated with that App.
</Text><Text>
              You may opt out of receiving marketing e-mails from us by following the opt-out instructions provided to you in those e-mails. Please note that we reserve the right to send you certain communications relating to your use of the Platforms and these transactional messages may be unaffected if you opt-out from marketing communications.
              To opt-out of receiving direct mail, you may contact us through Customer Support at support[at]sendpeng.com.
</Text><Text>
              Note: Because mailings often are prepared well in advance you may, for a period of time, continue to receive some offers after you send us your request.

            </Text>
            <br />
            <br />
            <br />
            <br />
            <Text fontSize="12px" color="black">
              Last Updated: 4th November, 2019
          </Text>
            <br />
          </Box>
        </Box>
      </Box>
    </PageWrapper >
  );
};

export default Privacy;
