import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Link,
  Button,
  useToast
} from "@chakra-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AppContext } from "./AppProvider";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

import FacebookLogin from 'react-facebook-login-1';

const AuthModal = ({ isOpen, onClose, headerElem, isLogin, setIsLogin, redirectUrl }) => {
  const { isLoggedIn, authService } = useContext(AppContext);
  const [isLoginForm, setIsLoginForm] = useState(isLogin);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoginForm(isLogin)

    return () => {
      abortController.abort();
    };
  });

  const location = useLocation();

  // if (isLoggedIn) {
  //   if (location.pathname === "/login") return <Redirect to="/" />;
  //   return "";
  // }

  const handleOnLoggedIn = () => {
    onClose()
    if (isLoggedIn) {
      if (location.pathname === "/login") return <Redirect to={redirectUrl} />;
      return "";
    }
  }


  const handleOnSignedUp = (detail) => {
    onClose()
  }

  const handleFBAuth = (detail) => {
    console.log(detail)
    let data = {access_token: detail.accessToken}
    authService.fbAuth(data, () => {
      onClose();
    }).then((response) => {
      console.log(response)
    })
  }

  let modalHeading = headerElem
    ? headerElem
    : isLogin
      ? "Login to PENG"
      : "Signup to PENG";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py={5} rounded="lg">
        <ModalHeader>{modalHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* <SocialButton
            provider='facebook'
            appId='526169384882027'
            onLoginSuccess={() => {}}
            onLoginFailure={() => {}}
          >
            Login with Facebook
    </SocialButton> */}
 {/* <FacebookLogin
          appId="526169384882027"
          autoLoad={true}
          fields="name,email,picture"
          scope="public_profile"
          callback={handleFBAuth}
        /> */}
        
          {isLogin ? <LoginForm onSubmit={handleOnLoggedIn}  onReset={handleOnLoggedIn} /> : <SignupForm onSubmit={handleOnSignedUp} />}

          {isLogin ? (
            <Text mt={8} textAlign="center">
              Don't have an account?{" "}
              <Link color="pink.500" onClick={() => setIsLogin(false)}>
                Sign up{" "}
              </Link>
            </Text>
          ) : (
              <Text mt={8} textAlign="center">
                Already have an account?{" "}
                <Link color="pink.500" onClick={() => setIsLogin(true)}>
                  Log in
              </Link>
              </Text>
            )}
        </ModalBody>
      </ModalContent>
    </Modal >
  );
};

export default AuthModal;
