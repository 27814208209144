import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  Text,
  useDisclosure
} from "@chakra-ui/core";
import Pluralize from "pluralize";
import React, { useContext } from "react";
import { AppContext, root_url, is_dev } from "../../authentication/AppProvider";
import Rating from "../../data-display/Rating";
import Testimonial from "../../data-display/Testimonial";
import VideoPlayer from "../../data-display/VideoPlayer";
import currencyFormatter from "../../data-display/Currency";
import { useMedia } from "../../external-pages/utils/hooks";
import CategoryGrid from "../CelebrityGrid";
import CelebrityVideo from "../CelebrityVideo";
import { useGetCategories } from "../hooks";
import { getCouponPrice } from "../../external-pages/utils/functions";
import { useHistory } from "react-router";
import Linkify from 'react-linkify';

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "920px"],
  mx: "auto",
  direction: ["column", "row"]
};
const mediaProps = {
  height: "100%",
  weight: "100%",
  maxHeight: "450px",
  minHeight: "450px",
  borderRadius: "0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const tagStyles = {
  size: "lg",
  bg: "gray.100",
  rounded: "full",
  _hover: { bg: "gray.200" },
  cursor: "pointer"
};

const videoContainerStyles = (isMobile, video) => ({
  position: isMobile ? "relative" : "absolute",
  zIndex: 1,
  px: [-5, 0],
  w: ["100%", "200px", "260px"],
  overflow: "hidden",
  rounded: ["none", "lg"],
  boxShadow: video ? "xl" : "none",
  h: ["360px", "420px"]
});

const videoWrapperStyles = {
  w: ["100%", "200px", "260px"],
  ml: [0, 0, 0, 0, 6],
  mr: [0, 5, 8]
};

const ProfilePage = ({
  nickname,
  picture: photo,
  introduction,
  profession,
  categories = [],
  description,
  reviews = [],
  related = [],
  latest_bookings = [],
  price,
  currency = "NGN",
  rating = 4.5,
  celebrity_time = '3',
  slug_url,
  toNextPage
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: categoryData } = useGetCategories();
  const { useAuthDisclosure, coupon, isLoggedIn } = useContext(AppContext);
  const { onOpen: onWishListOpen} = useAuthDisclosure();
  const picture = (is_dev ? root_url : '') + photo;

  let isMobile = useMedia("(max-width: 420px)");
  let history = useHistory();
  let response_time = !!celebrity_time ? celebrity_time : '3';

  let celebCategories = (categoryData || []).filter(category =>
    categories.includes(category.id)
  );
  
  let retail_price = price;
  price = getCouponPrice(coupon, price, slug_url);

  return (
    <Box>
      <Box position="relative">
        <Box bg="gray.50" pt={[0, 8]} pb={8} px={[0, 6]}>
          <Flex {...wrapperProps}>
            <Box {...videoWrapperStyles}>
              <Box {...videoContainerStyles(isMobile, introduction)}>
                {(introduction && introduction.uuid_id !== 'bfb765bc-7243-479c-bf61-3d6b8f524a00') ? (
                  <VideoPlayer  
                    fluid={false}
                    width={"100%"}
                    objectFit="cover"
                    height="100%"
                    src={introduction.recording_url}
                    poster={introduction.snapshot_url}{...mediaProps}
                    autoPlay={true} 
                    showVolume={true} 
                    loop={true} 
                    muted={true} 
                    />
                ) : (
                  <Image src={picture} {...mediaProps} />
                )}
              </Box>
            </Box>
            <Box px={[5, 0]} pt={[6, 0]} flex={1}>
              <Heading>{nickname}</Heading>
              <Text fontSize="xl">{profession}</Text>

              <Linkify my={5} opacity={0.8} lineHeight="tall">
                <Text>{description}</Text>
              </Linkify>
              
              {price && (
                <ButtonGroup size="lg" mt={6} spacing={[3, 3, 6]}>
                  <Button
                    onClick={() => {
                      if (isLoggedIn) {
                        toNextPage()
                      } else {
                        onWishListOpen(
                          "login",
                          <AuthHeader {...{ nickname, picture }} />,
                          `/${slug_url}/book`
                        )
                      }
                    }}
                    fontSize="md"
                    variantColor="pink"
                    variant="solid"
                  >
                    Book now for {currencyFormatter(currency, price)}
                  </Button>
                  {!!coupon && !!coupon.code && (
                  <Box as="span">
                    <Box mr={["1.3rem", "1.5rem"]} textAlign="center" color="pink.400" fontSize="0.8rem">{`Actual price: `}<span className="price-slash">{currencyFormatter(currency, retail_price)}</span></Box>
                    {/* <Box mr={["1.3rem", "1.5rem"]} textAlign="center" fontSize="0.8rem">{` ${coupon.code} coupon applied `}</Box> */}
                  </Box>
                  )}

                  {/*<Button
                    display={["none", "none", "inline-block"]}
                    fontSize="md"
                    variantColor="pink"
                    onClick={() =>
                      onWishListOpen(
                        "login",
                        <WishListHeader {...{ nickname, picture }} />
                      )
                    }
                    leftIcon="heart"
                    variant="link"
                  >
                    Wishlist
                  </Button>*/}

                  {/* <IconButton
                    variant="outline"
                    variantColor="pink"
                    onClick={() =>
                      onWishListOpen(
                        "login",
                        <WishListHeader {...{ nickname, picture }} />
                      )
                    }
                    display={["inline-block", "inline-block", "none"]}
                    size="lg"
                    icon="heart"
                  /> */}
                </ButtonGroup>
              )}

              {<Stack mt={6} isInline align="center">
                <Icon name="time" />
                <Text fontSize="0.8rem">{`Typically responds in ${response_time} day${parseInt(response_time) > 1 ? 's' : ''}`}</Text>
              </Stack> }
            </Box>
          </Flex>
        </Box>
        <Box px={[5, 6]} py={[2, 8]}>
          <Flex {...wrapperProps}>
            <Box w={["100%", "200px", "300px"]} mr={[0, 5]} />
            <Box flex={1}>
              {reviews && reviews.length > 0 && (
                <Flex
                  direction={["column", "column", "column", "column", "row"]}
                >
                  <Box flex={1}>
                    <Text fontSize="lg" lineHeight="none" fontWeight="bold">
                      {Pluralize("review", reviews.length, true)}
                    </Text>
                    <Stack isInline align="center" spacing={4}>
                      <Rating size="lg" value={1} isReadOnly />
                      <Text fontSize="lg" mt={2}>
                        {rating} stars
                      </Text>
                    </Stack>
                    {reviews.length > 0 && (
                      <Box
                        mt={[5, 3]}
                        d={["block", "block", "block", "block", "none"]}
                      >
                        {reviews.slice(0, 1).map((review, index) => (
                          <Testimonial
                            {...review}
                            as="i"
                            key={index}
                            mb={[5, 3]}
                          />
                        ))}
                      </Box>
                    )}
                    {reviews.length > 1 && (
                      <Button
                        onClick={() => onOpen()}
                        variant="link"
                        variantColor="pink"
                      >
                        See all reviews
                      </Button>
                    )}
                  </Box>
                  <Box flex={1} d={["none", "none", "none", "none", "block"]}>
                    {reviews.slice(0, 1).map((review, index) => (
                      <Testimonial {...review} as="i" key={index} mb={5} />
                    ))}
                  </Box>
                </Flex>
              )}

              {celebCategories && (
                <Stack mt={5} isInline align="center">
                  {celebCategories.slice(0, 3).map((category, index) => (
                    <Tag
                      key={index}
                      onClick={() => history.push(`/c/${category.slug}`)}
                      {...tagStyles}
                    >
                      <TagLabel textTransform="capitalize">
                        {category.label}
                      </TagLabel>
                    </Tag>
                  ))}
                </Stack>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
      {latest_bookings && latest_bookings.length > 0 && (
        <LatestBookings {...{ latest_bookings }} />
      )}

      {related && related.length > 0 && <SimilarCelebs {...{ related }} />}

      <TestimonialModal {...{ isOpen, reviews, onClose }} />
    </Box>
  );
};

export default ProfilePage;

const LatestBookings = ({ latest_bookings = [] }) => {
  return (
    <Box pt={[20, 12, 24]} px={[5, 6]} {...wrapperProps}>
      <Heading size="lg" mb={6}>
        Latest videos
      </Heading>
      <Grid
        templateColumns={["none", "repeat(auto-fill, minmax(232px, 1fr))"]}
        rowGap={8}
        columnGap={8}
        w="100%"
      >
        {latest_bookings.slice(0, 6).map(booking => (
          <CelebrityVideo {...booking} key={booking.uuid} />
        ))}
      </Grid>
    </Box>
  );
};

export const SimilarCelebs = ({ related }) => {
  return (
    <Box py={[16, 24]} px={[5, 6]} mb="3rem" {...wrapperProps}>
      <Heading size="lg" mb={6}>
        Similar Celebrities
      </Heading>

      <CategoryGrid
        data={related}
        hasData={related.length > 0}
        dummyArray={[1, 2, 3, 4]}
      />
    </Box>
  );
};

const TestimonialModal = ({ isOpen, reviews, onClose }) => {
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent rounded="md">
        <ModalHeader borderBottomWidth="2xs">
          {Pluralize("Review", reviews.length, true)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {reviews.map(x => (
            <Testimonial key={x} py={5} />
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AuthHeader = ({ nickname, picture }) => {
  return (
    <Stack isInline align="center">
      <Avatar src={picture} name={nickname} />
      <Box>
        <Text fontWeight="medium" lineHeight="short">
          {nickname}
        </Text>
        <Text fontSize="sm" fontWeight="normal">
          Login to book
        </Text>
      </Box>
    </Stack>
  );
};

const WishListHeader = ({ nickname, picture }) => {
  return (
    <Stack isInline align="center">
      <Avatar src={picture} name={nickname} />
      <Box>
        <Text fontWeight="medium" lineHeight="short">
          {nickname}
        </Text>
        <Text fontSize="sm" fontWeight="normal">
          Login to add to wishlist
        </Text>
      </Box>
    </Stack>
  );
};
