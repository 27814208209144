import { useContext } from "react";
import { AppContext, createUrl } from "../authentication/AppProvider";

export const useBookingAction = () => {
  const { fetcher } = useContext(AppContext);
  // GET CELEB
  const getCelebrity = slug_url => {
    return fetcher("/celebrity/" + slug_url).then(res => res);
  };

  // GET CELEB BOOKINGS
  const getBookings = slug_url => {
    return fetcher(`/booking/${slug_url}/`).then(
      res => res
    );
  };

  const verifyBooking = booking_id => {
    return fetcher(`/verify-booking/${booking_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ uuid: booking_id })
    }).then(res => res);
  };

  const acceptBooking = (celeb, booking_id) => {
    return fetcher(`/booking/${celeb}/${booking_id}/accept/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(res => res);
  };

  const doReview = (booking_id, review, callback) => {
    return fetcher(`/peng/${booking_id}/review/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({order_review: review.order_review || '', order_rating: review.order_rating || 5})
    }).then(res => {
      if (!!callback) callback();
      return res;
    });
  };

  const completeBooking = (celeb, booking_id) => {
    return fetcher(`/booking/${celeb}/${booking_id}/complete/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(res => res);
  };

  const rejectBooking = (celeb, booking_id, reason) => {
    return fetcher(`/booking/${celeb}/${booking_id}/decline/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({'decline_reason': reason})
    }).then(res => res);
  };

  const getTransactions = slug_url => {
      return fetcher(`/transactions/${slug_url}/`).then(
        res => res
      );
    };

  const getProfile = slug_url => {
    return fetcher(`/celebrity/${slug_url}/profile/`).then(
      res => res
    );
  };
 
  const editProfile = (slug_url, profile, callback) => {
    return fetcher(`/celebrity/${slug_url}/edit_profile/`, {
      method: "POST",
      body: JSON.stringify(profile)
    }).then(res => {
      if (!!callback) callback();
      return res;
    });
  };

  
  const getUser = slug_url => {
    return fetcher(`/auth/user`).then(
      res => res
    );
  };

  
  // USER BOOKINGS
  const userBookings = () => {
    return fetcher(`/user_bookings/`).then(
      res => res
    );
  };

  return {
    rejectBooking,
    completeBooking,
    doReview,
    acceptBooking,
    verifyBooking,
    getBookings,
    getCelebrity,
    getTransactions,
    getProfile,
    editProfile,
    userBookings,
    getUser
  };
};
