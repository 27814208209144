export function scrolltoTop(style = "smooth") {
  return window.scroll({
    top: 0,
    behavior: style ? style : "auto"
  });
}

export function formatDBdate(created_date) {
  let dateTimeParts = created_date.split(/[- T:]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
  dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

  const dateObject = new Date(dateTimeParts[0], dateTimeParts[1], dateTimeParts[2]);
  const month = dateObject.toLocaleString('default', { month: 'long' });
  return `${dateObject.getDate()} ${month} ${dateObject.getFullYear()}`
}


export function getCouponPrice(coupon, price, slug_url) {
  if (!!coupon) {
    if (coupon.type === 'peng' || coupon.type === slug_url) {
      switch (coupon.discount_type) {
        case 'P':
          price = (1- coupon.perc_discount) * price;
          break;
        case 'B':
          price = price - coupon.base_discount;
          break;
        case 'PB':
          price = ((1- coupon.perc_discount) * price) - coupon.base_discount;
          break;
      } 
    }
  }
  return Number.parseFloat(price).toFixed(2);
}
