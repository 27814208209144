import React from "react";
import { Box } from "@chakra-ui/core";
import Navigation from "./NavigationBar";
import Footer from "./Footer";

const PageWrapper = ({
  children,
  navigationProps = {},
  footerProps = {},
  ...rest
}) => {
  return (
    <Box {...rest} position="relative" minH="100vh">
      <Box paddingBottom="12rem">
        <Navigation {...navigationProps} />
        {children}
      </Box>
      <Footer position="absolute" bottom="0" width="100%" {...footerProps} />
    </Box>
  );
};

export default PageWrapper;
