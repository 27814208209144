import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Grid,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { AppContext, is_dev } from "../authentication/AppProvider";
import LoadingPage from "../external-pages/LoadingPage";
import CelebrityVideo from "../celebrity-ui/CelebrityVideo"
import { useBookingAction, userBookings } from "../bookings/booking_actions";



const mapStatusToColor = {
  "New request": "green",
  "Awaiting video": "orange",
  "Pending approval": "cyan",
  Completed: "blue",
  Declined: "red",
  Expired: "red",
  Cancelled: "red"
};

const mediaProps = {
  height: "100%",
  weight: "110%",
  borderRadius: "8px 8px 0px 0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const contentWrapper = {
  height: "400px",
  direction: "column",
  borderWidth: "2xs",
  borderColor: "gray.100",
  boxShadow: "sm",
  borderRadius: "8px",
  overflow: "hidden",
  bg: "white"
};

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const getBookingStatus = ({
  accepted,
  declined,
  approved,
  expired,
  completed,
  cancelled
}) => {
  let status,
    isDisabled = false;

  if (!expired && !cancelled) {
    if (!accepted && !declined) status = "New request";
    if (accepted && !completed) status = "Awaiting video";
    if (completed && !approved) {
      status = "Pending approval";
      isDisabled = true;
    }
    if (completed && approved) {
      status = "Completed";
      isDisabled = true;
    }
    if (declined) {
      status = "Declined";
      isDisabled = true;
    }
  }
  if (expired) {
    status = "Expired";
    isDisabled = true;
  }
  if (cancelled) {
    status = "Cancelled";
    isDisabled = true;
  }

  const color = status && mapStatusToColor[status];

  return { color, status, isDisabled };
};

const options = ["Active",/*"Pending",*/ "Completed", "Cancelled"];
const bookingOptions = options.map(option => ({
  label: option,
  value: option.toLowerCase()
}));

const gridProps = {
  display: "grid",
  gridTemplateColumns: ["none", "repeat(auto-fill, minmax(232px, 1fr))"],
  // gridTemplateColumns: ["none", "repeat(2, 1fr)"],
  gridRowGap: 6,
  gridColumnGap: 6
};
const wrapperProps = {
  maxW: ["100%", "100%", "100%", "920px"],
  mx: "auto",
  direction: ["column", "row"]
};
function getBookingData(initialData = [], filter) {
  let finalData, showListView;
  if (!Array.isArray(initialData)) {
    initialData = [];
  }
  if (filter === "active") {
    let awaitingData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Awaiting video"
    );
    let newRequestData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "New request"
    );
    finalData = [...awaitingData, ...newRequestData];
    showListView = true;
  }
  if (filter === "pending") {
    finalData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Pending approval"
    );
    showListView = false;
  }
  if (filter === "completed") {
    finalData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Completed"
    );
    showListView = false;
  }
  if (filter === "cancelled") {
    const cancelledStatus = ["Declined", "Expired", "Cancelled"];
    finalData = initialData.filter(dataObj =>
      cancelledStatus.includes(getBookingStatus(dataObj).status)
    );
    showListView = true;
  }
  return { finalData, showListView };
}


const UserBookings = ({ data: dataProp = [] }) => {
  const { user, isLoggedIn, isCeleb, checkingAuth } = useContext(AppContext);
  const { userBookings } = useBookingAction();
  const [data, setInputData] = useState(dataProp || []);
  const [outputData, setOutputData] = useState(data);
  const [filter, setFilter] = useState("active");
  const [isListView, setIsListView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [declineReason, setDeclineReason] = useState("");
  // const [bookings, setBookings] = useState({});
  // const [targetBooking, setTargetBooking] = useState({});
  let history = useHistory();

  let slug_url = !!user && !!user.public ? user.public.slug_url : null;

  useEffect(() => {
    if (isLoggedIn) {
      userBookings().then(res => {setInputData(res); setIsLoading(false)})
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && !checkingAuth) {
      setInputData([]);
      history.push('/')
    }
  }, [isLoggedIn, checkingAuth]);



  useEffect(() => {
    const { finalData, showListView } = getBookingData(data, filter);
    setOutputData(finalData);
    setIsListView(showListView);
  }, [filter, data]);

  

  return (
    <Box>
      {isLoading ? <LoadingPage /> : ( 
        <>
      <Box>
        {data && data.length > 0 ? (<Bookings {...{ data }} />) 
        : (<EmptyBookings />)}
      </Box>
      </>)}
    </Box>
  );
  

};


export default UserBookings;

const Bookings = ({data}) => {
  return (
    <Box {...wrapperProps}>
      <Heading size="lg" mb={6}>
        Latest videos
      </Heading>
      <Grid
        templateColumns={["none", "repeat(auto-fill, minmax(232px, 1fr))"]}
        rowGap={8}
        columnGap={8}
        w="100%"
      >
        {data.map(booking => (
          <CelebrityVideo {...booking} key={booking.uuid} />
        ))}
      </Grid>
    </Box>
  );
};


const EmptyBookings = () => {
  return (
    <Flex justify="column" minH="50vh" align="center" justifyContent="center">
      <Stack textAlign="center" align="center" spacing={6}>
        <Icon name="cube-box" size="128px" />
        <Text fontSize="xl">There are no bookings yet</Text>
      </Stack>
    </Flex>
  );
};
