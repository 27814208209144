import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppContext } from "../authentication/AppProvider";
import BookingConfirmation from "../bookings/BookingConfirmation";
import BookingCancelled from "../bookings/BookingCancelled";
import FAQ from "../external-pages/FAQ";
import Press from "../external-pages/Press";
import LoadingPage from "../external-pages/LoadingPage";
import Login from "../external-pages/Login";
import ResetPassword from "../external-pages/ResetPassword";
import CompleteResetPassword from "../external-pages/CompleteResetPassword";
import Privacy from "../external-pages/Privacy";
import Signup from "../external-pages/Signup";
import Terms from "../external-pages/Terms";
import BlogList from "../external-pages/BlogList";
import BlogPost from "../external-pages/BlogPost";
import Charity from "../external-pages/Charity";
import TalentTerms from "../external-pages/TalentTerms";
import CelebrityDashboard from "../internal-pages/CelebrityDashboard";
import UserDashboard from "../internal-pages/UserDashboard";
import CompletedVideo from "../bookings/CompletedVideo";
import TalentSignup from "../celebrity-ui/TalentSignup"
import {useToast} from "@chakra-ui/core"

const HomePage = React.lazy(() => import("../external-pages/HomePage"));
const CelebrityProfile = React.lazy(() =>
  import("../celebrity-ui/CelebrityProfile")
);
const BookCelebrity = React.lazy(() => import("../bookings/BookCelebrity"));

let bookingRoutes = [
  // {
  //   path: "/:slug_url/book",
  //   exact: false,
  //   Component: BookCelebrity,
  //   destination: "/booking-confirmed"
  // },
  {
    path: "/:slug_url",
    exact: true,
    Component: CelebrityProfile,
    destination: "/book"
  }
];

const BookingFlow = () => {
  const history = useHistory();
  const toast = useToast();
  const { authService } = useContext(AppContext);
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route
        path="/accounts/confirm-email/:key"
        render={({ match }) => {
          let keys = match.params.key;
          authService.verifyEmail(keys).then((res) => {
            if (!!res && !!res.detail) {
              if (res.detail === "ok") {
                toast({
                  title: "Thanks for verifying your email.",
                  description: "You can now log in with your email.",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                })
                history.push('/login')
              } else {
                toast({
                  title: "Oops!",
                  description: "Verification unsuccessful.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                })
              }
            }
          }).catch((err) => {
            toast({
              title: "Oops!",
              description: "Unknown error.",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
            console.log(err)
          })
        }
      }

      />
      <Route exact path="/auth/password-reset/:uib64/:token" component={CompleteResetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/blog" component={BlogList} />
      <Route exact path="/charity" component={Charity} />
      <Route exact path="/blog/:slug" component={BlogPost} />
      <Route exact path="/talent-terms" component={TalentTerms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/press" component={Press} />
      <Route exact path="/talent-signup" component={TalentSignup} />
      <Route exact path="/peng/:booking_id" component={CompletedVideo} />
      <Route celeb={true} path="/celebrity">
        <React.Suspense fallback={<LoadingPage />}>
          <CelebrityDashboard />
        </React.Suspense>
      </Route>
      <PrivateRoute path="/user" component={UserDashboard}>
        <React.Suspense fallback={<LoadingPage />}>
          <UserDashboard />
        </React.Suspense>
      </PrivateRoute>
      <Route path="/booking/:booking_id">
        <React.Suspense fallback={<LoadingPage />}>
          <BookingConfirmation />
        </React.Suspense>
      </Route>
      <Route path="/booking_cancelled/:booking_id">
        <React.Suspense fallback={<LoadingPage />}>
          <BookingCancelled />
        </React.Suspense>
      </Route>
      />
      {bookingRoutes.map((route, index) => {
        let { Component, destination, path, ...rest } = route;
        return (
          <Route {...rest} path={path} key={index}>
            <React.Suspense fallback={<LoadingPage />}>
              <Component
                toNextPage={slug_url => {
                  history.push(`/${slug_url}${destination}`);
                }}
              />
            </React.Suspense>
          </Route>
        );
      })}
      <PrivateRoute exact={true} destination="/booking-confirmed" path="/:slug_url/book" component={BookCelebrity}></PrivateRoute>
      <Route path="/">
        <React.Suspense fallback={<LoadingPage />}>
          <HomePage />
        </React.Suspense>
      </Route>
    </Switch>
  );
};

export default BookingFlow;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading, isLoggedIn } = useContext(AppContext);

  return (
    <Route {...rest}>
      {props => {
        if (isLoading) {
          return <h2>Loading...</h2>;
        } else {
          // console.log({ user });
          // console.log({ isLoggedIn });
          if (!isLoggedIn) {
            return <Redirect to="/login" />;
          } else if (isLoggedIn && rest.celeb) {
            if (!!user && !user.is_celeb) {
              return <Redirect to="/" />;
            }
            return (<React.Suspense fallback={<LoadingPage />}>
                      <Component {...props} />
                  </React.Suspense>);
          } else {
            return (<React.Suspense fallback={<LoadingPage />}>
              <Component {...props} />
          </React.Suspense>);
          }
        }
      }}
    </Route>
  );
};
