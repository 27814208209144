import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext, root_url, is_dev } from "../authentication/AppProvider";
import { useGetSeachResults } from "../celebrity-ui/hooks";
import { useMedia, useScrollYPosition } from "../external-pages/utils/hooks";
import AutoComplete from "../form-elements/AutoComplete";
import {useAsync} from 'react-use';

export const pengPink = "/img/logo.png";
export const pengWhite = "/img/logo_white.png";

const iconProps = {
  size: "16px",
  name: "search",
  color: "pink.400"
};
const inputProps = {
  placeholder: "Type a celeb...",
  fontSize: "md",
  size: "md",
  focusBorderColor: "pink.400"
};

const stickyWrapperProps = (hideBorder, scrollThreshold, isHomePage) => ({
  top: 0,
  bg: isHomePage & !scrollThreshold ? "black" : "white",
  zIndex: "sticky",
  position: isHomePage && !scrollThreshold ? "relative" : "sticky",
  borderBottomWidth: hideBorder ? "none" : "2xs",
  borderColor: isHomePage & !scrollThreshold ? "black" : "gray.200"
});

const buttonProps = (isHomePage, scrollThreshold) => ({
  variant: "link",
  size: "md",
  variantColor: "pink",
  color: isHomePage && !scrollThreshold ? "white" : "pink.500"
});

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto"
};

const Navigation = ({
  children,
  isHomePage,
  hideAutoComplete = false,
  hideLoginButton = false,
  hideSignupButton = false,
  hideBorder = false,
  ...rest
}) => {
  const { useAuthDisclosure, isLoggedIn, isCeleb, user, authService } = useContext(AppContext);
  const { onOpen } = useAuthDisclosure();
  const [query, setQuery] = useState("");
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { data, hasData } = useGetSeachResults(query);
  const { scrollThreshold, y } = useScrollYPosition(700, 850, true);

  let history = useHistory();
  let isMobile = useMedia("(max-width: 420px)");

  let initialLogo = isHomePage ? pengWhite : pengPink;
  const [logo, setLogo] = useState(initialLogo);

  useEffect(() => {
    setLogo(isHomePage && !scrollThreshold ? pengWhite : pengPink);
  }, [y, scrollThreshold, isHomePage]);

  useEffect(() => {
    setLogo(isHomePage && !scrollThreshold ? pengWhite : pengPink);
  }, [user]);

  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen
  } = useDisclosure();

  let suggestions = data || [];

  const handleChange = value => {
    return setQuery(value);
  };

  const handleLogout = () => {
    setIsLoggingOut(true);
    authService.logout(() => { setIsLoggingOut(false) });
  }

  const handleLogoClick = () => {
    let openDrawer = isMobile && !hideLoginButton && !hideSignupButton;
    if (openDrawer) {
      onDrawerOpen();
    } else {
      history.push("/");
    }
  };

  const menuButtonRef = React.createRef();


  return (
    <>
      <Box {...stickyWrapperProps(hideBorder, scrollThreshold, isHomePage)}>
        <Box {...wrapperProps}>
          <Flex
            py={3}
            px={[5, 6]}
            align="center"
            justify="space-between"
            {...rest}
          >
            <Stack
              isInline
              align="center"
              spacing={5}
              w={["100%", "75%", "80%", "60%"]}
              transition="all 1.2s ease-in-out"
            >
              <Box
                cursor="pointer"
                maxWidth={[168, 168]}
                onClick={handleLogoClick}
              >
                <Image
                  src={logo}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  alt="Peng"
                />
              </Box>

              {!hideAutoComplete &&
                (((isHomePage && scrollThreshold) || !isHomePage) ? (
                  <AutoComplete
                    hasLoadedData={hasData}
                    onChange={value => setQuery(value)}
                    onSuggestionSelected={(_, { suggestion }) => {
                      history.replace(`/${suggestion.slug_url}`);
                    }}
                    options={suggestions}
                    {...{ iconProps, inputProps }}
                  />
                ) : (
                    <Box py={5} w="100%" />
                  ))}
            </Stack>
            {children
              ? children
              : !isLoggedIn && (
                <Stack
                  d={["none", "block"]}
                  isInline
                  align="center"
                  spacing={4}
                >
                  {!hideLoginButton && (
                    <Button
                      onClick={() => onOpen("login")}
                      {...buttonProps(isHomePage, scrollThreshold)}
                    >
                      Log in
                      </Button>
                  )}
                  {!hideSignupButton && (
                    <Button
                      onClick={() => onOpen("signup")}
                      {...buttonProps(isHomePage, scrollThreshold)}
                    >
                      Sign up
                      </Button>
                  )}
                </Stack>
              )}

            {isLoggedIn && (
              <Stack d={["none", "block"]} isInline align="center" spacing={4}>
                {isCeleb && (
                  <Button
                    as="a"
                    href="/celebrity"
                    {...buttonProps(isHomePage, scrollThreshold)}
                  >
                    Bookings
                  </Button>
                )}
                {
                  <Menu>
                    {({ isOpen }) => (
                      <React.Fragment>
                        <MenuButton isActive={isOpen} ref={menuButtonRef}>
                          <Box isInline>
                            <Avatar size="sm" name={!!user ? `${user.first_name} ${user.last_name}` : ''} src={(is_dev ? root_url : '') + (!!user ? user.picture : '')} />
                            <Icon name={isOpen ? "triangle-up" : "triangle-down"} ml="4px" size="12px" />
                          </Box>
                        </MenuButton>
                        <MenuList mr="90px">
                          <MenuGroup title="Profile">
                            {isCeleb && (<MenuItem onClick={() => { history.push("/celebrity"); }}>Talent Dashboard</MenuItem>)}
                            {isLoggedIn && (<MenuItem onClick={() => { history.push("/user"); }}>User Dashboard</MenuItem>)}
                            <MenuItem onClick={handleLogout}>Logout </MenuItem>
                          </MenuGroup>
                          <MenuDivider />
                          <MenuGroup title="Help">
                            <MenuItem onClick={() => { history.push("/faq"); }}>FAQ</MenuItem>
                            <MenuItem onClick={() => { history.push("/terms"); }}>Terms</MenuItem>
                          </MenuGroup>
                        </MenuList>
                      </React.Fragment>
                    )}
                  </Menu>
                }
              </Stack>
            )}
            <Icon onClick={handleLogoClick} d={["block", "none"]} ml="20px" color={!scrollThreshold & isHomePage ? "white" : "gray.600"} name="menu" size="32px" />

          </Flex>
        </Box>
      </Box>

      <MobileDrawer
        {...{
          isDrawerOpen,
          onDrawerClose,
          isLoggedIn,
          hideLoginButton,
          onOpen,
          hideSignupButton,
          isCeleb,
          user,
          handleLogout
        }}
      />
    </>
  );
};

export default Navigation;

const MobileDrawer = ({
  isDrawerOpen,
  onDrawerClose,
  hideLoginButton,
  isLoggedIn,
  onOpen,
  hideSignupButton,
  isCeleb,
  user,
  handleLogout
}) => {
  let history = useHistory();
  
  return (
    <Drawer
      size="xs"
      isOpen={isDrawerOpen}
      placement="left"
      isFullHeight
      onClose={onDrawerClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader maxWidth="144px">
          <Image src={pengPink} w="100%" h="100%" objectFit="cover" />
        </DrawerHeader>

        <DrawerBody fontSize="xl" color="pink.400">
          {isLoggedIn && (
          <Stack alignItems="center" mb="40px" mx="-1.5rem" backgroundColor="#ebebeb" p="1rem">
            <Avatar size="2xl" name={`${user.first_name} ${user.last_name}`} src={(is_dev ? root_url : '') + user.picture} />
            <Text>{`${user.first_name} ${user.last_name}`}</Text>
          </Stack>
          )}
          <Stack spacing={5} mt={3}>
            {!hideLoginButton && !isLoggedIn && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  onOpen("login");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Log in</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            {!hideSignupButton && !isLoggedIn && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  onOpen("signup");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Sign up</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            {isLoggedIn && isCeleb && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  history.push("/celebrity");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Talent Dashboard</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            {isLoggedIn && isCeleb && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  history.push("/user");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>User Dashboard</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            {isLoggedIn && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  handleLogout();
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Logout</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            <Stack
              onClick={() => {
                history.push("/");
                onDrawerClose();
              }}
              justify="space-between"
              isInline
              align="center"
            >
              <Text>Home</Text>
              <Icon name="home" size="32px" />
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
