import { Avatar, AvatarBadge, Heading, Stack, Icon, Box, Text, Link, Flex, Grid, Image, Stock } from "@chakra-ui/core";
import React, { useState, useContext, useEffect } from "react";
import PageWrapper from "./PageWrapper";
import { Helmet } from "react-helmet";
import useSWR, { mutate } from "swr";
import { AppContext, root_url, is_dev } from "../authentication/AppProvider";
import LoadingPage from "../external-pages/LoadingPage";
import { useAsync } from 'react-use';
import { useHistory } from "react-router";
import BlogImage from "./BlogImage";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12],
};


export function Post({ post, callback }) {
  const image = (is_dev ? root_url : '') + post.author.picture;
  
  return (
    <Box _hover={{
      transition: "all .4s ease",
      boxShadow: "8px 28px 50px rgba(39,44,49,.07), 1px 6px 12px rgba(39,44,49,.04)",
      transform: "translate3D(0,-1px,0) scale(1.02)"

    }} 
    onClick={() => callback(post)}
    cursor={"pointer"}>
      <BlogImage image_height={"200px"} picture={post.thumbnail}></BlogImage>
      <Box p={5} h="250px" shadow="md" borderWidth="1px">
        <Box h="170px">
          <Text color={"#738a94"} fontSize="sm">{post.tags.join(', ').toUpperCase()}</Text>
          <Heading fontSize="xl" pd={"6px"}>{post.title}</Heading>
          <div style={{color:'rgba(0, 0, 0, 0.54)'}} dangerouslySetInnerHTML={{ __html: post.excerpt }} />
        </Box>
        <Box display={"inline"}>
          <Avatar size="sm" name={post.author.first_name} src={image} />
  <Box color={"#738a94"} float={"right"} as="span" fontSize="sm">{!!post.read_time ? post.read_time.toUpperCase() : ''}<br/>{`${post.views} views`}</Box>
        </Box>
      </Box>
    </Box>
  );
}


const EmptyPosts = () => {
  return (
    <Flex justify="column" minH="50vh" align="center" justifyContent="center">
      <Stack textAlign="center" align="center" spacing={6}>
        <Icon name="cube-box" size="128px" />
        <Text fontSize="xl">There are no posts yet</Text>
      </Stack>
    </Flex>
  );
};



const BlogList = props => {
  const { fetcher } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [hasData, setHasData] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = () => {
      let url = `/posts/`;
      fetcher(url).then(response => {
        setData(response);
        setHasData(true);
      });
    };
    fetchData();
  }, [fetcher]);

  const viewBlog = (post) => {
    history.push(`/blog/${post.slug}`);
  }

  return hasData ? (
    <PageWrapper>
      <Helmet>
        <title>{"Peng Stories - PENG"}</title>
        <meta name="description" content={`Blog`} />
        <meta name="keywords" content="celebrity,gifts,peng,blog" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Peng Blog`} />
        <meta property="og:image" content={`https://sendpeng.com/logo.png`} />
      </Helmet>
      <Box bg="gray.50" minH={'calc(100vh - 400px)'}>
        <Box {...wrapperProps}>
          <Heading as="h1">PENG Stories</Heading>
          <br />
          {data.length > 0 ? (<Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(auto-fill, minmax(270px, 1fr))"
            ]}
            rowGap={6}
            columnGap={5}
            w="100%"
          >
            {data.map((post) => <Post key={post.slug} post={post} callback={viewBlog} />)}
          </Grid>) : <EmptyPosts />}
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </PageWrapper>
  ) : (
      <LoadingPage />
    );;
};

export default BlogList;
