// @refresh reset
import { useEffect, useContext } from "react";
import useSWR, { mutate } from "swr";
import { AppContext, createUrl } from "../authentication/AppProvider";

const fetcher = (url, config = {}, ignoreError = false) => {
  // if (!ignoreAuth) {
  //   const token = tokenConfig(authToken);
  //   config = { ...config, ...token };
  // }
  return fetch(createUrl(`${url}`), config).then(response => {
    if (response.status < 400) {
      return response.json();
    }

    return !ignoreError ? response.json() : [];
  });
};


// export function usePrefetcher(slug) {
//   const helper = callback => {
//     fetcher(getUrl("/celebrity", slug)).then(res => {
//       mutate(getUrl("/celebrity", slug), res);
//     });
//   };
//   return helper;
// }

export function useGetCategories(options = {}) {
  const { isDependent } = options;
  const url = isDependent ? () => `/categories` : `/categories`;

  const { data, ...rest } = useSWR(url, fetcher);

  let result = data || [];
  // useEffect(() => {
  //   if (data) {
  //     data
  //       .filter(x => x.count > 0)
  //       .forEach(x => {
  //         fetcher(`/celebrities/${x.slug}/`, {importance: "low"}).then(res => {
  //           mutate(`/celebrities/${x.slug}/`, res);
  //         });
  //       });
  //   }
  // }, [result.length, data, fetcher]);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetCelebrities(options = {}) {
  const { isDependent, category } = options;
  const url = isDependent
    ? () => `/celebrities/${category}/`
    : `/celebrities/${category}/`;

  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetSeachResults(query) {
  const url = Boolean(query) && "/search/" + query;

  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetCeleb(query) {
  const url = query && `/celebrity/${query}/`;
  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}


export function useGetLocation() {
  const url = `/locate/`;
  const { data, ...rest } = useSWR(url, fetcher);
console.log(data)
  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetPeng(booking_id) {
  const url = booking_id && `/peng/${booking_id}`;
  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: !!data,
    ...rest
  };
}
