import {
    Alert,
    AlertIcon,
    Box,
    Button,
    ButtonGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Image,
    Icon,
    IconButton,
    Text,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
    Radio,
    RadioButtonGroup,
    Stack,
    Tag,
    TagLabel,
    useToast,
    useDisclosure,
    Checkbox
} from "@chakra-ui/core";
import { Field, Formik } from "formik";

import React, { useEffect, useState, useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import VideoPlayer from "../data-display/VideoPlayer";
import { useMedia } from "../external-pages/utils/hooks";
import { AppContext, is_dev, root_url, createUrl } from "../authentication/AppProvider";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Linkify from 'react-linkify';
import Pluralize from "pluralize";
import Rating from "../data-display/Rating";
import Testimonial from "../data-display/Testimonial";
import { useBookingAction } from "../bookings/booking_actions";
import ChangePasswordForm from "../authentication/ChangePasswordForm"
import LoadingPage from "../external-pages/LoadingPage";
import ImageUploader from "./ImageUploader";
import TalentEditForm from "./TalentEditForm";
import currencyFormatter from "../data-display/Currency";
// import { CreateVideo } from "../bookings/CelebrityBookings"

const wrapperProps = {
    maxW: ["100%", "100%", "100%", "1152px"],
    // mx: "auto",
    // px: [0, 6],
    // py: [8, 12],
    flexDirection: ["column", "row"],
};

const videoContainerStyles = {
    position: "relative",
    zIndex: 1,
    // px: [-5, 0],
    // w: ["100%", "200px", "260px"],
    overflow: "hidden",
    rounded: ["none", "lg"],
    boxShadow: "xl",
    h: ["100vw", "300px", "300px", "300px"],
};

const videoWrapperStyles = {
    position: "relative",
    w: ["100%", "300px", "300px", "300px"],
    // ml: [0, 0, 0, 0, 6],
    mr: [0, 5, 8]
};



function usePipe() {
    let [loadedScript, setLoadedScript] = useState(true);

    useEffect(() => {
        initializePipe();
    });

    function initializePipe() {
        if (!loadedScript) {
            // let addpipeCSS = document.createElement("link");
            // addpipeCSS.rel = "stylesheet";
            // addpipeCSS.href = "https://cdn.addpipe.com/2.0/pipe.css";
            // let addpipeJS = document.createElement("script");
            // addpipeJS.src = "https://cdn.addpipe.com/2.0/pipe.js";
            // document.body.appendChild(addpipeCSS);
            // document.body.appendChild(addpipeJS);
            setLoadedScript(true);
        }
    }

    function onLoadPipe(profile, callback, setVideo) {
        if (loadedScript && !!profile.uuid) {
            const video_key = 'd2f1c9e06f6ce047bc3ddffe535eeb43';//process.env.VIDEO_KEY;
            const video_env = is_dev ? 'PtRWYL' : 'zgSG0j' //  process.env.VIDEO_ENV;
            var pipeParams = {
                size: {
                    width: "100%",
                    height: "100%"
                },
                qualityurl: "avq/720p.xml",
                accountHash: video_key,
                eid: video_env,
                sis: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 0 : 1,
                asv: 0,
                payload: `"{'type': 'introduction', 'id': '${profile.uuid}'}"`,
                st: 1,
                showMenu: 1,
                dup: 1,
                mrt: 120
            };

            if (!!window.PipeSDK) {
                const elementId = "peng-video";
                var elementExists = !!document.getElementById(elementId);
                // if (elementExists) {

                window.PipeSDK.insert(elementId, pipeParams, function (myRecorder) {
                    myRecorder.onReadyToRecord = function (id, type) { };

                    var successCallback = function () {
                        myRecorder.remove();
                        callback(profile);
                        setVideo(true)
                    }

                    myRecorder.onSaveOk = function (
                        recorderId,
                        streamName,
                        streamDuration,
                        cameraName,
                        micName,
                        audioCodec,
                        videoCodec,
                        fileType,
                        videoId,
                        audioOnly,
                        location
                    ) {
                        var args = Array.prototype.slice.call(arguments);
                        console.log("onSaveOk(" + args.join(", ") + ")");
                        successCallback()
                        //TODO: Tell them Video is Complete
                    }

                    myRecorder.onDesktopVideoUploadSuccess = function (recorderId, filename, filetype, videoId, audioOnly, location) {
                        var args = Array.prototype.slice.call(arguments);
                        console.log("onDesktopVideoUploadSuccess(" + args.join(', ') + ")");
                        successCallback()
                    }

                    myRecorder.onVideoUploadSuccess = function (recorderId, filename, filetype, videoId, audioOnly, location) {
                        var args = Array.prototype.slice.call(arguments);
                        console.log("onVideoUploadSuccess(" + args.join(', ') + ")");
                        successCallback()
                    }


                });

                // } 
            }
        }
    }
    return [loadedScript, onLoadPipe];
}


const UserProfile = props => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user, isLoggedIn, isCeleb, checkingAuth } = useContext(AppContext);

    const [picture, setPicture] = useState('');
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [imageRef, setImageRef] = useState(null);
    const fileInput = React.createRef();
    let isMobile = useMedia("(max-width: 420px)");
    let reviews = [];
    const [loadedScript, onLoadPipe] = usePipe(false);
    const {
        isOpen: isCreateVideoOpen,
        onClose: onCreateVideoClose,
        onOpen: onCreateVideoOpen
    } = useDisclosure();

    const {
        isOpen: isEditProfileOpen,
        onClose: onEditProfileClose,
        onOpen: onEditProfileOpen
    } = useDisclosure();

    let slug_url = !!user && !!user.public ? user.public.slug_url : null;

    const { getProfile, editProfile, setCelebrityImage } = useBookingAction();



    useEffect(() => {
        if (isLoading && !!slug_url && isLoggedIn && isCeleb) {
            getProfile(slug_url)
                .then(res => {
                    setProfile(res);
                    let pic = (is_dev ? root_url : '') + res.picture;
                    setPicture(pic)

                }).then(() => {
                    setIsLoading(false)
                })
        }
    }, [isLoading]);
    // useEffect(() => {
    //     if (!!slug_url && isLoggedIn && isCeleb) {
    //         getProfile(slug_url)
    //             .then(res => {
    //                 setProfile(res);
    //                 let pic = (is_dev ? root_url : '') + res.picture;
    //                 setPicture(pic)
    //                 setIsLoading(false)
    //             })
    //     }
    // }, [slug_url, isLoggedIn, isCeleb]);

    let celeb = !!user && !!user.public ? user.public : null;
    // let video = null;

    // let { nickname, picture, description, currency, price, profession, response_time} = celeb;

    let profile_pic = !!user && !!user.public ? user.public.slug_url : null;
    const currency = 'NGN'

    const onSubmit = (response) => {
        onClose()
    }

    const markComplete = () => {
        // let slug_url = !!user && !!user.public ? user.public.slug_url : null;
        // if (!!slug_url) {
        //   completeBooking(slug_url, booking.uuid).then((res) => {
        //     let targetBooking = data.find(({ uuid }) => uuid === booking.uuid);
        //     let remainingBookings = data.filter(({ uuid }) => uuid !== booking.uuid);
        //     let newBooking = { ...targetBooking, completed: true };
        //     let newData = [...remainingBookings, newBooking];
        //     setInputData(newData);
        //   });;
    }

    const handleRecordVideo = () => {
        onCreateVideoOpen();
    }

    const onProfileEdit = (value) => {
        return editProfile(slug_url, value)
            .then((res) => {
                setProfile({ ...profile, ...res })
                onEditProfileClose()
            });
    }

    const getCelebPayout = (profile) => {
        let celeb_payout_percentage = 1 - (profile.charity_percentage*0.01);
        return currencyFormatter(profile.account.currency, (profile.account.booking_percentage * profile.price * celeb_payout_percentage));
    }

    const getCharityPayout = (profile) => {
        let charity_payout_percentage = (profile.charity_percentage*0.01);
        return charity_payout_percentage > 0 ? currencyFormatter(profile.account.currency, (profile.account.booking_percentage * profile.price * charity_payout_percentage)) : 0;
    }

    return (
        <Box mb={10}>
            <Flex justify="space-between" mb={6}>
                <Heading>Talent Profile</Heading>
            </Flex>
            {isLoading ? <LoadingPage /> :
                (
                    <>

                        <Box position="relative">
                            <Box bg="gray.50" pt={[0, 8]} pb={8} px={[0, 6]}>
                                <Flex {...wrapperProps}>
                                    <Box {...videoWrapperStyles}>
                                        <Box {...videoContainerStyles}>
                                            <ImageUploader imageUrl={picture} actionUri={createUrl(`/celebrity/${slug_url}/profile_image/`)} ref={fileInput} setPicture={setPicture} />
                                        </Box>
                                    </Box>
                                    <Box px={[5, 0]} pt={[6, 0]} flex={1}>
                                        <Heading>{profile.nickname}</Heading>
                                        <Text fontSize="xl">{profile.profession}</Text>
                                        <Linkify my={5} opacity={0.8} lineHeight="tall">
                                            <Text>{profile.description}</Text>
                                        </Linkify>
                                        <Text fontSize="1.5rem" color="deeppink" mt={4}>{currencyFormatter(profile.account.currency, profile.price)}</Text>
                                        {/* {profile.price && (
                                        <ButtonGroup size="lg" mt={6} spacing={[3, 3, 6]}>
                                                <Button
                                                    fontSize="md"
                                                    variantColor="pink"
                                                    variant="solid"
                                                >
                                                    Book now for
                                                </Button>
                                            </ButtonGroup>
                                        )} */}
                                        {<Stack mt={6} isInline align="center">
                                            <Icon name="money" />
                                            <Text fontSize="0.8rem">{`Payout per shoutout booking -  ${getCelebPayout(profile)}`}</Text>
                                        </Stack>}
                                        {profile.charity_percentage > 0 && <Stack mt={4} isInline align="center">
                                            <Icon name="charity" />
                                            <Text fontSize="0.8rem">{`Charity Donation per booking -  ${getCharityPayout(profile)}`}</Text>
                                        </Stack>}
                                        {<Stack mt={4} isInline align="center">
                                            <Icon name="time" />
                                            <Text fontSize="0.8rem">{`Typically responds in ${profile.celebrity_time} day${parseInt(profile.response_time) > 1 ? 's' : ''}`}</Text>
                                        </Stack>}

                                        <Box px={[0, 0]} py={[2, 8]}>
                                            <Button
                                                fontSize="md"
                                                variantColor="pink"
                                                variant="solid"
                                                onClick={onEditProfileOpen}
                                            >
                                                Edit Profile
                                            </Button>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Flex flexDirection={["column", "row"]} justifyContent="space-between">
                            <Box pt={[20, 12, 24]} px={0} {...wrapperProps} flex={"1"}>
                                <Heading size="lg" mb={6}>Introduction Video</Heading>
                                <Box w={["100%", "300px"]}>
                                    {!!profile.introduction &&
                                        <Box flex={1} overflow="hidden"
                                            height="400px"
                                            direction="column"
                                            borderWidth="2xs"
                                            borderColor="gray.100"
                                            boxShadow="sm"
                                            borderRadius="8px"
                                            overflow="hidden"
                                            bg="white"
                                            borderRadius="8px 8px 0px 0px">
                                            <VideoPlayer
                                                // {...mediaProps}
                                                fluid={false}
                                                width={"100%"}
                                                objectFit="cover"
                                                height="100%"
                                                controls={true}
                                                src={profile.introduction && profile.introduction.recording_url}
                                                poster={profile.introduction && profile.introduction.snapshot_url}
                                            />
                                        </Box>
                                    }
                                    {
                                        (<>
                                            <Button w={"100%"} leftIcon={"video-camera"} variantColor="pink" variant="solid" onClick={handleRecordVideo}>Record your intro video</Button>

                                        </>)
                                    }
                                </Box>
                            </Box>
                            <Box pt={[20, 12, 24]} px={0} {...wrapperProps} flex={"1"}>
                                <Heading size="lg" mb={6}>Payment Details</Heading>
                                <Box>
                                    <Flex flexDirection={["column"]} justify="space-between">
                                        {/* <Box>
                                        <Text>Bank/Account</Text>
                                        <Text>{`(${profile.account.bank || '-'}) / ${profile.account.account_number}`}</Text>
                                    </Box> */}
                                        <Box pb={"1rem"}>
                                              <Heading as="h5" size="sm">Account Name</Heading>
                                            <Text>{`${user.first_name} ${user.last_name}`}</Text>
                                        </Box>
                                        <Box pb={"1rem"}>
                                            <Heading as="h5" size="sm">Bank</Heading>
                                            <Text>{`${profile.account.bank}`}</Text>
                                        </Box>
                                        <Box pb={"1rem"}>
                                            <Heading as="h5" size="sm">Account Number</Heading>
                                            <Text>{`${profile.account.account_number}`}</Text>
                                        </Box>
                                        <Box pb={"1rem"}>
                                            <Heading as="h5" size="sm">Payment Frequency</Heading>
                                            <Text>{`${profile.account.payment_frequency}`}</Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>

                        </Flex>
                        <Flex flexDirection={["column", "row"]} justifyContent="space-between">
                            <Box pt={[20, 12, 24]}>
                                <Heading size="lg" mb={6}>Access Management</Heading>
                                <Button onClick={onOpen}>Change Password</Button>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Change Password</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            <ChangePasswordForm onSubmit={onSubmit} />
                                        </ModalBody>

                                        <ModalFooter>

                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>

                            </Box>
                        </Flex>
                        <CreateVideo
                            isOpen={isCreateVideoOpen}
                            booking={profile}
                            onClose={onCreateVideoClose}
                            {...{ loadedScript, onLoadPipe, markComplete }}
                        />
                        <Modal
                            size="lg"
                            isOpen={isEditProfileOpen}
                            onClose={onEditProfileClose}
                            scrollBehavior="inside"
                            closeOnOverlayClick={false}
                        >
                            <ModalOverlay />
                            <ModalContent minH="80vh" rounded="lg">
                                <ModalHeader>Edit Celebrity Profile</ModalHeader>
                                <ModalCloseButton />

                                <ModalBody pb={6}>
                                    <TalentEditForm value={profile} onSubmit={onProfileEdit} />
                                </ModalBody>
                                <ModalFooter />
                            </ModalContent>
                        </Modal>
                    </>
                )
            }
        </Box >
    );
};

export default UserProfile;


const CreateVideo = ({
    isOpen,
    onClose,
    onCreateVideo = () => { },
    booking,
    onLoadPipe,
    loadedScript,
    markComplete,
    ...rest
}) => {
    const [video, setVideo] = useState(false);

    let isMobile = useMedia("(max-width: 420px)");
    var is_mobile = require('is-mobile');

    let Component = is_mobile() ? MobileVideoDrawer : DesktopVideoModal;

    return (
        <Box {...rest}>
            <Component
                {...{
                    isOpen,
                    onClose,
                    loadedScript,
                    onLoadPipe,
                    booking,
                    video,
                    markComplete,
                    setVideo
                }}
            />
        </Box>
    );
};


const MobileVideoDrawer = ({
    isOpen,
    onClose,
    loadedScript,
    onLoadPipe,
    booking,
    video,
    markComplete,
    setVideo
}) => {
    const resetVideo = () => {
        setVideo(false)
        onClose()
    }
    const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

    return (
        <Drawer
            isFullHeight
            isOpen={isOpen}
            placement="bottom"
            size="full"
            onClose={resetVideo}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader px={5}>Create video</DrawerHeader>

                {video ? (
                    <DrawerBody px={5} pb={6}>
                        <RecordingCompleted {...booking} resetVideo={resetVideo} />
                    </DrawerBody>
                ) : (
                        <>
                            <DrawerBody px={5} pb={6}>
                                <Box id="peng-video" w={"100%"}>
                                    {!!loadedScript && onLoadPipe(booking, markComplete, setVideo)}
                                </Box>
                            </DrawerBody>
                            <Box mt="30px" pl="30px" pr="30px" position="absolute" top={"100px"}>
                                <Stack py={5} textAlign="center" spacing={4} justifyContent="center" width={"calc(100vw - 65px)"} opacity="0.5">
                                    <Heading textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)" mb="0" size="md"><u>PENG INTRO</u></Heading>
                                    <>
                                        <Text textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)">{`Hi guys, this is ${booking.nickname}, ${booking.profession}.`}<br />{`I just wanted to tell you I recently joined PENG and I am excited.`}</Text>
                                        <Text textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)">{`I am here to connect more with you guys and take your shout outs. Birthday, weddings or anything to surprise a loved one.`}</Text>
                                        <Text textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)">{`I can’t wait to hear from you`}</Text>
                                    </>
                                </Stack>
                            </Box>
                        </>
                    )}

                <DrawerFooter />
            </DrawerContent>
        </Drawer>
    );
};

const DesktopVideoModal = ({
    isOpen,
    onClose,
    loadedScript,
    onLoadPipe,
    booking,
    video,
    markComplete,
    setVideo
}) => {
    const resetVideo = () => {
        setVideo(false)
        onClose()
    }
    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            onClose={resetVideo}
            scrollBehavior="inside"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent minH="80vh" rounded="lg">
                <ModalHeader>Create video</ModalHeader>
                <ModalCloseButton />
                {video ? (
                    <ModalBody pb={6}>
                        <RecordingCompleted {...booking} resetVideo={resetVideo} />
                    </ModalBody>
                ) : (
                        <ModalBody pb={6} id="video-container">
                            <Box h="300px">
                                <Box id="peng-video">
                                    {!!loadedScript && onLoadPipe(booking, markComplete, setVideo)}
                                </Box>
                            </Box>
                            <Box>
                                <Stack py={5} align="center" spacing={8}>
                                    <Heading size="md"><u>PENG Intro</u></Heading>
                                </Stack>
                                <>
                                    <Text textAlign="center">{`Hi guys, this is ${booking.nickname}, ${booking.profession}.`}<br />{`I just wanted to tell you I recently joined PENG and I am excited.`}</Text>
                                    <Text textAlign="center">{`I am here to connect more with you guys and take your shout outs. Birthday, weddings or anything to surprise a loved one.`}</Text>
                                    <Text textAlign="center">{`I can’t wait to hear from you`}</Text>
                                </>
                            </Box>
                        </ModalBody>
                    )}
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};

const RecordingCompleted = ({ order_from, personal_booking, order_to, resetVideo = () => { } }) => {
    let displayName = personal_booking ? order_to : order_from;
    return (
        <Box textAlign="center">
            <Stack py={5} align="center" spacing={8}>
                <Icon name="fireworks" size="96px" color="pink.500" />
                <Heading size="lg">Thanks for the video!</Heading>
            </Stack>

            <Text>
                Your video is being processed and will appear on your profile shortly.
        </Text>
            {!!resetVideo &&
                <Button mt="8px" onClick={resetVideo} variantColor="pink" size="sm">
                    Profile
          </Button>
            }
        </Box>
    );
};