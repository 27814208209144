import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  PseudoBox,
  Stack,
  Text,
  useTheme
} from "@chakra-ui/core";
import React, { useState, useEffect, Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { createUrl } from "../celebrity-ui/hooks";
import Autocomplete from "../form-elements/AutoComplete";
import { useMedia } from "./utils/hooks";
import { useTransition, animated, config } from "react-spring";
import styled from "@emotion/styled-base";
import { AppContext } from "../authentication/AppProvider";
import {useAsync} from 'react-use';

const homepagePhotos = [
  {
    id: 1,
    image: "/img/Ka3na.jpg",
    name: "Ka3na",
    slug: "Ka3na"
  },
  {
    id: 2,
    image: "/img/Seyi_Law.jpg",
    name: "Seyi Law",
    slug: "seyilaw"
  },
  {
    id: 3,
    image: "/img/osasighodaro.jpg",
    name: "Osas Ighodaro",
    slug: "osasighodaro"
  },
  {
    id: 4,
    image: "/img/Iyanya.jpg",
    name: "Iyanya",
    slug: "iyanya"
  },
];

const mainWrapperStyles = {
  w: "100vw",
  bg: "black",
  justifyContent: "center",
  justify: "center",
  direction: "column"
};

const headingStyles = {
  mt: 6,
  lineHeight: "shorter",
  size: "2xl",
  color: "white",
  w: ["100%", "100%", "90%", "80%", "75%"]
};

const wrapperStyles = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [0, 8],
  pb: [0, 8, 12]
};

const gradientStyles = {
  position: "absolute",
  width: "100%",
  height: "100%",
  textAlign: "center",
  "z-index": 1,
  transform: "scale(1.01)",
  pointerEvents: "none",
  top: 0,
  // background:"radial-gradient(ellipse at center, rgba(0,0,0,0) 65%,rgba(0,0,0,1) 100%)"
  background:
    "linear-gradient(to top, black 0, rgba(0,0,0,0.1) 40%, transparent 55%) center no-repeat"
};

const AnimatedBox = styled(animated.div)`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  will-change: opacity;
  overflow: hidden;
`;

const AnimatedText = styled(animated.span)`
  will-change: opacity;
  overflow: hidden;
`;

const JumboTron = props => {
  const theme = useTheme();
  const { fetcher } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useAsync(async () => {
    if (query) {
      const fetchData = () => {
        let url = `/search/${query}/`;
        setIsLoading(true);
        fetcher(url, {importance: "low"}).then(response => {
          setData(response);
          setIsLoading(false);
        });
      };
      fetchData();
    }
  }, [query, fetcher]);

  let suggestions = data || [];

  const history = useHistory();
  const isMobile = useMedia("(max-width: 420px)");
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [displayName, setDisplayName] = useState(homepagePhotos[0].name);

  const transition = useTransition(homepagePhotos[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0.1 },
    config: config.molasses
  });

  useEffect(
    () => void setInterval(() => setIndex(state => (state + 1) % 4), 6000),
    []
  );

  useEffect(() => setDisplayName(homepagePhotos[index].name), [index]);

  return (
    <Flex {...mainWrapperStyles}>
      <Box {...wrapperStyles}>
        <Stack my={[6, 8, 8, 10]} spacing={8} px={[6, 0]}>
          <Heading {...headingStyles}>
            {`${t('Get a personalised shoutout from ')}`}
            <AnimatedText
              style={{
                ...transition[transition.length - 1].props,
                color: `${theme.colors.pink["500"]}`
              }}
            >
              {displayName}
            </AnimatedText>
          </Heading>
          <Autocomplete
            hasLoadedData={!isLoading}
            onChange={value => setQuery(value)}
            onSuggestionSelected={(_, { suggestion }) => {
              history.replace(`/${suggestion.slug_url}`);
            }}
            options={suggestions}
          />
        </Stack>

        <Box mt={[12, 4]}>
          <Grid templateColumns={["none", "repeat(4, 1fr)"]} columnGap={2}>
            {isMobile ? (
              <MobileImage photos={transition} />
            ) : (
              <DesktopImage photos={homepagePhotos} />
            )}
          </Grid>
        </Box>
      </Box>
    </Flex>
  );
};

export default JumboTron;

const DesktopImage = ({ photos }) => {
  let history = useHistory();
  return (
  <>
    {photos.map((celeb, index) => (
      <Box h="400px" position="relative" key={index}>
        <Image
          h="100%"
          w="100%"
          cursor="pointer"
          objectFit="cover"
          rounded="lg"
          onClick={() => {history.push(`/${celeb.slug}`)}}
          src={celeb.image}
        />

        <ImageGradient id={celeb} />
      </Box>
    ))}
  </>
)};

const MobileImage = ({ photos }) => {
  let history = useHistory();
  
  return (
    <Box overflow="hidden" h="400px" position="relative">
      {photos.map(({ item, props, key }) => (
        <Fragment key={key}>
          <AnimatedBox
            key={key}
            onClick={() => {history.push(`/${item.slug}`)}}
            style={{
              ...props,
              backgroundImage: `url(${item.image})`
            }}
          />

          <ImageGradient id={item} />
        </Fragment>
      ))}
    </Box>
  )
};

const ImageGradient = ({ id }) => (
  <PseudoBox {...gradientStyles}>
    <Stack spacing={1} position="absolute" bottom="5%" left="5%">
      <Text
        d={["none", "block"]}
        color="white"
        fontWeight="bold"
        opacity={0.8}
        lineHeight="none"
      >
        {id.name}
      </Text>
    </Stack>
  </PseudoBox>
);
