import { Heading, Box, Text, Link, Flex, Image } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";
import { Helmet } from "react-helmet";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12],
};

const press = [
  {
    icon: '/img/disrupt_africa.png',
    alt: 'Disrupt Africa Naija',
    src: 'https://disrupt-africa.com/2020/08/nigerias-peng-helps-african-celebrities-connect-with-their-fans-through-personalised-videos/',
    description: 'Nigeria’s PENG helps African celebrities connect with their fans through personalised videos'
  },
  {
    icon: '/img/bellanaija.png',
    alt: 'Bella Naija',
    src: 'https://www.bellanaija.com/2020/04/peng-celebrity-video/',
    description: 'You can now Get a Personalized Video Message from Your Fave Celebrity for as low as N5,000 with PENG'
  },
  {
    icon: '/img/techcabal.png',
    alt: 'Techcabal',
    src: 'https://techcabal.com/2020/04/08/peng-a-new-twist-to-celebrity-fan-engagement/',
    description: 'PENG, a new twist to celebrity – fan engagement'
  },
  {
    icon: '/img/forbes.png',
    alt: 'Forbes',
    src: 'https://globalstartupecosystem.com/forbes-announces-selection-of-companies-for-its-1st-digital-startup-accelerator-in-nigeria/',
    description: 'Forbes Announces Selection of Companies for Its 1st Digital Startup Accelerator in Nigeria'
  },
  {
    icon: '/img/techloy.jpg',
    alt: 'Techloy',
    src: 'https://medium.com/techloy/peng-an-african-celebrity-shoutout-platform-that-lets-you-connect-with-fans-and-talents-5fda5767a1ee',
    description: 'PENG, an African celebrity shoutout platform that lets you connect with fans and talents'
  },
];

const Press = props => {
  return (
    <PageWrapper>
      <Helmet>
        <title>{"Press Mentions - PENG"}</title>
        <meta name="description" content={`Press Mentions`} />
        <meta name="keywords" content="celebrity,gifts,peng,press" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Peng Press Mentions`} />
        <meta property="og:image" content={`https://sendpeng.com/logo.png`} />
      </Helmet>
      <Box bg="gray.50" minH={'calc(100vh - 400px)'}>
        <Box {...wrapperProps}>
          <Heading as="h1">Press Mentions</Heading>
          <br />
          <Flex my="20px" flexDirection={["column", "column", "column", "row"]} flexWrap="wrap">
            {press.map(obj =>
              (<Box flex="0 50%" pb="2rem" px={["0px", "0px", "0px", "20px"]}>
                <Link href={obj.src} isExternal>
                  <Text fontSize="16px" color="#F411A8" pb="10px" >
                    <Image alt={obj.alt} h="80px" src={obj.icon} />
                  </Text>
                </Link>
                <Link color="#F411A8" pr="20px" href={obj.src} isExternal>
                  {obj.description}
                </Link>
              </Box>)
            )}
          </Flex>

          <Box bg="grey.400" w="100%" py={4} mt={"40px"} color="black">
            <Heading as="h2" size="md">Inquiries</Heading>
            <Text pt="5px">Please contact our founder directly at <Link color="#F411A8" href="mailto:temi@sendpeng.com"><b>temi@sendpeng.com</b></Link></Text>
          </Box>
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Press;
