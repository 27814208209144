import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { object, string } from "yup";
import PasswordInput from "../form-elements/PasswordInput";
import { AppContext, createUrl } from "./AppProvider";
import * as Yup from 'yup';


let initialValue = {
  new_password1: "",
  new_password2: "",
};

let validationSchema = object().shape({
    new_password1: string().required("Enter a password"),
    new_password2: string().oneOf([Yup.ref('new_password1'), null], 'Passwords must match').required("Confirm Password"),
});

let placeholders = {
  new_password1: {
    label: "New Password",
    placeholder: "Type your password"
  },
  new_password2: {
    label: "Confirm Password",
    placeholder: "Type your password"
  }
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: touched[name] && errors[name],
  errorMessage: errors[name]
});

const CompleteResetPasswordForm = ({ onSubmit = () => { }, spacing = "24px", uib64="", token = "" }) => {
  const { setAuthToken, isLoggedIn, authService } = useContext(AppContext);
  let [loading, setLoading] = React.useState(false);
  let [message, setMessage] = React.useState('');
  let [status, setStatus] = React.useState('success');
  const toast = useToast();

  function onFormSubmit(data) {
    setLoading(true);
    let payload = {
      password: data['new_password1'],
      uidb64: uib64,
      token: token
    }
    authService.confirmResetPassword(payload).then((res) => {
      let body = res.body;
      if(res.status == 200) {
        setStatus('success')
        setMessage(body.detail)
        toast({
          title: "Your password has been changed.",
          description: body.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onSubmit(body.detail)
      } else {
        setStatus('error')
        if (body.new_password2) {
          setMessage('Erroneous input: ' + body.new_password2.join(' '))
        } else {
          setMessage('Erroneous input')
        }
      }
      setLoading(false);
    }).catch((error) => {
      setStatus('error')
      setMessage('Unknown error')
      console.log(error)
    }) 
  }


    
    // .then((response) => {
    //   setErrorMessage(response)
    //   setLoading(false)
    // })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValue}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
            {message !== '' && (<Alert status={status}>
              <AlertIcon />
                {message}
            </Alert>)
            }
         

              <Field name="new_password1">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <PasswordInput
                      {...field}
                      fontSize="md"
                      autoComplete="false"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="new_password2">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <PasswordInput
                      {...field}
                      fontSize="md"
                      autoComplete="false"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            <Button
              mt={spacing}
              type="submit"
              isFullWidth
              size="lg"
              variantColor="pink"
              isLoading={loading}
            // isDisabled={!isComplete}
            >
              Reset Password
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default CompleteResetPasswordForm;

CompleteResetPasswordForm.defaultProps = {
  onSubmit: data => {
    // alert(JSON.stringify(data))
  }
};
