import React from "react";
import { Flex, Spinner } from "@chakra-ui/core";
import CelebritySearch from "../celebrity-ui/CelebritySearch";
import JumboTron from "./JumboTron";
import { Helmet } from "react-helmet";
import PageWrapper from "./PageWrapper";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper navigationProps={{ isHomePage: false }}>
      <Helmet>
        <title>{"PENG"}</title>
        <meta name="description" content={t("personalized_engagement")} />
        <meta name="keywords" content="celebrity,gifts,peng" />
      </Helmet>
      <Flex
      justify="center"
      align="center"
      justifyContent="center"
      direction="column"
      h={["60vh", "75vh"]}
    >
      Not Found
    </Flex>
    </PageWrapper>
  );
};

export default NotFound;