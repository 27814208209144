import React, { createContext, useMemo, useState, useEffect } from "react";
import LoadingPage from "../external-pages/LoadingPage";
import NotFound from "../external-pages/NotFound";

let backendHost = '';
// const DEV = process.env.REACT_APP_HOST_ENVIRONMENT === "development";
// const STAGING = process.env.HOST_ENVIRONMENT === "staging";

const DEV = window.location.hostname === "localhost";
const STAGING = window.location.hostname === "staging.sendpeng.com";
let host = "https://api.sendpeng.com";
if (STAGING) {
  host = "https://apistaging.sendpeng.com";
}
if (DEV) {
  host = `https://a19260ce.ngrok.io`;
}
// host = `http://localhost:8000`;
let baseUrl = `${host}/api/v1`;

export const is_dev = DEV;

export const root_url = host;
let base_url = `${root_url}/api/v1`;

export const createUrl = (route, query) => {
  const baseUrl = base_url + route;
  return query ? `${baseUrl}/${query}` : baseUrl;
};

const getUserURL = createUrl(`/auth/user`);
const logOutURL = `/rest-auth/logout/`;

const AppContext = createContext({
  useAuthDisclosure: () => {}
});

const AppProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("token") || ""
  );
  const [user, setUser] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/');
  const [isCeleb, setIsCeleb] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [headerElem, setHeaderElem] = useState("");

  // Setup config with token - helper function

  const tokenConfig = authToken => {
    // Get token from state
    const token = authToken || localStorage.getItem("token");
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    // If token, add to headers config
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
  };

  ////////////////////

  const authService = {
    isAuthenticated: false,
    clearAuth() {
      let rm = this;
      setUser(null);
      setIsLoggedIn(false);
      setCheckingAuth(false);
      setAuthToken("");
      localStorage.removeItem("token");
      rm.isAuthenticated = false;
    },
    getUser(config, cb = () => {}) {
      const token = tokenConfig(authToken);
      config = { ...config, ...token };
      let rm = this;
      return fetch(getUserURL, config)
      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then(response => {
        if (response.status == 401) {
          rm.clearAuth();
          setIsLoading(false);
          return;
        }

        return response.body;
        
        
      }).then((response) => {
        if (!!response && !!response.username) {
          setUser(response);
          setIsCeleb(response.is_celeb);
          setIsLoggedIn(true);
          setIsLoading(false);
          rm.isAuthenticated = true;
          cb();
        }
      }).catch(() => {
        rm.clearAuth();
        setIsLoading(false);
      })
    },
    authenticate(data, onSubmit = () => {}) {
      let rm = this;

      return fetch(createUrl(`/account/login`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        // .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(res => {
          let response = res.json().then(data => data)
          if (res.status === 200) {
            return response.then(data => data)
            .then(data => {
              const token = data;
              if (!!token.key) {
                localStorage.setItem("token", token.key);
                setAuthToken(token.key);
              }
              if (!!token.user) {
                setUser(token.user);
                setIsCeleb(token.user.is_celeb);
                setIsLoggedIn(true);
                setIsLoading(false);
                rm.isAuthenticated = true;
              }
              return data
            })
            .then(res => {
              if (!res.user) {
                rm.getUser();
              }
             })
            .then(res => {
              onSubmit();
            })
          }
          else {
            rm.clearAuth();
            return response.then(login_data => {
              if (login_data.hasOwnProperty('non_field_errors')) {
                let login_errors = login_data.non_field_errors.join(', ');
                return login_errors;
              }
              return 'Unknown error'
            }) 
          }
        });
    },
    fbAuth(data, onSubmit = () => {}) {
      let rm = this;

      return fetch(createUrl(`/rest-auth/facebook/`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        // .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(res => {
          let response = res.json().then(data => data)
          if (res.status === 200) {
            return response.then(data => data)
            .then(data => {
              const token = data;
              if (!!token.key) {
                localStorage.setItem("token", token.key);
                setAuthToken(token.key);
              }
              if (!!token.user) {
                setUser(token.user);
                setIsCeleb(token.user.is_celeb);
                setIsLoggedIn(true);
                setIsLoading(false);
                rm.isAuthenticated = true;
              }
            })
            .then(res => {
              rm.getUser();
            })
            .then(res => {
              onSubmit();
            })
          }
          else {
            rm.clearAuth();
            return response.then(login_data => {
              if (login_data.hasOwnProperty('non_field_errors')) {
                let login_errors = login_data.non_field_errors.join(', ');
                return login_errors;
              }
              return 'Unknown error'
            }) 
          }
        });
    },
    createUser(data) {
      return fetch(createUrl(`/rest-auth/registration/`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
        }).then(r =>  r.json().then(data => ({status: r.status, body: data})))

    },
    changePassword(data) {
      const token = tokenConfig(authToken);
      let config = Object.assign({
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }, token );
    
      return fetch(`${base_url}/rest-auth/password/change/`, config)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})));
    },
    resetPassword(data) {
      return fetch(createUrl(`/auth/request-reset-email/`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
        }).then(r =>  r.json().then(data => ({status: r.status, body: data})))
    },
    facebook(data) {
      return fetch(createUrl(`/rest-auth/facebook/`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
        }).then(r =>  r.json().then(data => ({status: r.status, body: data})))

    },
    confirmResetPassword(data) {
      return fetch(createUrl(`/auth/password-reset-complete/`), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
        }).then(r =>  r.json().then(data => ({status: r.status, body: data})))

    },
    verifyEmail(key) {
      return fetch(createUrl(`/rest-auth/registration/verify-email/`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ key: key })
        })
        .then(response => response.json())
    },
    logout(cb = () => {}) {
     return fetcher(logOutURL, {method: 'POST'}).then(response => {
        if (!!response.detail) {
          setIsLoggedIn(false);
          setUser(null);
          setAuthToken("");
          localStorage.removeItem("token"); 
        }
      }).then(() => {
        cb();
      });
    }
  }

  const fetcher = (url, config = {}, ignoreError = false) => {
    
    const token = authToken || localStorage.getItem("token");
    // Headers
    if (!config.headers) {
      config['headers'] = {"Content-Type": "application/json"}
    }
    // If token, add to headers config
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return fetch(`${base_url}${url}`, config).then(response => {
      if (response.status < 400) {
        return response.json();
      }

      return !ignoreError ? response.json() : [];
    });
  };

  useEffect(() => {
    if(checkingAuth && !!localStorage.getItem("token")) {
      authService.getUser({}).then(() => {
        setIsLoading(false)
        setCheckingAuth(false)
      }).catch(error => {
        authService.clearAuth()
      });;
    } else {
      setIsLoading(false)
      setCheckingAuth(false)
    }
    
  })

  const getDiscountVariable = () => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == 'd') {
            return decodeURIComponent(pair[1]);
        }
    }
    let globalPromoCode = 'PENG10';//'TEMI10';
    return globalPromoCode;
  }

  const checkCoupon = (discount_variable = '') => {
    // Check localStorage if variable exists
    let discount = localStorage.getItem('discount')
    if (!!discount) {
      let saved_discount = JSON.parse(discount);
      let is_active_coupon = new Date(saved_discount.expiry_date) >= new Date()
      if (is_active_coupon) {
        if (discount_variable === '') {
          setCoupon(saved_discount)
          return;
        } else {
          if (saved_discount.code === discount_variable) {
            setCoupon(saved_discount)
            return;
          } 
        }
      }
      // Remove expired coupon
      if (!is_active_coupon) {
        localStorage.removeItem('discount');
      }
    }
  }

  useEffect(() => {
    let discount_variable = getDiscountVariable();
    console.log(discount_variable)
    if (!!discount_variable && !coupon) {
      checkCoupon(discount_variable);
      // Retrieve variable
      let slug_url = window.location.pathname.split('/')[1]
      // Get discount
      let url = `/discount/${discount_variable}/` + (!!slug_url ? `${slug_url}/` : '')
      fetch(`${base_url}${url}`).then(response => {
        if (response.status < 400) {
          return response.json();
        }
        return response.json()
      }).then((data) => {
        if (!!data.code) {
          // Replace variable in localStorage or store globally if variable has not expired
          setCoupon(data);
          localStorage.setItem('discount', JSON.stringify(data))
        }
      });
    } else if (!coupon) {
      checkCoupon();
    }
  }, [coupon])

  // useEffect(() => {
  //   if (!user && !!authToken) {
  //     setIsLoading(true);
  //     fetcher(getUserURL)
  //       .then(response => {
  //         setUser(response);
  //         setIsLoggedIn(true);
  //         setIsLoading(false);
  //       })
  //       .catch(error => {
  //         setUser(null);
  //         setIsLoggedIn(false);
  //         setAuthToken("");
  //         localStorage.removeItem("token");
  //         setIsLoading(false);
  //       });
  //   }
  // }, [authToken, user]);

  // const onLogout = () => {
  //   if (isLoggedIn) {
  //     return fetcher(logOutURL, {method: 'POST'}).then(response => {
  //       if (!!response.detail) {
  //         setUser(null);
  //         setIsLoggedIn(false);
  //         setAuthToken("");
  //         localStorage.removeItem("token"); 
  //       localStorage.removeItem("token");
  //         localStorage.removeItem("token"); 
  //       }
  //     });
  //   }
  // };

  const childContext = useMemo(() => {
    const useAuthDisclosure = () => {

      function onOpen(path, headerElem, redirect_url='/') {
        setIsOpen(true);
        setRedirectUrl(redirect_url);
        // history.push(`/${path}`);
        if (headerElem) setHeaderElem(headerElem);
        if (path === "login") setIsLogin(true);
        if (path === "signup") setIsLogin(false);
      }

      function onClose() {
        setIsOpen(false);
        // history.goBack();
      }

      return {
        isOpen,
        onOpen,
        onClose,
        isLogin,
        redirectUrl,
        setIsLogin,
        headerElem
      };
    };

    return {
      useAuthDisclosure,
      setAuthToken,
      isLoggedIn,
      isLoading,
      user,
      setUser,
      isCeleb,
      fetcher,
      checkingAuth,
      authService,
      coupon,
      redirectUrl
    };
  }, [isLogin, fetcher, isLoading, user, isCeleb, isLoggedIn, isOpen, headerElem, checkingAuth, authService, coupon, redirectUrl]);
  if(isLoading) {
    return <LoadingPage />
  }
  return (
    <AppContext.Provider value={childContext}>{children}</AppContext.Provider>
  );
};

export { AppProvider, AppContext };
