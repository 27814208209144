import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import Rating from "../data-display/Rating";
import VideoPlayer from "../data-display/VideoPlayer";
import Select from "../form-elements/Select";
import { useMedia } from "../external-pages/utils/hooks";
import { AppContext, is_dev } from "../authentication/AppProvider";
import LoadingPage from "../external-pages/LoadingPage";
import { formatDBdate } from "../external-pages/utils/functions";
import { useBookingAction } from "./booking_actions";

import "./pipeStyles.css";


const mapStatusToColor = {
  "New request": "green",
  "Awaiting video": "orange",
  "Pending approval": "cyan",
  Completed: "blue",
  Declined: "red",
  Expired: "red",
  Cancelled: "red"
};

const mediaProps = {
  height: "100%",
  weight: "110%",
  borderRadius: "8px 8px 0px 0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const contentWrapper = {
  height: "400px",
  direction: "column",
  borderWidth: "2xs",
  borderColor: "gray.100",
  boxShadow: "sm",
  borderRadius: "8px",
  overflow: "hidden",
  bg: "white"
};

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const getBookingStatus = ({
  accepted,
  declined,
  approved,
  expired,
  completed,
  cancelled
}) => {
  let status,
    isDisabled = false;

  if (!expired && !cancelled) {
    if (!accepted && !declined) status = "New request";
    if (accepted && !completed) status = "Awaiting video";
    if (completed && !approved) {
      status = "Pending approval";
      isDisabled = true;
    }
    if (completed && approved) {
      status = "Completed";
      isDisabled = true;
    }
    if (declined) {
      status = "Declined";
      isDisabled = true;
    }
  }
  if (expired) {
    status = "Expired";
    isDisabled = true;
  }
  if (cancelled) {
    status = "Cancelled";
    isDisabled = true;
  }

  const color = status && mapStatusToColor[status];

  return { color, status, isDisabled };
};

const options = ["Active",/*"Pending",*/ "Completed", "Cancelled"];
const bookingOptions = options.map(option => ({
  label: option,
  value: option.toLowerCase()
}));

const gridProps = {
  display: "grid",
  gridTemplateColumns: ["none", "repeat(auto-fill, minmax(232px, 1fr))"],
  // gridTemplateColumns: ["none", "repeat(2, 1fr)"],
  gridRowGap: 6,
  gridColumnGap: 6
};

function getBookingData(initialData = [], filter) {
  let finalData, showListView;
  if (!Array.isArray(initialData)) {
    initialData = [];
  }
  if (filter === "active") {
    let awaitingData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Awaiting video"
    );
    let newRequestData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "New request"
    );
    finalData = [...awaitingData, ...newRequestData];
    showListView = true;
  }
  if (filter === "pending") {
    finalData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Pending approval"
    );
    showListView = false;
  }
  if (filter === "completed") {
    finalData = initialData.filter(
      dataObj => getBookingStatus(dataObj).status === "Completed"
    );
    showListView = false;
  }
  if (filter === "cancelled") {
    const cancelledStatus = ["Declined", "Expired", "Cancelled"];
    finalData = initialData.filter(dataObj =>
      cancelledStatus.includes(getBookingStatus(dataObj).status)
    );
    showListView = true;
  }
  return { finalData, showListView };
}

function usePipe() {
  let [loadedScript, setLoadedScript] = useState(true);

  useEffect(() => {
    initializePipe();
  });

  function initializePipe() {
    if (!loadedScript) {
      let addpipeCSS = document.createElement("link");
      addpipeCSS.rel = "stylesheet";
      addpipeCSS.href = "https://cdn.addpipe.com/2.0/pipe.css";
      let addpipeJS = document.createElement("script");
      addpipeJS.src = "https://cdn.addpipe.com/2.0/pipe.js";
      document.body.appendChild(addpipeCSS);
      document.body.appendChild(addpipeJS);
      setLoadedScript(true);
    }
  }

  function onLoadPipe(booking, callback, setVideo) {
    if (loadedScript && !!booking.uuid) {
      const video_key = 'd2f1c9e06f6ce047bc3ddffe535eeb43';//process.env.VIDEO_KEY;
      const video_env = is_dev ? 'PtRWYL' : 'zgSG0j' //  process.env.VIDEO_ENV;
      var pipeParams = {
        size: {
          width: "100%",
          height: "100%"
        },
        qualityurl: "avq/720p.xml",
        accountHash: video_key,
        eid: video_env,
        sis:  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 0 : 1,
        asv: 0,
        payload: `"{'type': 'booking', 'id': '${booking.uuid}'}"`,
        st: 1,
        showMenu: 1,
        dup: 1,
        mrt: 120
      };

      if (!!window.PipeSDK) {
        const elementId = "peng-video";
        var elementExists = !!document.getElementById(elementId);
        // if (elementExists) {

          window.PipeSDK.insert(elementId, pipeParams, function(myRecorder) {
            myRecorder.onReadyToRecord = function(id, type) {};
  
            var successCallback = function() {
              myRecorder.remove();
              callback(booking);
              setVideo(true)
            }

            myRecorder.onSaveOk = function(
              recorderId,
              streamName,
              streamDuration,
              cameraName,
              micName,
              audioCodec,
              videoCodec,
              fileType,
              videoId,
              audioOnly,
              location
            ) {
              var args = Array.prototype.slice.call(arguments);
              console.log("onSaveOk(" + args.join(", ") + ")");
              successCallback()
              //TODO: Tell them Video is Complete
            }

            myRecorder.onDesktopVideoUploadSuccess = function(recorderId, filename,filetype,videoId,audioOnly,location){
              var args = Array.prototype.slice.call(arguments);
              console.log("onDesktopVideoUploadSuccess("+args.join(', ')+")");
              successCallback()
            }
  
            myRecorder.onVideoUploadSuccess = function(recorderId, filename,filetype,videoId,audioOnly,location){
              var args = Array.prototype.slice.call(arguments);
              console.log("onVideoUploadSuccess("+args.join(', ')+")");
              successCallback()
            }


          });

        // } 
      }
    }
  }
  return [loadedScript, onLoadPipe];
}

const CelebrityBookings = ({ data: dataProp = [] }) => {
  const { user, isLoggedIn, isCeleb, checkingAuth } = useContext(AppContext);
  const [loadedScript, onLoadPipe] = usePipe(false);
  const { completeBooking, getBookings, acceptBooking } = useBookingAction();
  const [data, setInputData] = useState(dataProp || []);
  const [outputData, setOutputData] = useState(data);
  const [filter, setFilter] = useState("active");
  const [isListView, setIsListView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [declineReason, setDeclineReason] = useState("");
  const [booking, setBooking] = useState({});
  const [targetBooking, setTargetBooking] = useState({});
  let history = useHistory();

  let slug_url = !!user && !!user.public ? user.public.slug_url : null;

  useEffect(() => {
    if (!!slug_url && isLoggedIn && isCeleb) {
      getBookings(slug_url).then(res => {setInputData(res); setIsLoading(false)})
    }
  }, [slug_url, isLoggedIn, isCeleb]);

  useEffect(() => {
    if (!isLoggedIn && !checkingAuth) {
      setInputData([]);
      history.push('/')
    }
  }, [isLoggedIn, checkingAuth]);

  const {
    isOpen: isDeclineOpen,
    onClose: onDeclineClose,
    onOpen: onDeclineOpen
  } = useDisclosure();

  const {
    isOpen: isCreateVideoOpen,
    onClose: onCreateVideoClose,
    onOpen: onCreateVideoOpen
  } = useDisclosure();

  const Component = isListView ? BookingLists : BookingGrid;
  const wrapperProps =
    outputData.length > 0 ? (isListView ? {} : gridProps) : {};

  useEffect(() => {
    const { finalData, showListView } = getBookingData(data, filter);
    setOutputData(finalData);
    setIsListView(showListView);
  }, [filter, data]);

  useEffect(() => {
    if (declineReason) {
      let newBooking = { ...booking.targetBooking, declined: true };
      let newData = [...booking.remainingBookings, newBooking];
      setInputData(newData);
      onDeclineClose();
    }
  }, [declineReason]);

  const handleActionClick = (action, key) => {
    let targetBooking = data.find(({ uuid }) => uuid === key);
    let remainingBookings = data.filter(({ uuid }) => uuid !== key);
    let bookingData = { targetBooking, remainingBookings };
    setBooking(bookingData);
    setTargetBooking(targetBooking);


    if (action === "accept booking") {
      acceptBooking(slug_url, key).then((res) => {
        let newBooking = { ...targetBooking, accepted: true };
        let newData = [...remainingBookings, newBooking];
        setInputData(newData);
        onCreateVideoOpen();
      });
    }
    if (action === "decline booking") onDeclineOpen();
    if (action === "create video") onCreateVideoOpen();
  };

  const markComplete = (booking) => {
    let slug_url = !!user && !!user.public ? user.public.slug_url : null;
    if (!!slug_url) {
      completeBooking(slug_url, booking.uuid).then((res) => {
        let targetBooking = data.find(({ uuid }) => uuid === booking.uuid);
        let remainingBookings = data.filter(({ uuid }) => uuid !== booking.uuid);
        let newBooking = { ...targetBooking, completed: true };
        let newData = [...remainingBookings, newBooking];
        setInputData(newData);
      });;
    }
  };

  // if (!isLoggedIn) {
  //   return <Redirect to="/" />;
  // }

  return (
    <Box>
      <Flex justify="space-between" mb={6}>
        <Heading>Bookings</Heading>

        <Box w="144px">
          <Select
            size="md"
            onChange={value => setFilter(value.value)}
            options={bookingOptions}
            defaultValue="active"
          />
        </Box>
      </Flex>

      {isLoading ? <LoadingPage /> : outputData.length > 0 ? (
        <PseudoBox {...wrapperProps}>
          {outputData.map(obj => (
            <Component
              key={obj.uuid}
              {...obj}
              {...getBookingStatus(obj)}
              onActionClick={action => handleActionClick(action, obj.uuid)}
            />
          ))}
        </PseudoBox>
      ) : (
        <EmptyBookings {...{ filter }} />
      )}
      <DeclineConfirmation
        isOpen={isDeclineOpen}
        onClose={onDeclineClose}
        booking={targetBooking}
        onDecline={reason => setDeclineReason(reason)}
      />

      <CreateVideo
        isOpen={isCreateVideoOpen}
        booking={targetBooking}
        onClose={onCreateVideoClose}
        {...{ loadedScript, onLoadPipe, markComplete }}
      />
    </Box>
  );
};

const EmptyBookings = ({ filter }) => {
  return (
    <Flex justify="column" minH="50vh" align="center" justifyContent="center">
      <Stack textAlign="center" align="center" spacing={6}>
        <Icon name="cube-box" size="128px" />
        <Text fontSize="xl">There are no {filter} bookings yet</Text>
      </Stack>
    </Flex>
  );
};

const DeclineConfirmation = ({ isOpen, onClose, booking, onDecline = () => {} }) => {
  const [reason, setReason] = useState("");
  const { rejectBooking } = useBookingAction();
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState("");
  let slug_url = !!user && !!user.public ? user.public.slug_url : null;

  const handleSubmit = () => {
    if (reason !== '') {
        setIsLoading(true)
        rejectBooking(slug_url, booking.uuid, reason).then((res) => {
          setIsLoading(false);
          onDecline(res);
        });
    }
  }

  const declineReasons = [
    "Not interested",
    "Not available",
    "Personal conflict"
  ].map(item => ({ label: item, value: item }));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg" top="4.00rem">
        <ModalHeader>Confirm decline</ModalHeader>

        <ModalBody pb={6}>
          <Text mb={6}>
            If you wish to decline this booking, please select a reason why
            you're unable to take this booking
          </Text>
          <Stack isInline align="center" spacing={5}>
            <Text fontWeight="bold">Reason:</Text>
            <Select
              w="100%"
              options={declineReasons}
              onChange={value => setReason(value.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variantColor="gray" variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            variantColor="red"
            isDisabled={reason ? false : true}
            onClick={() => handleSubmit()}
          >
            Decline
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CreateVideo = ({
  isOpen,
  onClose,
  onCreateVideo = () => {},
  booking,
  onLoadPipe,
  loadedScript,
  markComplete,
  ...rest
}) => {
  const [video, setVideo] = useState(false);

  let isMobile = useMedia("(max-width: 420px)");
  var is_mobile = require('is-mobile');

  let Component = is_mobile() ? MobileVideoDrawer : DesktopVideoModal;

  return (
    <Box {...rest}>
      <Component
        {...{
          isOpen,
          onClose,
          loadedScript,
          onLoadPipe,
          booking,
          video,
          markComplete,
          setVideo
        }}
      />
    </Box>
  );
};

export default CelebrityBookings;

const MobileVideoDrawer = ({
  isOpen,
  onClose,
  loadedScript,
  onLoadPipe,
  booking,
  video,
  markComplete,
  setVideo
}) => {
  const resetVideo = () => {
    setVideo(false)
    onClose()
  }
  const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

  return (
    <Drawer
      isFullHeight
      isOpen={isOpen}
      placement="bottom"
      size="full"
      onClose={resetVideo}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader px={5}>Create video</DrawerHeader>

        {video ? (
          <DrawerBody px={5} pb={6}>
            <RecordingCompleted {...booking} resetVideo={resetVideo} />
          </DrawerBody>
        ) : (
          <>
          <DrawerBody px={5} pb={6}>
            <Box id="peng-video" w={"100%"}>
             {!!loadedScript && onLoadPipe(booking, markComplete, setVideo)}
            </Box>
          </DrawerBody>
          <Box mt="30px" pl="30px" pr="30px" position="absolute" top={"100px"}>
              <Stack py={5} textAlign="center" spacing={4} justifyContent="center" width={"calc(100vw - 65px)"} opacity="0.5">
                <Heading textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)" mb="0" size="md"><u>Message for {booking.order_to} {!!booking.order_from ? `from ${booking.order_from}` : ''}</u></Heading>
                <Text textAlign="center" color="black" textShadow=" 1px 1px 0 rgba(255,2555,255, 0.5)">
                  {booking.message}
                </Text>
              </Stack>
            </Box>
          </>
        )}

        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

const DesktopVideoModal = ({
  isOpen,
  onClose,
  loadedScript,
  onLoadPipe,
  booking,
  video,
  markComplete,
  setVideo
}) => {
  const resetVideo = () => {
    setVideo(false)
    onClose()
  }
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={resetVideo}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minH="80vh" rounded="lg">
        <ModalHeader>Create video</ModalHeader>
        <ModalCloseButton />
        {video ? (
          <ModalBody pb={6}>
            <RecordingCompleted {...booking} resetVideo={resetVideo} />
          </ModalBody>
        ) : (
          <ModalBody pb={6} id="video-container">
            <Box h="300px">
              <Box id="peng-video"> 
                {!!loadedScript && onLoadPipe(booking, markComplete, setVideo)}
              </Box>
            </Box>
            <Box>
              <Stack py={5} align="center" spacing={8}>
        <Heading size="md"><u>Message for {booking.order_to} {!!booking.order_from ? `from ${booking.order_from}` : ''}</u></Heading>
              </Stack>
              <Text>
                {booking.message}
              </Text>
            </Box>
          </ModalBody>
        )}
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

const RecordingCompleted = ({ order_from, personal_booking, order_to, resetVideo = () => {}}) => {
  let displayName = personal_booking ? order_to : order_from;
  return (
    <Box textAlign="center">
      <Stack py={5} align="center" spacing={8}>
        <Icon name="fireworks" size="96px" color="pink.500" />
        <Heading size="lg">Thanks for the video!</Heading>
      </Stack>

      <Text>
        Your video is being processed and will be sent to {displayName} shortly.
      </Text>
      {!!resetVideo &&
        <Button mt="8px" onClick={resetVideo} variantColor="pink" size="sm">
          Bookings
        </Button>
      }
    </Box>
  );
};

export const BookingInfo = props => {
  return (
    <>
      <Flex justify="space-between" w={["100%", "60%"]} mb={3}>
        <Box>
          <Text color="gray.500" fontSize="xs" lineHeight="none">
            For
          </Text>
          <Text
            lineHeight="short"
            fontWeight="medium"
            fontSize={props.fontSize || "md"}
          >
            {props.order_to}
          </Text>
        </Box>
        {!!props.order_from && (
          <>
            <Icon name="chevron-right" size="40px" color="pink.500" />
            <Box>
              <Text color="gray.500" fontSize="xs" lineHeight="none">
                From
              </Text>
              <Text
                lineHeight="short"
                fontWeight="medium"
                fontSize={props.fontSize || "md"}
              >
                {props.order_from}
              </Text>
            </Box>
          </>
        )}
      </Flex>
      {props.message && (
        <Text
          px={4}
          py={4}
          mb={3}
          bg="gray.50"
          rounded="md"
          fontSize={props.fontSize || "md"}
        >
          {props.message}
        </Text>
      )}
    </>
  );
};

const BookingLists = ({
  onActionClick = () => {},
  color,
  status,
  isDisabled,
  ...props
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const handleClick = action => {
    if (action === "accept booking") {
      setIsAccepting(true);
    }
    onActionClick && onActionClick(action);
  };
  return (
    <PseudoBox
      p={6}
      mb={6}
      rounded="lg"
      borderWidth="xs"
      opacity={isDisabled ? 0.5 : 1}
      bg="white"
      _hover={isDisabled ? {} : { borderColor: "gray.300" }}
    >
      <Flex direction={["column", "row"]} justify="space-between">
        <Box w={["100%", "60%"]}>
          <Flex direction={["column", "row"]} >
            <Badge variant="solid" mb={3} variantColor={color}>
              {status}
            </Badge>
            {!!props.expires_at && (<Box pl={["0", "1rem"]} d="inline-block" position="relative" top="0px" fontSize="0.9rem">Expires {formatDBdate(props.expires_at)}</Box>)}
          </Flex>
          <BookingInfo {...props} />
        </Box>
        {status === "Awaiting video" ? (
          <Stack isInline align="center" mt={[6, 0]} mx="auto">
            <PseudoBox
              onClick={() => handleClick("create video")}
              ml={[0, 20]}
              textAlign="center"
              color="red.500"
              _hover={{ cursor: "pointer", color: "red.600" }}
            >
              <IconButton
                variantColor="red"
                aria-label="Create video"
                size="lg"
                rounded="full"
                icon="video-camera"
              />
              <Text fontWeight="bold" mt={2}>
                Make video
              </Text>
            </PseudoBox>
          </Stack>
        ) : (
          status === "New request" && (
            <Stack
              isInline
              align="center"
              spacing={12}
              mt={[6, 0]}
              mx={["auto", 5]}
            >
              <PseudoBox
                onClick={() => handleClick("accept booking")}
                textAlign="center"
                color={isDisabled ? "pink.200" : "pink.500"}
                _hover={
                  isDisabled ? {} : { cursor: "pointer", color: "pink.600" }
                }
              >
                <IconButton
                  variantColor="pink"
                  isDisabled={isDisabled}
                  aria-label="Accept"
                  isLoading={isAccepting}
                  size="lg"
                  rounded="full"
                  icon="check"
                />
                <Text fontWeight="bold" mt={2}>
                  Accept
                </Text>
              </PseudoBox>

              <PseudoBox
                onClick={() => handleClick("decline booking")}
                textAlign="center"
                color={isDisabled ? "gray.200" : "gray.500"}
                _hover={
                  isDisabled ? {} : { cursor: "pointer", color: "gray.600" }
                }
              >
                <IconButton
                  isDisabled={isDisabled}
                  variantColor="gray"
                  aria-label="Decline"
                  rounded="full"
                  size="lg"
                  icon="close"
                />
                <Text fontWeight="bold" mt={2}>
                  Decline
                </Text>
              </PseudoBox>
            </Stack>
          )
        )}
        {(status === "Completed" || status === "Pending approval") && (
          <Box ml={[6, 6]} w="40%">
            <VideoPlayer
              src={props.video && props.video.recording_url}
              poster={props.video && props.video.snapshot_url}
            />
          </Box>
        )}
      </Flex>
    </PseudoBox>
  );
};

const BookingGrid = ({
  order_from,
  personal_booking,
  order_to,
  message,
  order_review,
  reviewed_at,
  order_rating,
  fontSize = "sm",
  status,
  video
}) => {
  let date = new Date(reviewed_at);
  let formattedDate = `${months[date.getMonth()]} ${date.getDay()}`;
  return (
    <Flex {...contentWrapper}>
      <Box flex={1} overflow="hidden" borderRadius="8px 8px 0px 0px">
        <VideoPlayer
          {...mediaProps}
          fluid={false}
          width={"100%"}
          objectFit="cover"
          height="100%"
          controls={status === "Pending approval" ? false : true}
          src={video && video.recording_url}
          poster={video && video.snapshot_url}
        />
      </Box>

      <Stack
        py={4}
        spacing={3}
        px={5}
        isInline
        align="center"
        borderTopWidth="2xs"
        justify="space-between"
      >
        <Box>
          <Text fontSize="sm" isTruncated lineHeight="none">
            For {order_to}
          </Text>
          {(order_rating && order_rating > 0) ? <Rating mt={-1} size="xs" value={order_rating} /> : ``}
        </Box>
        <Popover placement="top-end">
          <PopoverTrigger>
            <Button variant="link" variantColor="pink" size="sm">
              Details
            </Button>
          </PopoverTrigger>
          <PopoverContent zIndex={4} _focus={{ outline: "none" }}>
            <PopoverCloseButton />
            <PopoverBody py={4}>
              <BookingInfo
                {...{
                  order_from,
                  fontSize,
                  personal_booking,
                  order_to,
                  message
                }}
              />
              {order_review && (
                <>
                  <Divider my={3} />
                  <Stack isInline justify="space-between">
                    <Stack isInline align="center">
                      <Text fontWeight="bold" fontSize="sm">
                        Review
                      </Text>
                      <Rating mt={-1} size="sm" value={order_rating} />
                    </Stack>
                    <Text fontSize="sm" color="gray.500">
                      {formattedDate}
                    </Text>
                  </Stack>
                  <Text fontSize="sm" as="i">
                    {order_review}
                  </Text>
                </>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>
    </Flex>
  );
};
