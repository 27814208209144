import {
    Alert,
    AlertIcon,
    Box,
    Button,
    ButtonGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Editable,
    EditablePreview,
    EditableInput,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Image,
    Icon,
    IconButton,
    Text,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
    Radio,
    RadioButtonGroup,
    Stack,
    Tag,
    TagLabel,
    Textarea,
    useToast,
    useDisclosure,
    Checkbox
} from "@chakra-ui/core";
import {  } from "@chakra-ui/core";
import { Field, Formik } from "formik";

import React, { useEffect, useState, useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import VideoPlayer from "../data-display/VideoPlayer";
import { useMedia } from "../external-pages/utils/hooks";
import { AppContext, is_dev, root_url, createUrl } from "../authentication/AppProvider";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Linkify from 'react-linkify';
import Pluralize from "pluralize";
import Rating from "../data-display/Rating";
import Testimonial from "../data-display/Testimonial";
import { useBookingAction } from "../bookings/booking_actions";
import ChangePasswordForm from "../authentication/ChangePasswordForm"
import LoadingPage from "../external-pages/LoadingPage";
import ImageUploader from "./ImageUploader";
import TalentEditForm from "./TalentEditForm";
import currencyFormatter from "../data-display/Currency";
// import { CreateVideo } from "../bookings/CelebrityBookings"

const wrapperProps = {
    maxW: ["100%", "100%", "100%", "1152px"],
    // mx: "auto",
    // px: [0, 6],
    // py: [8, 12],
    flexDirection: ["column", "row"],
};

const videoContainerStyles = {
    position: "relative",
    zIndex: 1,
    // px: [-5, 0],
    // w: ["100%", "200px", "260px"],
    overflow: "hidden",
    rounded: ["none", "lg"],
    boxShadow: "xl",
    h: ["100vw", "300px", "300px", "300px"],
};

const videoWrapperStyles = {
    position: "relative",
    w: ["100%", "300px", "300px", "300px"],
    // ml: [0, 0, 0, 0, 6],
    mr: [0, 5, 8]
};



const Profile = props => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { fetcher, user, isLoggedIn, isCeleb, checkingAuth, setUser } = useContext(AppContext);

    const [picture, setPicture] = useState('');
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [imageRef, setImageRef] = useState(null);
    const fileInput = React.createRef();
    let isMobile = useMedia("(max-width: 420px)");
    let reviews = [];

    const {
        isOpen: isEditProfileOpen,
        onClose: onEditProfileClose,
        onOpen: onEditProfileOpen
    } = useDisclosure();

    let slug_url = !!user && !!user.public ? user.public.slug_url : null;

    const { getUser, editProfile, setCelebrityImage } = useBookingAction();

    const handleFormSubmit = (field) => (data) => {
        console.log(data)
        setIsLoading(true);
    
        fetcher(`/auth/update-user/`, {
          body: JSON.stringify({[field]: data}),
          method: "PATCH"
        }).then(res => {
            console.log(res)
          let saved_user = res['user'];  
          let updated_user = {...user, [field]: saved_user[field]};
          setUser(updated_user)
          setProfile(updated_user)
          setIsLoading(false);
  
        //   history.push("/");
        });
    
      }

    function EditableControls({ isEditing, onSubmit, onCancel, onRequestEdit }) {
        return isEditing ? (
          <ButtonGroup spacing={2} ml={4} display={'flex'}>
            <IconButton variantColor="pink" icon="check" onClick={onSubmit} />
            <IconButton icon="close" variant="outline" variantColor="pink"  onClick={onCancel} />
          </ButtonGroup>
        ) : (
          <Flex justifyContent="center"  ml={4}>
            <IconButton size="xs" variant="outline" variantColor="pink" icon="edit" onClick={onRequestEdit} />
          </Flex>
        );
      }


    useEffect(() => {
        if (isLoading && isLoggedIn) {
            getUser()
                .then(res => {
                    setProfile(res);
                    let pic = (is_dev ? root_url : '') + res.picture;
                    setPicture(pic)

                }).then(() => {
                    setIsLoading(false)
                })
        }
    }, [isLoading]);
    // useEffect(() => {
    //     if (!!slug_url && isLoggedIn && isCeleb) {
    //         getProfile(slug_url)
    //             .then(res => {
    //                 setProfile(res);
    //                 let pic = (is_dev ? root_url : '') + res.picture;
    //                 setPicture(pic)
    //                 setIsLoading(false)
    //             })
    //     }
    // }, [slug_url, isLoggedIn, isCeleb]);

    let celeb = !!user && !!user.public ? user.public : null;
    // let video = null;

    // let { nickname, picture, description, currency, price, profession, response_time} = celeb;

    let profile_pic = !!user && !!user.public ? user.public.slug_url : null;
    const currency = 'NGN'

    const onSubmit = (response) => {
        onClose()
    }

     const onProfileEdit = (value) => {
        return editProfile(slug_url, value)
            .then((res) => {
                setProfile({ ...profile, ...res })
                onEditProfileClose()
            });
    }

    

    return (
        <Box mb={10}>
            {isLoading ? <LoadingPage /> :
                (
                    <>

                        <Box position="relative">
                            <Box bg="gray.50" pt={[0, 8]} pb={8} px={[0, 6]}>
                                <Flex {...wrapperProps}>
                                    <Box {...videoWrapperStyles}>
                                        <Box {...videoContainerStyles}>
                                            <ImageUploader file_name={'picture'} imageUrl={picture} actionUri={createUrl(`/account/image`)} ref={fileInput} setPicture={setPicture} />
                                        </Box>
                                    </Box>
                                    <Box px={[5, 0]} pt={[6, 0]} flex={1}>
                                        <Box>
                                            <Heading>{`${user.first_name} ${user.last_name}`}</Heading>
                                            {/* <Editable
                                                display={'flex'}
                                                isPreviewFocusable={false}
                                                onSubmit={handleFormSubmit('name')}
                                                submitOnBlur={false} 
                                                defaultValue={`${profile.name}`}>
                                                {props => (
                                                    <>
                                                        <EditablePreview  fontSize={"40px"} fontWeight={"600"} lineHeight={"26px"} />
                                                        <EditableInput borderWidth="2px" borderColor="pink.400"/>
                                                        <EditableControls {...props} />
                                                    </>
                                                )}
                                            </Editable> */}
                                        </Box>
                                        <Linkify my={5} opacity={0.8} lineHeight="tall">
                                            <Text mt={4}>{profile.description}</Text>
                                        </Linkify>
                                        <Box>
                                            <Text fontSize="xl">{`First Name`}</Text>
                                            <Editable
                                                display={'flex'}
                                                isPreviewFocusable={false}
                                                submitOnBlur={false} 
                                                onSubmit={handleFormSubmit('first_name')}
                                                defaultValue={profile.first_name}>
                                                {props => (
                                                    <>
                                                        <EditablePreview  />
                                                        <EditableInput borderWidth="2px" borderColor="pink.400"/>
                                                        <EditableControls {...props} />
                                                    </>
                                                )}
                                            </Editable>
                                        </Box>
                                        <Box>
                                            <Text fontSize="xl">{`Last Name`}</Text>
                                            <Editable
                                                display={'flex'}
                                                isPreviewFocusable={false}
                                                submitOnBlur={false} 
                                                onSubmit={handleFormSubmit('last_name')}
                                                defaultValue={profile.last_name}>
                                                {props => (
                                                    <>
                                                        <EditablePreview  />
                                                        <EditableInput borderWidth="2px" borderColor="pink.400"/>
                                                        <EditableControls {...props} />
                                                    </>
                                                )}
                                            </Editable>
                                        </Box>
                                        <Box>
                                            <Text fontSize="xl">{`Bio`}</Text>
                                            <Editable
                                                display={'flex'}
                                                isPreviewFocusable={false}
                                                submitOnBlur={false} 
                                                onSubmit={handleFormSubmit('bio')}
                                                defaultValue={profile.bio}>
                                                {props => (
                                                    <>
                                                        <EditablePreview  />
                                                        <EditableInput borderWidth="2px" borderColor="pink.400"/>
                                                        <EditableControls {...props} />
                                                    </>
                                                )}
                                            </Editable>
                                        </Box>
                                        <Box px={[0, 0]} py={[2, 8]}>
                                            <Button onClick={onOpen}>Change Password</Button>

                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Change Password</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <ChangePasswordForm onSubmit={onSubmit} />
                                </ModalBody>

                                <ModalFooter>

                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        {/* <Flex flexDirection={["column", "row"]} justifyContent="space-between">
                            <Box pt={[20, 12, 24]}>
                                <Heading size="lg" mb={6}>Access Management</Heading>
                                <Button onClick={onOpen}>Change Password</Button>

                            </Box>
                        </Flex> */}
                        <Modal
                            size="lg"
                            isOpen={isEditProfileOpen}
                            onClose={onEditProfileClose}
                            scrollBehavior="inside"
                            closeOnOverlayClick={false}
                        >
                            <ModalOverlay />
                            <ModalContent minH="80vh" rounded="lg">
                                <ModalHeader>Edit User Profile</ModalHeader>
                                <ModalCloseButton />

                                <ModalBody pb={6}>
                                    <TalentEditForm value={profile} onSubmit={onProfileEdit} />
                                </ModalBody>
                                <ModalFooter />
                            </ModalContent>
                        </Modal>
                    </>
                )
            }
        </Box >
    );
};

export default Profile;
