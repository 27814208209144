import {
    Box,
    IconButton
} from "@chakra-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { useMedia } from "../external-pages/utils/hooks";
import { AppContext, is_dev, root_url } from "../authentication/AppProvider";
import ReactDOM from "react-dom";
import ImgCrop from "antd-img-crop";
import { Upload, Modal } from "antd";

const ImageUploader = React.forwardRef(({
    imageUrl,
    actionUri,
}, fileInput, file_name='file') => {
    const [isLoading, setIsLoading] = useState(true);
    const [previewShow, setPreviewShow] = useState(false)
    const [previewSrc, setPreviewSrc] = useState(null)

    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (isLoading) {
            setSrc(imageUrl)
            setIsLoading(false)
        }
    }, [imageUrl])


    const getBase64Url = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    const onPreview = () => {
        setPreviewSrc(src || imageUrl)
        setPreviewShow(true)
    };
    const onCancel = () => {
        setPreviewShow(false)
    };
    const onChange = (response) => {
        if (!!response && !!response.file && !!response.file.response) {
            let pic = (is_dev ? root_url : '') + response.file.response.picture;
            setSrc(pic)
        }
    };
    let token = localStorage.getItem("token");
    var is_mobile = require('is-mobile');

    return (
        <Box>
            {<>
                <ImgCrop width={500} height={500}>
                    <Upload
                        accept="image/jpg,image/jpeg,image/png,image/gif,image/bmp,image/tiff" 
                        action={actionUri}
                        name={file_name}
                        listType="picture"
                        headers={{ "Authorization": `Token ${token}` }}
                        showUploadList={false}
                        style={{ width: "100%" }}
                        onChange={onChange}
                    >
                        {<Box position="relative" w="300px" h="300px" m={"0 auto"}>
                            { 
                            (<Box position="absolute" zIndex="2" right="10px" bottom="10px">
                                <IconButton aria-label="Search filesystem" icon="camera" />
                            </Box>
                            )}
                            {
                                <img
                                    src={src || imageUrl}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onPreview();
                                    }}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                />
                            }
                        </Box>}
                    </Upload>
                </ImgCrop>
                <Modal visible={previewShow} onCancel={onCancel} footer={null}>
                    <img src={previewSrc} style={{ maxWidth: "100%" }} alt="preview" />
                </Modal>
            </>}
        </Box>
    );
})

export default ImageUploader;