import {
  Box,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CelebrityBookings from "../bookings/CelebrityBookings";
import Footer from "../external-pages/Footer";
import Navigation from "../external-pages/NavigationBar";
import PageWrapper from "../external-pages/PageWrapper";
import UserProfile from "./UserProfile";
import Transactions from "../bookings/Transactions";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "100%", "1088px"],
  mx: "auto",
  py: [8, 12],
  mb: "2rem"
};

const tabProps = {
  mb: [0, 5],
  width: ["auto", "auto", "auto", "100%"],
  display: "inline-block",
  rounded: "lg",
  mr: [3, 5, 5, 0],
  py: 3,
  fontWeight: "semibold",
  textTransform: "capitalize",
  color: "gray.500",
  _hover: { color: "gray.600" },
  _focus: { outline: "none" },
  _selected: {
    color: "gray.700",
    bg: "white",
    boxShadow: "md",
    fontWeight: "bold"
  }
};

const tabListProps = {
  d: ["none", "flex"],
  alignSelf: "flex-start",
  whiteSpace: "nowrap",
  flexDirection: ["row", "row", "row", "column"],
  width: ["500px", "100%", "100%", "260px"],
  pr: [0, 0, 0, 16],
  pb: [2, 0, 0, 0],
  pt: [2, 2, 2, 0]
};

const tabsProps = {
  display: "flex",
  flexDirection: ["column", "column", "column", "row"],
  variant: "unstyled",
  px: [5, 0]
};

const tabPanelProps = {
  flex: 1,
  my: [8, 0]
};

// const Transactions = () => (
//   <Flex justify="space-between" mb={6}>
//     <Heading>Transactions</Heading>
//   </Flex>
// );
const Profile = () => (
  <Flex justify="space-between" mb={6}>
    <Heading>Profile</Heading>
  </Flex>
);
const Settings = () => (
  <Flex justify="space-between" mb={6}>
    <Heading>Account settings</Heading>
  </Flex>
);

const Impact = () => (
  <Flex justify="space-between" mb={6}>
    <Heading>Your impact</Heading>
  </Flex>
);

const Referral = () => (
  <Flex justify="space-between" mb={6}>
    <Heading>PENG with friends</Heading>
  </Flex>
);

const dashboardItems = {
  Bookings: {
    icon: "bell",
    component: CelebrityBookings,
    isHighlighted: false,
    path: "/bookings"
  },
  Transactions: {
    icon: "money",
    isHighlighted: false,
    component: Transactions,
    path: "/transactions"
  },
  Profile: {
    icon: "star",
    isHighlighted: false,
    component: UserProfile,
    path: "/profile"
  }
  // Settings: {
  //   icon: "settings",
  //   isHighlighted: false,
  //   component: Settings,
  //   path: "/settings"
  // },
  // Impact: {
  //   icon: "heart",
  //   isHighlighted: false,
  //   component: Impact,
  //   path: "/impact"
  // },
  // "Refer Celebs": {
  //   icon: "sun",
  //   isHighlighted: true,
  //   component: Referral,
  //   path: "/referral"
  // }
};

const DisplayComponent = (Component, props) => {
  return <Component {...props} />;
};

const TabContent = ({ label, icon, isHighlighted, color, fontSize = "lg" }) => {
  let iconSize = fontSize && fontSize === "md" ? "16px" : "20px";
  return (
    <Stack
      isInline
      align="center"
      spacing={4}
      color={isHighlighted ? "red.500" : "inherit"}
    >
      <Icon name={icon} size={iconSize} />
      <Stack isInline align="center" spacing={3}>
        <Text fontSize={fontSize} color={color}>
          {label}
        </Text>
      </Stack>
    </Stack>
  );
};

const CelebrityDashboard = props => {
  const { pathname } = useLocation();
  const history = useHistory();

  let labels = Object.keys(dashboardItems);
  let tabValues = Object.values(dashboardItems);
  let tabLabels = [];
  let tabComponents = [];
  for (let i = 0; i < labels.length; i++) {
    tabLabels.push({
      label: labels[i],
      icon: tabValues[i].icon,
      isHighlighted: tabValues[i].isHighlighted,
      path: tabValues[i].path
    });
    tabComponents.push(tabValues[i].component);
  }
  let pathIndex = tabLabels.findIndex(
    ({ path }) => `/celebrity${path}` === pathname
  );
  if (pathIndex < 0) {
    pathIndex = 0;
  }
  const [tabIndex, setTabIndex] = useState(pathIndex);

  useEffect(() => {
    if (tabLabels.length > 0) {
      if (pathname === "/celebrity") {
        let path = tabLabels[0].path;
        history.replace(`/celebrity${path}`);
      }
    }
  }, [pathname, tabLabels, history]);

  useEffect(() => {
    setTabIndex(pathIndex);
  }, [pathIndex, pathname]);

  const handleChange = index => {
    setTabIndex(index);
    let path = tabLabels[index].path;
    history.replace("/celebrity" + path);
  };

  return (
    <PageWrapper  navigationProps={{ isHomePage: false,  }}>
      {/* <Navigation hideLoginButton hideSignupButton /> */}
      <Box {...wrapperProps}>
        <Tabs {...tabsProps} index={tabIndex} onChange={handleChange}>
          <Box>
            <TabList {...tabListProps}>
              {tabLabels.map((obj, index) => (
                <Tab key={index} {...tabProps}>
                  <TabContent {...obj} />
                </Tab>
              ))}
            </TabList>

            <MobileMenu
              itemOptions={tabLabels}
              index={tabIndex}
              onChange={handleChange}
            />
          </Box>

          <TabPanels {...tabPanelProps}>
            {tabComponents.map((component, index) => (
              <TabPanel key={index}>{DisplayComponent(component)}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <Footer />
    </PageWrapper>
  );
};

export default CelebrityDashboard;

const MobileMenu = ({ itemOptions = [], index = 0, onChange }) => {
  let defaultItem = itemOptions.length > 0 && itemOptions[index];
  return (
    <Menu>
      <MenuButton
        d={["block", "none"]}
        px={5}
        py={2}
        w="100%"
        transition="all 0.2s"
        rounded="md"
        borderWidth="2xs"
        textAlign="left"
        bg="white"
        _hover={{ borderColor: "gray.300" }}
        _focus={{ outline: "none" }}
      >
        <Stack isInline align="center" justify="space-between">
          <Stack isInline align="center" spacing={3} color="gray.600">
            <Icon name={defaultItem.icon || "dropdown-menu"} />
            <Text>{defaultItem.label || "Menu"}</Text>
          </Stack>
          <Icon name="menu" />
        </Stack>
      </MenuButton>
      <MenuList w="90%" py={0}>
        <MenuOptionGroup type="radio" onChange={onChange} defaultValue={index}>
          {itemOptions.map((item, index) => (
            <MenuItemOption key={index} value={index} py={2}>
              <TabContent {...item} fontSize="md" />
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
