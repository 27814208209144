import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { boolean, object, string } from "yup";
import { Redirect, useHistory } from "react-router-dom";
import PasswordInput from "../form-elements/PasswordInput";
import { AppContext } from "./AppProvider";
import Linkify from 'react-linkify';
let initialValue = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  subscription: true
};

const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

let validationSchema = object().shape({
  first_name: string()
    .trim()
    .required("First name is required"),
  last_name: string()
    .trim()
    .required("Last name is required"),
  username: string()
    .trim()
    .required("Username is required"),
  email: string().max(250, "Email should be less than 250 characters")
    .email("Enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Enter a valid email"),
  password: string().required("Enter a password"),

  subscription: boolean()
});

let placeholders = {
  first_name: {
    label: "First name",
    placeholder: "Your first name"
  },
  last_name: {
    label: "Last name",
    placeholder: "Your last name"
  },
  username: {
    label: "Username",
    placeholder: "Enter a username"
  },
  email: {
    label: "Email address",
    placeholder: "Your email"
  },
  password: {
    label: "Password",
    placeholder: "Type a secure password"
  },
  subscription: {
    label: "Send me exclusive offers and discounts."
  }
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: Boolean(touched[name] && errors[name]),
  errorMessage: errors[name]
});

const SignupForm = ({ value, onSubmit = () => {}, spacing = "24px" }) => {
  const { setAuthToken, isLoggedIn, authService } = useContext(AppContext);
  let [loading, setLoading] = React.useState(false);
  let [message, setMessage] = React.useState('');
  let [status, setStatus] = React.useState('success');
  const toast = useToast();
  let history = useHistory();

  function onFormSubmit(data) {
    let signup_data = {...data, password1: data['password'], password2: data['password']};
    setLoading(true);
    // onSubmit(data).catch(error => {
    //   console.log(error);
    // });
    authService.createUser(signup_data).then((res) => {
      let body = res.body;
      if(res.status === 201) {
        setStatus('success')
        setMessage(body.detail)
        toast({
          title: "Thanks for signing up.",
          description: body.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onSubmit(body.detail)
      } else {
        setStatus('error')
        let error_message =  (Object.values(body).map(obj=>obj.join(',')));
        setMessage(error_message)
      }
      setLoading(false);
    }).catch((error) => {
      setStatus('error')
      setMessage('Unknown error')
      console.log(error)
    }) 
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...initialValue, ...value }}
      onSubmit={values => {
        let email = values.email.toLowerCase();
        let newValues = { ...values, email };
        onFormSubmit(newValues);
      }}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
            {!!message && (<Alert status={status}>
              <AlertIcon />
              <Box>{!Array.isArray(message) ? message : message.map(msg => (<Box>{msg}</Box>))}</Box>
              </Alert>)
            }
              <Flex direction={["column", "row"]} justify="space-between">
                <Field name="first_name">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={commonProps(field, form).isInvalid}
                    >
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="pink.400"
                        fontSize="md"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box size={spacing} />

                <Field name="last_name">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={commonProps(field, form).isInvalid}
                    >
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        {...field}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="username">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      type="email"
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <PasswordInput
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Box />

              <Field name="subscription">
                {({ field, form }) => (
                  <Checkbox
                    {...field}
                    {...commonProps(field, form)}
                    variantColor="pink"
                    isChecked={field.value}
                  >
                    {placeholders[field.name].label}
                  </Checkbox>
                )}
              </Field>
            </Stack>

            <Button
              mt={spacing}
              type="submit"
              size="lg"
              isFullWidth
              variantColor="pink"
              isLoading={loading}
              // isDisabled={!isComplete}
            >
              Sign up
            </Button>
          </form>
        );
      }}
      </Formik>
  );
};

SignupForm.defaultProps = {
  onSubmit: () => {}
};

export default SignupForm;
