import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  Divider
} from "@chakra-ui/core";
import React from "react";
import { useGetPeng } from "../celebrity-ui/hooks";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { root_url } from "../authentication/AppProvider";
import Navigation from "../external-pages/NavigationBar";
import { NextSteps } from "./BookCelebrity";
import { SimilarCelebs } from "../celebrity-ui/CelebrityProfile/ProfilePage";
import LoadingPage from "../external-pages/LoadingPage";
import NotFound from "../external-pages/NotFound";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "100%", "40%"],
  mx: "auto"
};

const BookingCancelled = prop => {
  const { booking_id } = useParams();
  const { data, hasData } = useGetPeng(booking_id);

  return hasData ? data.hasOwnProperty('celebrity') ? (
    <>
      <Navigation />
      <BackgroundImage {...data.celebrity} />

      <Box {...wrapperProps} px={[5, 8]} py={[3, 10]}>
        <Grid
          mt={[8, 8, 8, 6]}
          mb={[12, 12, 12, 10]}
          columnGap={16}
          rowGap={12}
        >
          <RequestDetails {...data} />
        </Grid>

        {data.celebrity && data.celebrity.related > 0 && <SimilarCelebs relate={data.celebrity.related} />}
      </Box>
    </>
  ) : (<NotFound/>) : (
    <LoadingPage />
  );
};

export default BookingCancelled;

const RequestDetails = ({
  booking,
  celebrity,
  currency = "₦"
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold">
        {t('Request details')}
      </Text>
      <Divider my={5} />
      <Stack spacing={5}>
        <Stack spacing={1}>
          <Text fontWeight="bold">{t('Celebrity')}</Text>
          <Link href={`/${celebrity.slug_url}`} color="pink.500">
            {celebrity.nickname}
          </Link>
        </Stack>

        <Flex justify="space-between">
          <Stack spacing={1}>
            <Text fontWeight="bold">{t('Video for')}</Text>
            <Text color="gray.600">{booking.order_to}</Text>
          </Stack>

      {booking.order_from && (
          <Stack spacing={1}>
            <Text fontWeight="bold">{t('Booked by')}</Text>
      <Text color="gray.600">{booking.order_from}</Text>
          </Stack>
        )
      }
      </Flex>

        <Stack spacing={1}>
          <Text fontWeight="bold">{`${t('Instructions for')} ${celebrity.nickname}`}</Text>
          <Text color="gray.600">
            {booking.message}
          </Text>
        </Stack>

        <Text as="i">{`${
          booking.private_order ? t("Booked privately") : t("Booked publicly")
        }`}</Text>

       
        <Stack spacing={1}>
          <Text fontWeight="bold">{t('Delivery information')}</Text>
          <Text color="gray.600">{`${t('Email')}: ${!!booking.email ? booking.email : '-'}`}</Text>
          <Text color="gray.600">{`${t('Phone')}: ${!!booking.phone ? booking.phone : '-'}`}</Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const BackgroundImage = ({ picture, nickname, slug_url }) => {
  const { t } = useTranslation();
  return (
    <Flex
      h="280px"
      align="center"
      direction="column"
      justifyContent="center"
      justify="center"
      position="relative"
    >
      <Image
        h="100%"
        w="100%"
        bgImage="url(/img/1567770.gif)"
        bgSize="cover"
        bgPos="center"
        backgroundRepeat="no-repeat"
      />
      <Box mx="auto" w={["80%", "40%"]} position="absolute" textAlign="center">
        <Avatar
          src={`${root_url + picture}`}
          name={nickname}
          size="lg"
          showBorder
          borderWidth="xs"
          borderColor="pink.300"
        />
        <Stack mt={5}>
          <Heading size="lg" fontWeight="bold" textTransform="uppercase">
            {t('Your payment was unsuccessful ') + ' :('}
          </Heading>
          <Text opacity={0.8}>
            {t('Try booking ')}
            <Link color="pink.500" href={`/${slug_url}/book`}>
              {nickname}
            </Link>
            {t(' again')}
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};
