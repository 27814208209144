import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/core";
import React, { useEffect, useState, useContext } from "react";
import Select from "../form-elements/Select";
import Pluralize from "pluralize";
import { useMedia } from "../external-pages/utils/hooks";
import { AppContext } from "../authentication/AppProvider";
import { BookingInfo } from "../bookings/CelebrityBookings";
import Rating from "../data-display/Rating";
import VideoPlayer from "../data-display/VideoPlayer";
import currencyFormatter from "../data-display/Currency";
import LoadingPage from "../external-pages/LoadingPage";
import { formatDBdate } from "../external-pages/utils/functions";
import { useBookingAction } from "./booking_actions";
import styled from '@emotion/styled'

const gridProps = {
  display: "grid",
  gridTemplateColumns: ["none", "repeat(auto-fill, minmax(232px, 1fr))"],
  // gridTemplateColumns: ["none", "repeat(2, 1fr)"],
  gridRowGap: 6,
  gridColumnGap: 6
};
let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const Transactions = ({ data: dataProp = [] }) => {
  const { user } = useContext(AppContext);
  const { getTransactions } = useBookingAction();
  const [transactions, setTransactions] = useState(dataProp || []);
  const [declineReason, setDeclineReason] = useState("");
  const [booking, setBooking] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isListView, setIsListView] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [targetBooking, setTargetBooking] = useState({});

  let slug_url = !!user && !!user.public ? user.public.slug_url : null;

  useEffect(() => {
    if (!!slug_url) {
      getTransactions(slug_url).then(res => { setTransactionData(res); setIsLoading(false) })
    }
  }, [slug_url]);

  const getNextPaymentDate = (payment_frequency = 'MONTHLY') => {
    const currentDate = new Date();
    let paymentDate;
    if (payment_frequency === 'MONTHLY') {
      paymentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      if (paymentDate.getDay() < 5) {
        paymentDate.setDate(paymentDate.getDate() - 7 + (12 - paymentDate.getDay()) % 7)
      } else {
        paymentDate.setDate(paymentDate.getDate() - (paymentDate.getDay() - 5));
      }
    } else {
      paymentDate = new Date(currentDate.getTime());
      paymentDate.setDate(currentDate.getDate() + (12 - currentDate.getDay()) % 7);
    }
    const month = paymentDate.toLocaleString('default', { month: 'long' });
    return `${paymentDate.getDate()} ${month} ${new Date().getFullYear()}`
  }

  // TODO get currency symbol mapper
  let currency = (!!transactionData.account && transactionData.account.currency === 'NGN') ? "₦" : '';

  return (
    <Box>
      <Flex justify="space-between" mb={6}>
        <Heading>Transactions</Heading>
      </Flex>

      {isLoading ? <LoadingPage /> : !!transactionData && !!transactionData.account ? (
        <PseudoBox>
          <PseudoBox
            p={6}
            mb={6}
            rounded="lg"
            borderWidth="xs"
            opacity={1}
            bg="white"
            _hover={{ borderColor: "gray.300" }}
          >
            <Flex direction={["column", "row"]} justify="space-between" justifyContent="center">
              <Box w={["100%", "50%"]}>
                <>
                  <Box pr={["", "20px"]} borderRight={["", "1px solid grey"]}>
                    <Text w="100%" color="gray.500" fontSize="sm" lineHeight="none" textAlign={["center", "right"]}>
                      Revenue Till Date
                        </Text>
                    <Text
                      lineHeight="short"
                      fontWeight="medium"
                      fontSize={"2.5rem"}
                      textAlign={["center", "right"]}
                      w="100%"
                    >
                      {`${currencyFormatter(transactionData.account.currency, transactionData.account.total_in)}`}
                    </Text>
                  </Box>
                </>
              </Box>
              <Box w={["100%", "50%"]}>
                <>
                  <Box pl={["", "20px"]}>
                    <Flex direction={"column"}>
                      <table>
                        <tbody>
                          <Box as="tr" fontSize="0.875rem">
                            <Box as="td" w={["50%", "110px"]}><Box as="span" color="gray.500" float={["right", "left"]}>Charity Payout</Box></Box>
                            <Box as="td" w="5px"><span>  </span></Box>
                            <Box as="td"><span>{`${currencyFormatter(transactionData.account.currency, transactionData.account.total_out)}`}</span></Box>
                          </Box>
                          <Box as="tr" fontSize="0.875rem">
                            <Box as="td" w={["50%", "110px"]}><Box as="span" color="gray.500" float={["right", "left"]}>Pending Payout</Box></Box>
                            <Box as="td" w="5px"><span>  </span></Box>
                            <Box as="td">
                              <Box as="span" paddingRight="5px">{`${currency} ${(transactionData.account.balance - transactionData.account.paid_earnings)}`}</Box>
                              <Tooltip hasArrow label={`ETA ${getNextPaymentDate(transactionData.account.payment_frequency)}`} placement="top">
                                <Icon name="calendar" size="10px" mb="5px" />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box as="tr" fontSize="0.875rem">
                            <Box as="td" w={["50%", "110px"]}><Box as="span" color="gray.500" float={["right", "left"]}>Previous Payout</Box></Box>
                            <Box as="td" w="5px"><span>  </span></Box>
                            <Box as="td">
                              <Box as="span" paddingRight="5px">{`${!!transactionData.payout && !!transactionData.payout.payout ? `${currencyFormatter(transactionData.account.currency, transactionData.payout.payout)}` : `${transactionData.account.currency} 0`} `}</Box>
                              {!!transactionData.payout && !!transactionData.payout.payout &&
                                <Tooltip hasArrow label={`Paid ${formatDBdate(transactionData.payout.created_at)}`} placement="top">
                                  <Icon name="calendar" size="10px" mb="5px" />
                                </Tooltip>
                              }
                            </Box>
                          </Box>
                        </tbody>
                      </table>
                    </Flex>

                  </Box>
                </>
              </Box>
            </Flex>
          </PseudoBox>
          {!!transactionData && !!transactionData.transactions && transactionData.transactions.length > 0 ? 
            (transactionData.transactions.map((obj, index) => (
            <TransactionLists
              key={index}
              transaction={obj}
              account={transactionData.account}
            />
            ))): (<EmptyTransactions />)}
        </PseudoBox>
      ) : (
          <EmptyTransactions />
        )
      }

    </Box >
  );
};

export default Transactions;
const contentWrapper = {
  height: "400px",
  direction: "column",
  borderWidth: "2xs",
  borderColor: "gray.100",
  boxShadow: "sm",
  borderRadius: "8px",
  overflow: "hidden",
  bg: "white"
};
const mediaProps = {
  height: "100%",
  weight: "110%",
  borderRadius: "8px 8px 0px 0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const EmptyTransactions = () => {
  return (
    <Flex justify="column" minH="50vh" align="center" justifyContent="center">
      <Stack textAlign="center" align="center" spacing={6}>
        <Icon name="cube-box" size="128px" />
        <Text fontSize="xl">There are no transactions yet</Text>
      </Stack>
    </Flex>
  );
};



const TransactionLists = ({ transaction, account }) => {
  // TODO get currency symbol mapper
  const currency = account.currency;
  return (
    <PseudoBox
      p={6}
      mb={6}
      rounded="lg"
      borderWidth="xs"
      opacity={1}
      bg="white"
      _hover={{ borderColor: "gray.300" }}
    >
      <Flex direction={["column", "row"]} justify="space-between">
        <Box w={["100%", "60%"]}>
          <>
            <Flex justify="space-between" w={["100%", "60%"]} mb={3}>
              <Box>
                <Text color="gray.500" fontSize="xs" lineHeight="none">
                  Booking For
              </Text>
                <Text
                  lineHeight="short"
                  fontWeight="medium"
                  fontSize={"md"}
                >
                  {transaction.booking.order_to}
                </Text>
              </Box>
              {!!transaction.booking.order_from && (
                <>
                  <Icon name="chevron-right" size="40px" color="pink.500" />
                  <Box>
                    <Text color="gray.500" fontSize="xs" lineHeight="none">
                      From
                  </Text>
                    <Text
                      lineHeight="short"
                      fontWeight="medium"
                      fontSize={"md"}
                    >
                      {transaction.booking.order_from}
                    </Text>
                  </Box>
                </>
              )}
           

            </Flex>

            <Flex justify="space-between" mb={3} bg="gray.50" px={4} py={4} rounded="md">
              <Box>
                <Text color="gray.500" fontSize="xs" lineHeight="none">
                  Gross Income
              </Text>
                <Text
                  lineHeight="short"
                  fontWeight="medium"
                  fontSize={"md"}
                >
                  {`${currencyFormatter(currency, transaction.amount_in)}`}
                </Text>
              </Box>
              <Box>
                <Text color="gray.500" fontSize="xs" lineHeight="none">
                  Charity Donation
              </Text>
                <Text
                  lineHeight="short"
                  fontWeight="medium"
                  fontSize={"md"}
                >
                  {`${currencyFormatter(currency, transaction.amount_out)} (${transaction.charity_percentage}%)`}
                </Text>
              </Box>
            </Flex>
            {/* <Text
              px={4}
              py={4}
              mb={3}
              bg="gray.50"
              rounded="md"
              fontSize={"md"}
            >
              {transaction.booking.message}
            </Text> */}
          </>
        </Box>
        <Box w={["100%", "40%"]} mb={3}>
          <Text textAlign="right" fontSize="1.5rem">{`${currencyFormatter(currency, transaction.balance)}`}</Text>
        </Box>

      </Flex>
    </PseudoBox>
  );
};
