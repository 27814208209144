import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure
} from "@chakra-ui/core";
import React, { useState, useEffect } from "react";
import { useGetPeng } from "../celebrity-ui/hooks";
import { useBookingAction } from "./booking_actions";
import { useParams } from "react-router";
import Rating from "../data-display/Rating";
import { Helmet } from "react-helmet";
import VideoPlayer from "../data-display/VideoPlayer";
import PageWrapper from "../external-pages/PageWrapper";
import LoadingPage from "../external-pages/LoadingPage";
import { useTranslation } from "react-i18next";
import NotFound from "../external-pages/NotFound";
import { useMedia } from "../external-pages/utils/hooks";
import { root_url, createUrl } from "../authentication/AppProvider";
import Disqus from 'disqus-react';


const CompletedVideo = props => {
  const { t } = useTranslation();
  const { booking_id } = useParams();
  const { data, hasData } = useGetPeng(booking_id);
  const [ booking, setBooking ] = useState({});
  const [ isDownloading, setIsDownloading ] = useState(false);
  const [ pengUrl, setPengUrl ] = useState('');
  const [ facebookUrl, setFacebookUrl ] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  let isMobile = useMedia("(max-width: 420px)");
  const baseFacebookUrl = `https://www.facebook.com/sharer/sharer.php?u=`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${pengUrl}`;

  const makeFacebookUrl = (url) => {
    return `https://www.facebook.com/dialog/share?app_id=526169384882027&display=popup&href=${encodeURI(url)}`
  }

  // const disqusShortname = 'peng';
  // const disqusConfig = {
  //     url: `/peng/${booking_id}`,
  //     identifier: booking_id,
  //     title: data.booking.order_for,
  // };

  function downloadFile() {
    let url = createUrl(`/downloadpeng/${booking_id}/`);
    setIsDownloading(true)
    return fetch(url, {
      method: 'GET'
    }).then(function(resp) {
      return resp.blob();
    }).then(function(blob) {
      let download = require('downloadjs');
      let filename = `PENG for ${booking.personal_booking ? booking.order_to : booking.order_to + ' from ' + booking.order_from}`
      download(blob, `${filename}.mp4`);
    }).then(() => {
      setIsDownloading(false)
    });
  }

  useEffect(() => {
    if (hasData) {
      setBooking(data.booking);
      setPengUrl(`${root_url}/peng/${booking_id}`);
      setFacebookUrl(makeFacebookUrl(pengUrl));
    }

    // return () => {
    //   cleanup
    // };
  }, [hasData])

  function handleOnSave(value) {
    setBooking(value);
    onClose();
  }

  return hasData && !!data.booking && !!data.booking.video ? (
    <PageWrapper>
        <Helmet>
        <title>{"PENG"}</title>
        <meta name="description" content={t("personalized_engagement")} />
        <meta name="keywords" content="celebrity,gifts,peng" />
        <meta property="og:type"          content="video" />
        <meta property="og:title"         content={`Peng video for ${data.booking.order_for}`} />
        <meta property="og:image"         content={data.booking.video.snapshot_url} />
        <meta property="og:video"         content={data.booking.video.recording_url} />
      </Helmet>
      <Box h="400px" bg="gray.400" position="relative">
        {<Image
          height="100%"
          w="100%"
          objectFit="cover"
          bgImage="url(/img/1567770.gif)"
        />}
      <Box pos="absolute" left="50%" top="0%">
        <Heading
            pos="relative"
            fontSize={isMobile ? "39px" : "72px"}
            textAlign="center"
            color="#1A202C"
            top={10}
            left="-50%"
          >
            {data.celebrity.nickname}
          </Heading>
      </Box>
        
      </Box>
      <Box maxW={isMobile ? "100%" : "840px"} mx="auto" mt="-240px" mb={24} zIndex={10} minH="68vh">
        <Flex h={isMobile ? "100vh" : "480px"} overflow="hidden" rounded="lg" borderWidth="2xs" flexDirection={isMobile ? 'column' : 'row'}>
          <VideoPlayer
            fluid={false}
            width={isMobile ? "100%" : "50%"}
            objectFit="cover"
            height="100%"
            src={data.booking.video.recording_url}
            poster={data.booking.video.snapshot_url}
          />
          <Box width={isMobile ? "100%" : "50%"} bg="white" p={8} zIndex={10}>
            <Box mb={6}>
              <Heading>For {data.booking.order_to}</Heading>
              {!!booking && !!booking.order_review ? (
                <Box
                  mt={5}
                  pl={3}
                  borderLeftWidth="sm"
                  borderColor="pink.500"
                  overflow="wrap"
                >
                  <Text
                    as="i"
                    color="gray.600"
                    fontSize="24px"
                    lineHeight="short"
                  >
                    "{booking.order_review}"
                  </Text>
                </Box>
              ) : (
                <Button
                  size="lg"
                  mt={5}
                  leftIcon="edit"
                  variant="link"
                  variantColor="pink"
                  onClick={() => onOpen()}
                >
                  Write a review
                </Button>
              )}
            </Box>
            <ButtonGroup spacing={4}>
              <IconButton icon="twitter" variantColor="twitter" size="md" />
              <Link 
                href={facebookUrl} 
                target="__blank" 
                title={`Peng For ${data.booking.order_to}`}>
                <IconButton icon="facebook" variantColor="facebook" size="md" />
              </Link>
              <IconButton
                variantColor="teal"
                aria-label="Download"
                size="md"
                isLoading={isDownloading}
                icon="download"
                onClick={downloadFile}
              />
            </ButtonGroup>

            <Divider my={10} />

            <Stack isInline spacing={6}>
              <Avatar src={data.celebrity.picture} size="2xl" />
              <Box>
                <Stack mb={3} spacing={1}>
                  <Heading size="lg">{data.celebrity.nickname}</Heading>
                  <Text color="gray.600">{data.celebrity.profession}</Text>
                </Stack>
                <Button variantColor="pink" size="lg" as="a" href={`/${data.celebrity.slug_url}/book`}>
                  Book {data.celebrity.nickname}
                </Button>
              </Box>
            </Stack>
          </Box>
                <Box>
                {/* <Disqus.CommentCount shortname={disqusShortname} config={disqusConfig}>
                    Comments
                </Disqus.CommentCount>
                <Disqus.CommentEmbed 
                    commentId={this.props.article.featuredComment}
                    showMedia={true}
                    height={160}
                />  
                
                <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} /> */}
                </Box>
        </Flex>
      </Box>
      <ReviewModal {...{ isOpen, onClose}} onSaveReview={handleOnSave} />
    </PageWrapper>
  ) :  hasData && (!!data.detail || (!!data.booking && !data.booking.video)) ? (<NotFound />) : (<LoadingPage />);
};

export default CompletedVideo;

const ReviewModal = ({ isOpen, onClose, onSaveReview }) => {
  const [value, setValue] = useState({});
  const { booking_id } = useParams();
  const { doReview } = useBookingAction();

  function handleChange(key, _value) {
    let newValue = { ...value, [key]: _value };
    setValue(newValue);
  }

  function handleSave() {
    doReview(booking_id, value).then((res) => onSaveReview(res));
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent top="4.5rem" rounded="lg">
        <ModalHeader>Rate your video</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <Rating
              size="xl"
              onChange={value => handleChange("order_rating", value)}
            />

            <Stack>
              <Text>Leave a comment</Text>
              <Textarea
                resize="none"
                onChange={({ target }) =>
                  handleChange("order_review", target.value)
                }
              />
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={5} onClick={onClose}>
            Close
          </Button>
          <Button variantColor="pink" onClick={handleSave}>
            Save review
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
