import { Box, IconButton, Image, PseudoBox } from "@chakra-ui/core";
import React, { useContext, useState } from "react";
import { AppContext, root_url, is_dev } from "../authentication/AppProvider";
// import { root_url } from "./hooks";
import LazyLoad from "react-lazy-load";

const Header = ({ nickname }) => <Box>{`Login to follow ${nickname}`}</Box>;

const generateRandomColor = () => { return '#' + Math.floor(Math.random() * 16777215).toString(16) + '30'; /*{#+random_color+opacity}*/ }

const CelebrityImage = ({
  picture,
  hover,
  nickname,
  fallbackImage = "/img/fallback.png"
}) => {
  const { useAuthDisclosure } = useContext(AppContext);
  const { onOpen, isLogin } = useAuthDisclosure();
  const image = (is_dev ? root_url : '') + picture;

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (!isLogin) {
      onOpen("login", <Header {...{ nickname }} />);
    } else {
      console.log('ile')
    }
  };
  return (
    <Box overflow="hidden" position="relative" backgroundColor={generateRandomColor()}>
      <Box mt="100%"></Box>
      <LazyLoad
        width={"100%"}
        height={"100%"}
        debounce={false}
        offsetVertical={200}
      >
        {/* <Image
        w="100%"
        alt={nickname}
        borderRadius="11px 11px 0 0"
        src={image}
        fallbackSrc={fallbackImage}
        transform={hover ? "scale(1.1)" : ""}
        transition={hover ? "all 1.8s ease-in-out" : ""}
      /> */}
        <PseudoBox
          as="img"
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          borderRadius="11px 11px 0 0"
          src={image}
          onError={event =>
            event.target.setAttribute("src", fallbackImage)
          }
          _groupHover={{
            transform: "scale(1.1)",
            transition: "all 1.5s ease-in-out"
          }}
        />
      </LazyLoad>
      {/*<IconButton
        top="1%"
        left="1%"
        size="lg"
        _hover={{ color: "pink.100" }}
        bg="transparent"
        onClick={handleClick}
        position="absolute"
        icon="heart"
        color="white"
      />*/}
      {/* <PseudoBox
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        z-index={1}
        transform="scale(1.01)"
        pointerEvents="none"
        background="radial-gradient(ellipse at center, rgba(0,0,0,0) 65%,rgba(0,0,0,1) 100%)"
        // background="linear-gradient(to top, black 0, rgba(0,0,0,0.1) 25%, transparent 55%) center no-repeat"
      /> */}
    </Box>
  );
};

export default CelebrityImage;
