import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  PseudoBox,
  Radio,
  RadioButtonGroup,
  Stack,
  Text,
  Textarea
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { boolean, object, string } from "yup";
import { useGetCeleb, useGetLocation } from "../celebrity-ui/hooks";
import Rating from "../data-display/Rating";
import Footer from "../external-pages/Footer";
import Navigation from "../external-pages/NavigationBar";
import { useMedia } from "../external-pages/utils/hooks";
import { getCouponPrice } from "../external-pages/utils/functions";
import LoadingPage from "../external-pages/LoadingPage";
import { AppContext, root_url, is_dev } from "../authentication/AppProvider";
import PhoneInput from 'react-phone-input-2'
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import { loadStripe } from "@stripe/stripe-js";
// import {
//   Elements,
//   CardElement,
//   useStripe,
//   useElements
// } from "@stripe/react-stripe-js";
import 'react-phone-input-2/lib/bootstrap.css';
import currencyFormatter from "../data-display/Currency";


const inputProps = {
  size: "lg",
  focusBorderColor: "pink.400",
  errorBorderColor: "red.500",
  variantColor: "pink",
  _placeholder: { color: "gray.300" },
  fontSize: "16px"
};

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "100%", "1052px"],
  mx: "auto"
};
const DEV = process.env.HOST_ENVIRONMENT === "development";
const STAGING = process.env.HOST_ENVIRONMENT === "staging";
let stripe_public_key = 'pk_live_51HIpSECe86J075aq1QFAt2FtCfEy4L4Z2YTd0YnN4wnWnNYiRO0QKGwLDeb7jB04QO7cYoMfh3gDapljZcw6B8j800NQTYoYEK';
if (STAGING || DEV || is_dev) {
  stripe_public_key = 'pk_test_51HIpSECe86J075aqGX7lik28jP5zErEi2yYKRZSwDK6b444U2os4xOpvup8vsStjpPVjhcgZ7J2afbFtKdNs85E900OMqhNK4v';
}
const stripePromise = loadStripe(stripe_public_key);
const CustomRadio = React.forwardRef((props, ref) => {
  const { isChecked, value, children, ...rest } = props;
  return (
    <PseudoBox
      ref={ref}
      display="inline-block"
      _focus={{ outline: "none" }}
      {...rest}
    >
      <Radio variantColor="pink" onChange={() => {}} {...inputProps} isChecked={isChecked}>
        {children}
      </Radio>
    </PseudoBox>
  );
});

let bookingOptions = [
  { label: "Someone else", value: false },
  { label: "Myself", value: true }
];

let initialValue = {
  personal_booking: false,
  order_to: "",
  order_from: "",
  email: "",
  phone: "",
  message: "",
  private_order: false,
  discount_code: "",
  charge_trigger: "",
};

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^([+]?\d{1,4}[.\s]?)?(\d{3,6}[.\s]?){2,4}\d{1,4}$/;
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

let validationSchema = object().shape({
  personal_booking: string(),
  discount_code: string(),
  charge_trigger: string(),
  order_to: string().max(100, "Name should not exceed 100 characters").required("Enter receiver's name"),
  order_from: string().max(100, "Name should not exceed 100 characters").when("personal_booking", {
    is: "false",
    then: string().required("Enter sender's name"),
    otherwise: string()
  }),
  email: string().max(250, "Email should be less than 250 characters")
    .email("Enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Enter a valid email"),
  phone: string().max(20, "Phone Number should not exceed 20 characters")
    .required("Phone number is required")
    .matches(phoneRegExp, "Enter a valid phone"),
  message: string().max(250, "Insructions should not exceed 250 characters").required("Instructions are required"),
  isVideoPrivate: boolean()
});

let placeholders = {
  order_to: {
    label: bookingOption => (bookingOption === false ? "For who?" : "My name is"),
    placeholder: "Janet"
  },
  order_from: {
    label: "My name is",
    placeholder: "Charles"
  },
  email: {
    label: bookingOption =>
      bookingOption === false ? "Delivery email" : "Email address",
    placeholder: "Email to send video"
  },
  phone: {
    label: bookingOption =>
      bookingOption === false ? "Receiver's phone" : "Phone number",
    placeholder: "2348012345678"
  },
  message: {
    label: nickname =>
      nickname
        ? `Instructions for ${nickname} are`
        : "Instructions for the video",
    placeholder:
      "It's my wife's birthday, she really loves you. I'll like you to wish her a happy birthday."
  },
  private_order: {
    label: "Don't make video public on PENG"
  }
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: Boolean(touched[name] && errors[name]),
  errorMessage: errors[name]
});

const allowedKeys = [8, 13, 9, 37, 39];
const numKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107];
const handleKeyDown = (e, onKeyDown) => {
  let preventCondition =
    (!allowedKeys.includes(e.keyCode) && e.keyCode < 48) || e.keyCode > 57

  if (preventCondition && !numKeys.includes(e.keyCode)) {
    e.preventDefault();
    return;
  } else {
    onKeyDown && onKeyDown(e);
  }
};


function usePaystack() {
  let [loadedScript, setLoadedScript] = useState(false);

  useEffect(() => {
    initializePaymentScript();
  }, []);

  function initializePaymentScript() {
    let result = window.PaystackPop;
    if (!!result === false) {
      let ravepay = document.createElement("script");
      ravepay.src = "https://js.paystack.co/v1/inline.js";
      document.body.appendChild(ravepay);
    }
    setLoadedScript(true);
  }



  function onPay(res, verificationCallback) {
    if (loadedScript) {
      const DEV = process.env.HOST_ENVIRONMENT === "development";
      const STAGING = process.env.HOST_ENVIRONMENT === "staging";
      let paystack_public_key = 'pk_live_2d8d2f90c2cb57eabe5efe2c4f996942466ff483';
      // let paystack_public_key = 'pk_test_490ce2e9315aa9f950a80ed80258c335c9318e36';
      if (STAGING || DEV || is_dev) {
        paystack_public_key = 'pk_test_490ce2e9315aa9f950a80ed80258c335c9318e36';
      }

      let handler = window.PaystackPop.setup({
        key: paystack_public_key,
        email: res.booking.email,
        amount: res.amount * 100,
        ref: res.reference,
        metadata: res.booking,
        currency: res.currency,
        callback: response => {
          // verify transaction
          let verification_paylod = {
            uuid: res.reference,
            reference: res.reference,
            payment_method: 'P'
          }
          verificationCallback(verification_paylod)
        },
        onClose: (response) => {
          // verify 
          let verification_paylod = {
            uuid: res.reference,
            reference: res.reference,
            payment_method: 'P'
          }
          verificationCallback(verification_paylod)
        }
        // plan: payStackState.plan || "",
        // quantity: payStackState.quantity || "",
        // subaccount: payStackState.subaccount || "",
        // transaction_charge: payStackState.transaction_charge || 0,
        // bearer: payStackState.bearer || "",
      });
      handler.openIframe();
    }
  }
  return [loadedScript, onPay];
}

function useFlutterwave() {
  let [loadedFScript, setLoadedFScript] = useState(false);

  useEffect(() => {
    initializePaymentScript();
  }, []);

  function initializePaymentScript() {
    let result = window.FlutterwaveCheckout;
    if (!!result === false) {
      let ravepay = document.createElement("script");
      ravepay.src = "https://checkout.flutterwave.com/v3.js";
      document.body.appendChild(ravepay);
    }
    setLoadedFScript(true);
  }



  function onFPay(res, verificationCallback) {
    if (loadedFScript) {
      const DEV = process.env.HOST_ENVIRONMENT === "development";
      const STAGING = process.env.HOST_ENVIRONMENT === "staging";
      let flutter_public_key = 'FLWPUBK-f3b8e64330c716af43c9140fa7839e13-X';
      // let paystack_public_key = 'pk_test_490ce2e9315aa9f950a80ed80258c335c9318e36';
      if (STAGING || DEV || is_dev) {
        flutter_public_key = 'FLWPUBK_TEST-c656067af3e87521860324406248d5a3-X';
      }

      let x = window.FlutterwaveCheckout({
        public_key: flutter_public_key,
        tx_ref: res.reference,
        amount: res.amount,
        currency: res.currency,
        payment_options: "card, mobilemoneyghana, ussd",
        // redirect_url: // specified redirect URL
        //   "https://callbacks.piedpiper.com/flutterwave.aspx?ismobile=34",
        meta: {
          reference: res.reference
        },
        customer: {
          email: res.booking.email,
        },
        callback: function (data) {
          console.log(data)
          let verification_paylod = {
            uuid: data['tx_ref'],
            reference: data['transaction_id'],
            payment_method: 'F'
          }
          let callback = () => {
            x.close()
          }
          verificationCallback(verification_paylod, callback)
          // let p1 = (new Promise((resolve, reject) => { 
          //     resolve('Success')
          //   }))

          //   p1.then(() => {
          //     x.close();
          //   })
        },
      });

    }
  }
  return [loadedFScript, onFPay];
}


const BookCelebrity = props => {
  const { slug_url } = useParams();
  const { data, hasData } = useGetCeleb(slug_url);
  const { data: country, hasData: hasCountry } = useGetLocation();
  const [loadedScript, onPay] = usePaystack();
  const [loadedFScript, onFPay] = useFlutterwave();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { fetcher, coupon } = useContext(AppContext);
  const history = useHistory();

  // useEffect(() => {
  //   async function fetchConfig() {
  //       await loadStripe(publicKey);
  //   }
  // }, []);

  const verificationCallback = (res, callback=() => {}) => {
    setIsLoading(true);
    fetcher(`/verify-booking/${res.uuid}/`, {
      body: JSON.stringify(res),
      method: "POST"
    }).then(function (verfication_response) {
      setIsLoading(false);
      if (res.payment_method === 'P') {
          if (!!verfication_response.payment_status && verfication_response.payment_status === 'success') {
            history.push(`/booking/${res.uuid}`, { state: verfication_response })
          } else {
            setShowAlert(true)
          }
      }
      if (res.payment_method === 'F') {
        if (!!verfication_response.payment_status && verfication_response.payment_status === 'success') {
          history.push(`/booking/${res.uuid}`, { state: verfication_response })
        } else {
          setShowAlert(true)
        }
      }
    }).then(() => {
      callback();
    })
  };

  
  const handleSubmit = values => {
    setIsLoading(true);
    values['charge_trigger'] = 'S'; //(country.hasOwnProperty('country_code') && country.country_code === 'NG') ? 'P' : 'S';
    fetcher(`/booking/${slug_url}/`, {
      body: JSON.stringify(values),
      method: "POST"
    }).then(res => {
      if (values['charge_trigger'] === 'S') {
        const handleStripe = async (res) => {
          // When the customer clicks on the button, redirect them to Checkout.
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: res['sessionId']
          });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `error.message`.
          if (result.error) {
            console.log(result)
          }
        };
        handleStripe(res);
      } else {
        onPay(res, verificationCallback);  //just in case we can swap to these
      }
      // onFPay(res, verificationCallback);  //just in case we can swap to these
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      // useStripe.redirectToCheckout({res['payment_refernce'], })
      setIsLoading(false);
    });
  };

  const handleCryptoSubmission = values => {
    setIsLoading(true);
    values['charge_trigger'] = 'C';
    return fetcher(`/booking/${slug_url}/`, {
      body: JSON.stringify(values),
      method: "POST"
    }).then(res => {
      setIsLoading(false);
      return res;
    });
  };

  const isMobile = useMedia("(max-width: 420px)");
  return hasData ? (
    <Box  position="relative" minH="100vh">
    <Box paddingBottom={isMobile ? "14rem" : "10rem"}>
      <Navigation hideBorder={isMobile ? false : true} />
      <Box d={["block", "block", "block", "none"]} bg="gray.50">
        <Box px={[5, 6]} py={[6, 8]} {...wrapperProps}>
          <CelebritySummary {...data} />
        </Box>
      </Box>
      <Box {...wrapperProps} minH="84vh" px={[5, 8]}>
          {showAlert && (<Alert status="error">
            <AlertIcon />
            There was an error processing your request
          </Alert>)
          }
        <Grid
          templateColumns={["none", "none", "none", "3fr 2fr"]}
          mt={[8, 8, 8, 6]}
          mb={[12, 12, 12, 10]}
          columnGap={12}
        >
          <BookingForm
            mb={10}
            onSubmit={handleSubmit}
            onCryptoSubmit={handleCryptoSubmission}
            isLoading={isLoading}
            coupon={coupon}
            country={!!country && country.hasOwnProperty('country_code') ? country : {'country_code': 'NG'}}
            {...data}
          />
          <Box d={["none", "none", "none", "block"]}>
            <BookingSummary coupon={coupon} {...data} />
          </Box>
        </Grid>
      </Box>
    </Box>
      <Footer />
    </Box>
  ) : (
    <LoadingPage />
  );
};
export default BookCelebrity;



const BookingForm = ({
  nickname,
  slug_url,
  value,
  price = 4000,
  currency = "₦",
  spacing = "24px",
  coupon,
  country,
  isLoading,
  onSubmit,
  onCryptoSubmit,
  ...rest
}) => {
  let retail_price = price;
  let coinbaseButton = '';
  const [chargeId, setChargeId] = useState('');

  price = getCouponPrice(coupon, price, slug_url);
  if (!!coupon && !!coupon.code) {
    initialValue.discount_code = coupon.code
  }

  let triggerCoinbaseCharge = (e) => {
    // console.log(coinbaseButton);
    document.getElementById('coinbaseButton').click();
    // coinbaseButton.click()
    // onSubmit();
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={value || initialValue}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, validateField, setErrors, setTouched, setFieldTouched, setFieldValue, validateForm }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Heading mb={6}>I'm booking for</Heading>
            <Stack spacing={spacing} {...rest}>
              <Field name="discount_code">
                {({ field, form }) => (
                  <input type="hidden" {...field}></input>
                )}  
              </Field>
              <Field name="personal_booking">
                {({ field, form }) => (
                  <FormControl>
                    <RadioButtonGroup
                      isInline
                      spacing={spacing}
                      defaultValue={false}
                      onChange={value => form.setFieldValue(field.name, value)}
                      value={field.value}
                    >
                      {bookingOptions.map((option, index) => (
                        <CustomRadio key={index} value={option.value}>
                          {option.label}
                        </CustomRadio>
                      ))}
                    </RadioButtonGroup>
                  </FormControl>
                )}
              </Field>
              <Box />
              <Flex direction={["column", "row"]}>
                <Field name="order_to">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={commonProps(field, form).isInvalid}
                    >
                      <FormLabel>
                        {commonProps(field, form).label(values.personal_booking)}
                      </FormLabel>
                      <Input
                        {...field}
                        {...inputProps}
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {values.personal_booking === false ? (
                  <>
                    <Box w="20px" h={spacing} />
                    <Field name="order_from">
                      {({ field, form }) => (
                        <FormControl
                          flex={1}
                          isInvalid={Boolean(
                            commonProps(field, form).isInvalid
                          )}
                        >
                          <FormLabel>
                            {commonProps(field, form).label}
                          </FormLabel>
                          <Input
                            {...field}
                            {...inputProps}
                            placeholder={commonProps(field, form).placeholder}
                          />
                          <FormErrorMessage>
                            {commonProps(field, form).errorMessage}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </>
                ) : (
                  <>
                    <Box d={["none", "block"]} w="20px" h={spacing} />
                    <Box flex={1} />
                  </>
                )}
              </Flex>

              <Flex direction={["column", "row"]}>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={Boolean(commonProps(field, form).isInvalid)}
                    >
                      <FormLabel>
                        {commonProps(field, form).label(values.type)}
                      </FormLabel>
                      <Input
                        type="email"
                        {...field}
                        {...inputProps}
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box w="20px" h={spacing} />
                <Field name="phone">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={Boolean(commonProps(field, form).isInvalid)}
                    >
                      <FormLabel>
                        {commonProps(field, form).label(values.type)}
                      </FormLabel>
                      <PhoneInput
                        inputProps={{
                          name: field.name,
                          required: true
                        }}
                        containerClass={`react-tel-input ${commonProps(field, form).isInvalid ? "error" : ""}`}
                        country={country.country_code.toLowerCase()}
                        preferredCountries={['ng', 'gb', 'us']}
                        enableSearch={true}
                        width="100%"
                        value={field.value}
                        onChange={value => form.setFieldValue(field.name, value)}
                        placeholder={commonProps(field, form).placeholder}
                      />  
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="message">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={Boolean(commonProps(field, form).isInvalid)}
                  >
                    <FormLabel>
                      {commonProps(field, form).label(nickname)}
                    </FormLabel>
                    <Textarea
                      height="6rem"
                      p={4}
                      resize="none"
                      {...inputProps}
                      {...field}
                      placeholder={commonProps(field, form).placeholder}
                      onChange={({ target }) =>
                        form.setFieldValue(field.name, target.value)
                      }
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Box />

              <Field name="private_order">
                {({ field, form }) => (
                  <FormControl
                    flex={1}
                    isInvalid={Boolean(commonProps(field, form).isInvalid)}
                  >
                    <Checkbox
                      {...field}
                      {...inputProps}
                      size="md"
                      {...commonProps(field, form)}
                    >
                      {commonProps(field, form).label}
                    </Checkbox>
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="charge_trigger">
                {({ field, form }) => (
                  <input type="hidden" {...field}></input>
                )}  
              </Field>
              <Box w={["100%", "50%"]}>
                <Button
                  isLoading={isLoading}
                  loadingText="...Submitting"
                  isFullWidth
                  mt={8}
                  type="submit"
                  {...inputProps}
                >
                  Book now for {currencyFormatter(currency, price)}
                </Button>
                {/* <Button
                    loadingText="...Submitting"
                    isFullWidth
                    mt={8}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      validateForm().then((res) => {
                      if(Object.values(res).length === 0) {
                        onCryptoSubmit(values).then(booking => {
                          setChargeId(booking['charge_id']);
                          triggerCoinbaseCharge()
                        })
                      } else {
                        for (const prop in res) {
                          setFieldTouched(prop, true);
                        }
                      }
                    })}}
                    variant="ghost"
                  >
                    Buy with Crypto for {currencyFormatter(currency, price)}
                  </Button> */}
                {!!coupon && !!coupon.code && (
                  <>
                    <Box textAlign="center" color="pink.400" fontSize="0.8rem">{`Actual price: `}<span className="price-slash">{currencyFormatter(currency, retail_price)}</span></Box>
                    <Box textAlign="center" fontSize="0.8rem">{` ${coupon.code} coupon applied `}</Box>
                  </>
                  )}
              </Box>
              <CoinbaseCommerceButton 
                ref={inp => { coinbaseButton = inp }} 
                className="displayNone" 
                id="coinbaseButton" 
                styled={false} 
                chargeId={chargeId}
                />

            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

BookingForm.defaultProps = {
  onSubmit: data => alert(JSON.stringify(data))
};

const CelebritySummary = ({ nickname, profession, picture, slug_url, rating = 4.9 }) => {
  const photo = (is_dev ? root_url : '') + picture;
  return (
    <Stack isInline>
      <Flex direction="column" justify="space-between" flex={1} mr={5}>
        <Box>
          <Link href={`/${slug_url}`} color="pink.500">
            <Text fontSize="lg" fontWeight="bold">
              {nickname}
            </Text>
          </Link>
          <Text fontSize="sm" isTruncated opacity={0.8}>
            {profession}
          </Text>
        </Box>
        <Stack isInline align="center">
          <Rating isReadOnly size="sm" />
          <Text fontSize="sm" mt={1} opacity={0.8}>
            ({rating}/5)
          </Text>
        </Stack>
      </Flex>

      <Box h={20} rounded="md" overflow="hidden">
      <Link href={`/${slug_url}`} color="pink.500">
        <Image h="100%" objectFit="cover" src={photo} />
        </Link>
      </Box>
    </Stack>
  );
};

const BookingSummary = ({
  price = 4000,
  picture,
  currency = "₦",
  nickname = "Celeb",
  rating,
  coupon,
  slug_url,
  profession
}) => {
  let retail_price = price;
  price = getCouponPrice(coupon, price, slug_url);

  return (
    <Box position="sticky" top={15} maxWidth="400px">
      <Box rounded="lg" borderWidth="sm" borderColor="pink.50" p={[5, 6]}>
        <CelebritySummary {...{ nickname, profession, picture, slug_url, rating }} />
        <Divider mt={6} mb={4} />
        <Stack isInline justify="space-between" opacity={0.8}>
          <Text>Price</Text>
          <Box>
            <Text>{currencyFormatter(currency, price)}</Text>
            {!!coupon && !!coupon.code && (<Text color="grey" as="s" fontSize="0.8rem">{currencyFormatter(currency, retail_price)}</Text>)}
          </Box>
        </Stack>
        <Divider mt={4} mb={6} />
        <NextSteps {...{ nickname }} />
      </Box>
    </Box>
  );
};

export const NextSteps = ({ nickname }) => {
  return (
    <PseudoBox position="relative">
      <Box
        borderLeftWidth="2xs"
        borderColor="pink.100"
        bottom="20%"
        margin="0 auto"
        position="absolute"
        zIndex="hide"
        left="10px"
        top="52px"
      />
      <Text fontSize="lg" fontWeight="bold" mb={3}>
        What happens next?
      </Text>
      <Stack isInline align="baseline" mb={6} minH="40px" spacing={5}>
        <Box py={2} bg="white">
          <Icon name="time" color="pink.500" size="20px" />
        </Box>

        <Text flex={1} opacity={0.8}>
          <b>{nickname}</b> has 7 days to complete your request.
        </Text>
      </Stack>
      <Stack isInline align="baseline" mb={6} minH="40px" spacing={5}>
        <Box py={2} bg="white">
          <Icon name="receipt" color="pink.500" size="20px" />
        </Box>

        <Text flex={1} opacity={0.8}>
          Your receipt and order updates will be sent to the{" "}
          <b>delivery email.</b>
        </Text>
      </Stack>
      <Stack isInline align="baseline" minH="40px" spacing={5}>
        <Box py={2} bg="white">
          <Icon name="email-message" color="pink.500" size="20px" />
        </Box>

        <Text flex={1} opacity={0.8}>
          When your request is completed, <b>we'll email you a link</b> to view,
          share, or download your PENG.
        </Text>
      </Stack>
    </PseudoBox>
  );
};
