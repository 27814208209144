import React from "react";
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from "video-react";

const VideoPlayer = props => {
  return (
    <Player {...props}>
      <ControlBar autoHide={false} disableDefaultControls={true}>
        {!!props.showVolume && props.showVolume &&  <VolumeMenuButton />}
        <PlayToggle />
      </ControlBar>
    </Player>
  );
};

export default VideoPlayer;
