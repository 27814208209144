import { Avatar, AvatarBadge, Heading, Stack, Icon, Box, Text, Link, Flex, Grid, Image, Stock } from "@chakra-ui/core";
import React, { useState, useContext, useEffect } from "react";
import PageWrapper from "./PageWrapper";
import { Helmet } from "react-helmet";
import useSWR, { mutate } from "swr";
import { useParams, useHistory } from "react-router-dom";
import { AppContext, root_url, is_dev } from "../authentication/AppProvider";
import LoadingPage from "../external-pages/LoadingPage";
import { useAsync } from 'react-use';
// import { useHistory } from "react-router";
import BlogImage from "./BlogImage";
import { Post } from "./BlogList";
import Disqus from 'disqus-react';

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12],
};


const EmptyPosts = () => {
  return (
    <Flex justify="column" minH="50vh" align="center" justifyContent="center">
      <Stack textAlign="center" align="center" spacing={6}>
        <Icon name="cube-box" size="128px" />
        <Text fontSize="xl">There are no posts yet</Text>
      </Stack>
    </Flex>
  );
};



const BlogPost = props => {
  const { fetcher } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [disqusConfig, setDisqusConfig] = useState([]);
  const [hasData, setHasData] = useState(false);
  const history = useHistory();
  const { slug } = useParams();
  const ad = (is_dev ? root_url : '') + '/img/ad300.jpg';
  useEffect(() => {
    const fetchData = () => {
      let url = `/post/${slug}`;
      fetcher(url).then(response => {
        setData(response);
        setDisqusConfig({
          url: `${root_url}/blog/${slug}`,
          identifier: slug,
          title: response.title
        })
        setHasData(true);
      });
    };
    fetchData();
  }, [fetcher, slug]);

  const viewBlog = (post) => {
    history.push(`/blog/${post.slug}`);
  }

  return hasData ? (
    <PageWrapper>
      <Helmet>
        <title>{"Peng Stories - PENG"}</title>
        <meta name="description" content={`Blog`} />
        <meta name="keywords" content="celebrity,gifts,peng,blog" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Peng Blog`} />
        <meta property="og:image" content={`https://sendpeng.com/logo.png`} />
      </Helmet>
      <Box bg="gray.50" minH={'calc(100vh - 400px)'}>
        <Box {...wrapperProps}>
          <Heading as="h1">{data.title}</Heading>
          <br />
          {!!data ? (
            <>
              <Flex
                w="100%"
              >
                <Box flex={"1"} w={["100%", "calc(100%-300px)"]}>
                  <Box d={["block", "none"]} mb={"12px"}>
                    <Image
                      src={"/img/728.jpg"}
                      objectFit="cover"
                      alt="Call to action"
                    />
                  </Box>
                  <div dangerouslySetInnerHTML={{ __html: data.content }} />

                </Box>
                <Box flex={"0 0 300px"} ml={"20px"} d={["none", "block"]}>
                  <Image
                    src={"/img/300.jpg"}
                    objectFit="cover"
                    alt="Call to action"
                  />
                </Box>
              </Flex>
              <Box>
                {!!data.related_posts && data.related_posts.length > 0 && (
                  <Box mt={"50px"}>
                    <Heading as="h1">Recent Posts</Heading>
                    <br />
                    {!!data.related_posts && data.related_posts.length > 0 ? (<Grid
                      templateColumns={[
                        "repeat(1, 1fr)",
                        "repeat(auto-fill, minmax(270px, 1fr))"
                      ]}
                      rowGap={6}
                      columnGap={5}
                      w="100%"
                    >
                      {data.related_posts.map((post) => <Post key={post.slug} post={post} callback={viewBlog} />)}
                    </Grid>) : <EmptyPosts />}
                    <br />
                  </Box>
                )}
                <Box mt={"50px"}>
                  <Disqus.DiscussionEmbed shortname={"sendpeng"} config={disqusConfig} />
                </Box>
              </Box>
            </>
          ) : <EmptyPosts />}
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </PageWrapper>
  ) : (
      <LoadingPage />
    );;
};

export default BlogPost;
