import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Option,
  Radio,
  RadioButtonGroup,
  Select,
  Stack,
  useToast,
  useDisclosure,
  Checkbox
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { object, string, boolean } from "yup";
import { AppContext } from "../authentication/AppProvider";
import PageWrapper from "../external-pages/PageWrapper";
import CelebrityTerms from "../external-pages/CelebrityTerms";
// import TalentForm from "./TalentForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import  banks from "../external-pages/utils/banks"

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "540px"],
  mx: "auto",
  mb: "6rem",
  px: [5, 6],
  py: [8, 12]
};

let initialValue = {
  name: "",
  email: "",
  phone: "",
  social_account: "",
  other_account: "",
  handle: "",
  followers: "",
  booking_price: "",
  bank_name: "",
  account_name: "",
  account_number: "",
  terms_accepted: false,
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

let validationSchema = object().shape({
  name: string()
    .trim()
    .required("Your name is required"),
  email: string()
    .email("Enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Enter a valid emails"),
  phone: string()
    .required("Phone is required")
    .min(7, "Phone number must be at least 7 characters")
    .max(20, "Phone number must be at less than 20 characters"),
    // .matches(phoneRegExp, "Enter a valid phone"),
  social_account: string()
    .trim()
    .required("Your social media network is required"),
  other_account: string()
    .trim()
    .when("social_account", {
      is: 'OTHER',
      then: string().required("Your social platform is required")
    }),
  handle: string().required("Your social media handle is required"),
  followers: string().required("The number of Followers you have is required"),
  booking_price: string().required("Your booking price is required"),
  bank_name: string().required("Your Bank Name is required"),
  account_name: string().required("Your account name is required"),
  account_number: string().required("Your account number is required"),
  terms_accepted: boolean().required("The terms and conditions must be accepted.").oneOf([true], "The terms and conditions must be accepted."),
});

let placeholders = {
  name: {
    label: "Your Name",
    placeholder: "Ayo Chioma Aina"
  },
  email: {
    label: "Email address",
    placeholder: "you@mail.com"
  },
  social_account: {
    label: "Where do you have the most followers?",
    placeholder: ""
  },
  other_account: {
    label: "What social platform do you have the most followers?",
    placeholder: "MYSPACE"
  },
  phone: {
    label: bookingOption =>
      bookingOption === false ? "Receiver's phone" : "Phone number",
    placeholder: "+2348012345678"
  },
  handle: {
    label: "Your social media handle",
    placeholder: "@ayo_chioma_aina"
  },
  followers: {
    label: "How many followers do you have",
    placeholder: "50k"
  },
  booking_price: {
    label: "Booking Price",
    placeholder: "50k",
    info: '(We advice a fee between N10,000 - N50,000. The lower the price, the more the bookings)'
  },
  bank_name: {
    label: "Bank Name",
    placeholder: "-- Select your bank --"
  },
  account_name: {
    label: "Account Name",
    placeholder: "David Adekola"
  },
  account_number: {
    label: "Account Number",
    placeholder: "012345678"
  },
  terms_accepted: {
    label: "I agree to the Terms of Use",
    placeholder: "50k"
  },
};

const inputProps = {
  size: "md",
  focusBorderColor: "pink.400",
  errorBorderColor: "red.500",
  variantColor: "pink",
  _placeholder: { color: "gray.300" },
  fontSize: "16px"
};


const allowedKeys = [8, 13, 9, 37, 39];
const numKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107];
const handleKeyDown = (e, onKeyDown) => {
  let preventCondition =
    (!allowedKeys.includes(e.keyCode) && e.keyCode < 48) || e.keyCode > 57

  if (preventCondition && !numKeys.includes(e.keyCode)) {
    e.preventDefault();
    return;
  } else {
    onKeyDown && onKeyDown(e);
  }
};

let socialMediaOptions = [
  { label: "Instagram", value: 'INSTAGRAM' },
  { label: "Facebook", value: 'FACEBOOK' },
  { label: "Twitter", value: 'TWITTER' },
  { label: "YouTube", value: 'YOUTUBE' },
  { label: "Other", value: 'OTHER' },
];




let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: Boolean(touched[name] && errors[name]),
  errorMessage: errors[name]
});



const TalentSignup = ({ toNextPage: _toNextPage, ...props }) => {
  const { t } = useTranslation();
  let history = useHistory();
  let [loading, setLoading] = React.useState(false);
  let [errorMessage, setErrorMessage] = React.useState('');
  const { fetcher } = useContext(AppContext);
  const toast = useToast();

  const handleFormSubmit = (data) => {
    setLoading(true);

    fetcher(`/lead/`, {
      body: JSON.stringify(data),
      method: "POST"
    }).then(res => {
      setLoading(false);
      toast({
        title: "Thanks for Signing up.",
        description: "We'll be in contact soon.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/");
    });

  }

  return (
    <PageWrapper>
      <Box>
        <Helmet>
          <title>{t("Talent Signup")}</title>
          <meta name="description" content={t("Talent Signup")} />
          <meta name="keywords" content="celebrity,gifts,peng" />
        </Helmet>
        <Box {...wrapperProps}>
          <Stack align="center" spacing={5} mb={8}>
            <Heading>Signup as a PENG talent</Heading>
            <Box>Let's get to know you so we can help you make your fans happy</Box>
          </Stack>
          <TalentForm
            loading={loading}
            onSubmit={handleFormSubmit}
            errorMessage={errorMessage}
          ></TalentForm>
        </Box>
      </Box>
      <Flex
        justify="center"
        mx="auto"
        justifyContent="center"
        direction="column"
      >
      </Flex>
    </PageWrapper>
  )
};

const TalentForm = ({ value, loading, errorMessage, onSubmit = () => { }, spacing = "24px" }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const btnRef = React.useRef();

  const handleModal = (e) => {
    e.preventDefault();
    onOpen();
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={value || initialValue}
      onSubmit={onSubmit}
    >
      {({ errors, values, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
              {errorMessage !== '' && (<Alert status="error">
                <AlertIcon />
                {errorMessage}
              </Alert>)
              }

              <Flex direction={["column"]}>
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={Boolean(commonProps(field, form).isInvalid)}
                    >
                      <FormLabel>
                        {commonProps(field, form).label}
                      </FormLabel>
                      <Input
                        {...field}
                        {...inputProps}
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="phone">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={Boolean(commonProps(field, form).isInvalid)}
                    >
                      <FormLabel>
                        {commonProps(field, form).label(values.type)}
                      </FormLabel>
                      <Input
                        type="tel"
                        // onKeyDown={handleKeyDown}
                        {...field}
                        {...inputProps}
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        type="email"
                        {...field}
                        {...inputProps}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="social_account">
                  {({ field, form }) => (
                    <Box flex={1} mb="24px">
                      <FormLabel flex={1}>{commonProps(field, form).label}</FormLabel>
                      <FormControl>
                        <RadioButtonGroup
                          isInline
                          flex={1}
                          spacing={4}
                          defaultValue={'INSTAGRAM'}
                          onChange={value => form.setFieldValue(field.name, value)}
                          value={field.value}
                        >
                          {socialMediaOptions.map((option, index) => (
                            <Radio
                              key={index}
                              value={option.value}
                              name={field.name}
                              onChange={e => option.value === e.target.value}
                              isChecked={field.value === option.value}
                              variantColor="pink"
                              defaultChecked={option.value === 'INSTAGRAM'}
                            >
                              {option.label}
                            </Radio>
                          ))}
                        </RadioButtonGroup>
                      </FormControl>
                    </Box>
                  )}
                </Field>
                {values.social_account === 'OTHER' && (
                  <Field name="other_account">
                    {({ field, form }) => (
                      <FormControl
                        flex={1}
                        mb="24px"
                        isInvalid={commonProps(field, form).isInvalid}>
                        <FormLabel>{commonProps(field, form).label}</FormLabel>
                        <Input
                          {...field}
                          {...inputProps}
                          fontSize="md"
                          focusBorderColor="pink.400"
                          placeholder={commonProps(field, form).placeholder}
                        />
                        <FormErrorMessage>
                          {commonProps(field, form).errorMessage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>)}
                <Field name="handle">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        {...field}
                        {...inputProps}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="followers">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        type="followers"
                        {...field}
                        {...inputProps}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="booking_price">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        type="booking_price"
                        {...field}
                        {...inputProps}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormLabel fontSize="12px">{commonProps(field, form).info}</FormLabel>
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="bank_name">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      mb="24px"
                      isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Select placeholder="Select option"
                        type="bank_name"
                        {...field}
                        {...inputProps}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      >
                        {banks.map(bank => (
                            <option value={bank.code}>{bank.name}</option>
                          )
                        )}
                      </Select>
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex direction={["column", "row"]}>
                  <Field name="account_name">
                    {({ field, form }) => (
                      <FormControl
                        flex={1}
                        mb="24px"
                        isInvalid={commonProps(field, form).isInvalid}>
                        <FormLabel>{commonProps(field, form).label}</FormLabel>
                        
                        <Input
                          type="account_name"
                          {...field}
                          {...inputProps}
                          fontSize="md"
                          focusBorderColor="pink.400"
                          placeholder={commonProps(field, form).placeholder}
                        />
                        <FormErrorMessage>
                          {commonProps(field, form).errorMessage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="account_number">
                    {({ field, form }) => (
                      <FormControl
                        flex={1}
                        mb="24px"
                        isInvalid={commonProps(field, form).isInvalid}>
                        <FormLabel>{commonProps(field, form).label}</FormLabel>
                        <Input
                          type="account_number"
                          {...field}
                          {...inputProps}
                          fontSize="md"
                          focusBorderColor="pink.400"
                          placeholder={commonProps(field, form).placeholder}
                        />
                        <FormErrorMessage>
                          {commonProps(field, form).errorMessage}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Field name="terms_accepted">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={Boolean(commonProps(field, form).isInvalid)}
                    >
                      <Checkbox
                        {...field}
                        {...inputProps}
                        size="md"
                        {...commonProps(field, form)}
                      >
                        I agree to the <Link color="pink.400" onClick={handleModal}>Terms of Use</Link>
                      </Checkbox>
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Box />
              <Modal
                onClose={onClose}
                finalFocusRef={btnRef}
                isOpen={isOpen}
                scrollBehavior={scrollBehavior}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Celebrity Terms</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <CelebrityTerms/>
                  </ModalBody>
                  <ModalFooter>
                    <Button  onClick={onClose}>Close</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Stack>

            <Button
              mt={spacing}
              type="submit"
              isFullWidth
              size="lg"
              variantColor="pink"
              isLoading={loading}
            // isDisabled={!isComplete}
            >
              Sign up
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default TalentSignup;