import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputRightAddon,
  InputLeftAddon,
  InputGroup,
  PseudoBox,
  Radio,
  RadioButtonGroup,
  Stack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Textarea,
  useToast
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { boolean, object, string } from "yup";
import { Redirect, useHistory } from "react-router-dom";
import { AppContext, is_dev, root_url, createUrl } from "../authentication/AppProvider";
import * as Yup from "yup";

let initialValue = {
  nickname: "",
  profession: "",
  description: "",
  price: "",
  celebrity_time: "",
  limit_bookings: "",
  booking_limit: "",
};

let validationSchema = object().shape({
  nickname: string().max(50, 'Name cannot exceed 50 characters')
    .trim()
    .required("Celebrity name is required"),
  profession: string().trim().max(50, 'Bio cannot exceed 50 characters')
    .required("Bio is required"),
  description: string().max(250, 'Description cannot exceed 250 characters')
    .trim()
    .required("Description is required"),
  limit_bookings: string().required('You need to determine if you want to limit your bookings'),
  booking_limit: Yup.number().when("limit_bookings", {
    is: "false",
    then: Yup.number().required("Enter your booking limit for the week"),
    otherwise: Yup.number()
  }),
  price: Yup.number().min(5000, 'Price cannot be below 5000').max(100000, 'Price cannot be above 100000')
    .transform(value => (isNaN(value) ? undefined : value))

    .required("Price is required"),
  celebrity_time: Yup.number()
    .min(1).max(7)
    .required("Response Time is required")
});

let placeholders = {
  nickname: {
    label: "Celebrity name",
    placeholder: "Celebrity name"
  },
  profession: {
    label: "Bio",
    placeholder: "Actor | Musician"
  },
  description: {
    label: "Description",
    placeholder: "Enter your description"
  },
  limit_bookings: {
    label: "Do you want to limit your bookings",
    placeholder: "Limit Bookings?"
  },
  booking_limit: {
    label: "Booking Limit per week",
    placeholder: "Set your limit"
  },
  price: {
    label: "Price",
    placeholder: "15000"
  },
  celebrity_time: {
    label: "Response Time",
    placeholder: "1"
  }
};

let bookingOptions = [
  { label: "Yes, I want to limit my bookings", value: true  },
  { label: "No, I want all bookings", value: false }
];

const inputProps = {
  size: "lg",
  focusBorderColor: "pink.400",
  errorBorderColor: "red.500",
  variantColor: "pink",
  _placeholder: { color: "gray.300" },
  fontSize: "16px"
};


const CustomRadio = React.forwardRef((props, ref) => {
  const { isChecked, value, children, ...rest } = props;
  return (
    <PseudoBox
      ref={ref}
      display="inline-block"
      _focus={{ outline: "none" }}
      {...rest}
    >
      <Radio variantColor="pink" onChange={() => {}} {...inputProps} isChecked={isChecked}>
        {children}
      </Radio>
    </PseudoBox>
  );
});

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: Boolean(touched[name] && errors[name]),
  errorMessage: errors[name]
});

const TalentEditForm = ({ value, onSubmit = () => { }, spacing = "24px" }) => {
  const { setAuthToken, isLoggedIn, authService, user } = useContext(AppContext);
  let [loading, setLoading] = React.useState(false);
  let [message, setMessage] = React.useState('');
  let [price, setPrice] = React.useState(1);
  let [status, setStatus] = React.useState('success');
  const toast = useToast();
  let history = useHistory();

  function onFormSubmit(data) {
    // let signup_data = {...data, password1: data['password'], password2: data['password']};
    setLoading(true);
    let formData = {};
    Object.keys(initialValue).forEach((key) => {
      return formData[key] = data[key]
    })
    onSubmit(formData).then((res) => {
      setLoading(false)
    }).catch(error => {
      console.log(error);
    });
  }

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...initialValue, ...value }}
      onSubmit={onFormSubmit}>
      {({ values, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
              {message !== '' && (<Alert status={status}>
                <AlertIcon />
                {message}
              </Alert>)
              }
              <Field name="nickname">
                {({ field, form }) => (
                  <FormControl
                    flex={1}
                    isInvalid={commonProps(field, form).isInvalid}
                  >
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      focusBorderColor="pink.400"
                      fontSize="md"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Box />

              <Field name="profession">
                {({ field, form }) => (
                  <FormControl
                    flex={1}
                    isInvalid={commonProps(field, form).isInvalid}
                  >
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="price">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>

                    <InputGroup>
                      <InputLeftAddon children={value.account.currency} />
                      <Input
                        {...field}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="celebrity_time">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <InputRightAddon children={`DAY${field.value > 1 ? 'S' : ''}`} />
                    </InputGroup>
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />
              <Field name="limit_bookings">
                {({ field, form }) => (
                  <FormControl>
                      <FormLabel>
                      {commonProps(field, form).label}
                    </FormLabel>
                    <RadioButtonGroup
                      isInline
                      spacing={spacing}
                      defaultValue={false}
                      onChange={value => form.setFieldValue(field.name, value)}
                      value={field.value}
                    >
                      {bookingOptions.map((option, index) => (
                        <CustomRadio key={index} value={option.value}>
                          {option.label}
                        </CustomRadio>
                      ))}
                    </RadioButtonGroup>
                  </FormControl>
                )}
              </Field>
              
              <Box w="20px" h={spacing} />
              {values.limit_bookings === true ? (
                  
              <Field name="booking_limit">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{`${commonProps(field, form).label} - ${field.value}` }</FormLabel>
                    
                    <Slider color="pink" 
                        value={field.value}
                        fontSize="md"
                        step={5}
                        focusBorderColor="pink.400"
                        onChange={value => form.setFieldValue(field.name, value)}
                        placeholder={commonProps(field, form).placeholder}
                    >
                      <SliderTrack />
                      <SliderFilledTrack />
                      <SliderThumb />
                    </Slider>
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>)
              : (
                  <>
           
                  </>
                )}
              
              <Box />
              <Field name="description">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Textarea
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

            </Stack>

            <Button
              mt={spacing}
              type="submit"
              size="lg"
              isFullWidth
              variantColor="pink"
              isLoading={loading}
            // isDisabled={!isComplete}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

TalentEditForm.defaultProps = {
  onSubmit: () => { }
};

export default TalentEditForm;
