import { Button, Box, Icon, Stack, Text } from "@chakra-ui/core";
import React from "react";

const wrapperProps = { maxW: ["100%", "100%", "100%", "1152px"], mx: "auto" };

const Footer = props => {
  return (
    <Box borderTopWidth="2xs" py={8} bg="gray.800" color="white" position="absolute" bottom="0" width="100%">
      <Box {...wrapperProps} px={[5, 6]} opacity={0.8}>
        <Stack
          flexDirection={["column", "row"]}
          spacing={[6, 2]}
          justifyItems="space-between"
        >
          <Text flex={1} fontWeight="bold">
            PENG © 2020
          </Text>

          <Stack
            isInline
            spacing={12}
            flexDirection={["column"]}
            flex={1}
          >
            <Stack isInline align="center" pb="8px">
              <Icon name="phone" />
              <Text>+2348039447753</Text>
            </Stack>
            <Stack isInline align="center">
              <Icon name="email" />
              <Text>contact@sendpeng.com</Text>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            isInline
            spacing={4}
            flexDirection={["column"]}
            justifyContent={["flex-end"]}
            alignItems={["flex-start", "flex-end"]}
            justifyItems="space-between"
          >
            <Box>
              <Button maxW="200px" marginBottom="10px" mx="0rem" variantColor="pink" as="a" href="/talent-signup">
                Talent Signup
              </Button>
            </Box>
            <Stack
              isInline
              spacing={12}
              flexWrap={["wrap", "nowrap"]}
              flexDirection={["row"]}
              flex={"1 0 60px"}
            >
              <Button color="white" variant="link" as="a" href="/charity">
                Charity
            </Button>
              <Button color="white" variant="link" as="a" href="/press">
                Press
            </Button>
              <Button color="white" variant="link" as="a" href="/terms">
                Terms
            </Button>
              <Button color="white" variant="link" as="a" href="/privacy">
                Privacy
            </Button>
              <Button color="white" variant="link" as="a" href="/faq">
                FAQ
            </Button>
            </Stack>

            {/* <Link href="/privacy">Privacy</Link> */}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
