import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { object, string } from "yup";
import PasswordInput from "../form-elements/PasswordInput";
import { AppContext, createUrl } from "./AppProvider";
import ResetPasswordForm from "../authentication/ResetPasswordForm";

let initialValue = {
  email: "",
  password: ""
};

let validationSchema = object().shape({
  email: string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: string().required("Enter a password")
});

let placeholders = {
  email: {
    label: "Email address",
    placeholder: "Your email"
  },
  password: {
    label: "Password",
    placeholder: "Type your password"
  }
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: touched[name] && errors[name],
  errorMessage: errors[name]
});

const LoginForm = ({ value, onSubmit = () => {}, spacing = "24px", onReset = () => {}, }) => {
  const { setAuthToken, isLoggedIn, isCeleb, authService, redirectUrl } = useContext(AppContext);
  let [loading, setLoading] = React.useState(false);
  let [resetFlag, setResetFlag] = React.useState(false);
  let [errorMessage, setErrorMessage] = React.useState('');

  function onFormSubmit(data) {
    setLoading(true);
    authService.authenticate(data, () => {
      onSubmit();
      setLoading(false)
    }).then((response) => {
      setErrorMessage(response + ' Signup?')
      setLoading(false)
    })
  }
  if (isLoggedIn) {
    if (isCeleb) {
      return <Redirect to="/celebrity/bookings" />;
    }
    return <Redirect to={redirectUrl} />;
  }
  return (
    <Box > 
      {!resetFlag && <Formik

        validationSchema={validationSchema}
        initialValues={value || initialValue}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Stack spacing={spacing}>
              {errorMessage !== '' && (<Alert status="error">
              <AlertIcon />
                {errorMessage}
            </Alert>)
            }
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        type="email"
                        {...field}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box />

                <Field name="password">
                  {({ field, form }) => (
                    <FormControl isInvalid={commonProps(field, form).isInvalid}>
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <PasswordInput
                        {...field}
                        fontSize="md"
                        autoComplete="false"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>

              <Button
                mt={spacing}
                type="submit"
                isFullWidth
                size="lg"
                variantColor="pink"
                isLoading={loading}
                // isDisabled={!isComplete}
              >
                Log in
              </Button>

              
            </form>
          );
        }}
      </Formik>}
                  
      {!resetFlag &&<Button
              mt={spacing}
              type="button"
              onClick={() => {setResetFlag(!resetFlag)}}
              isFullWidth
              size="lg"
              variant="link"

              variantColor="pink"
            >
              Forgot your Password?
            </Button>}
            
        {resetFlag && <ResetPasswordForm onSubmit={onReset} />}
    </Box>
  
  );
};

export default LoginForm;

LoginForm.defaultProps = {
  onSubmit: data => {
    // alert(JSON.stringify(data))
  }
};
